import styled, { css, createGlobalStyle } from 'styled-components';

export const GlobalNotificationStyle = createGlobalStyle`
  body[data-page='order'] {
    [class*='LayoutStyled__Container'] {
      max-width: 1224px;
      padding-left: 0;
      padding-right: 0;
    }
    .notification {
      margin-bottom: 1.5rem;
      margin-bottom: 1.25rem;
    }
  }
`;

const NotificationsStyled = styled.div.attrs(() => ({
  className: 'notification-sfco',
}))`
  ${({ theme, checkout }) => {
    const { colors, media } = theme;
    return css`
      align-items: center;
      background: #fff2f5;
      color: ${colors.primary.gravity};
      display: flex;
      font-size: 16px;
      padding: 16px;
      position: relative;
      justify-content: center;
      margin-bottom: ${checkout ? 0 : '40px'};
      margin-top: 0;
      white-space: pre-wrap;

      ${media.tablet.up} {
        margin-bottom: ${checkout ? 0 : '48px'};
      }

      ${media.desktop.only} {
        border: 1px solid ${colors.primary.red};
        border-radius: 16px;
        width: 1240px;
        margin: 64px auto 0;
        justify-content: flex-start;
      }

      a {
        text-decoration: underline;
      }
      .tooltip .tooltip-button svg {
        height: auto;
        margin-right: 0;
      }
      .notification {
        &__message {
          line-height: 1.4;
          ${media.tablet.down} {
            flex-direction: column;
          }
        }
        &__status-icon {
          fill: currentColor;
          flex: 0 0 24px;
          margin-right: 10px;
          svg {
            height: 24px;
            width: 24px;
          }
        }
        &__button {
          cursor: pointer;
          margin-left: 24px;
          flex: 0 0 16px;
        }
      }
    `;
  }}
`;

export default NotificationsStyled;
