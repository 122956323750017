/* eslint max-len: 0 */
import React, { SVGProps } from 'react';
import Phone from './Phone';

const PhoneMirrored = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return <Phone style={{ transform: 'rotate(90deg)' }} {...props} />;
}

PhoneMirrored.displayName = 'PhoneMirrored';

export default PhoneMirrored;
