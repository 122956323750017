/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Lock = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--outline-lock"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5005 1.00003C8.73906 1.00003 6.50049 3.23861 6.50049 6.00003V6.37503H6.50098V8.66866C5.0062 9.53311 4.00049 11.1491 4.00049 13V18C4.00049 20.7615 6.23906 23 9.00049 23H15.0005C17.7619 23 20.0005 20.7615 20.0005 18V13C20.0005 11.1493 18.995 9.53347 17.5005 8.66894V6.00003C17.5005 3.23861 15.2619 1.00003 12.5005 1.00003H11.5005ZM8.50098 8.02467C8.66527 8.00837 8.83191 8.00003 9.00049 8.00003H15.5005V6.00003C15.5005 4.34318 14.1573 3.00003 12.5005 3.00003H11.5005C9.84364 3.00003 8.5005 4.34316 8.50049 6H8.50098V8.02467ZM18.0005 13C18.0005 11.3432 16.6573 10 15.0005 10H9.00049C7.34363 10 6.00049 11.3432 6.00049 13V18C6.00049 19.6569 7.34363 21 9.00049 21H15.0005C16.6573 21 18.0005 19.6569 18.0005 18V13Z"
        fill="#141928"
      />
    </svg>
  );
};

Lock.displayName = 'Lock';

export default Lock;
