import { useEffect, useMemo, useState, MutableRefObject } from 'react';

/**
 *
 * @param ref - reference to the element to check if it is in the viewport
 * @param threshold - number between 0 and 1 representing the percentage of the element that must be in the viewport to be considered in view
 * @returns
 */
export default function useIsInViewport(ref: MutableRefObject<HTMLElement>, threshold = 0): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), {threshold}), []);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
