import { DEV_WRITE_AND_SOURCE_KEYS, AC_DEV_WRITE_AND_SOURCE_KEYS } from '../common';

const development = {
  api: process.env.baseApi || 'https://fuel-ol-nonprod.azurewebsites.net/api/',
  newrelic: {
    accountID: '3301489',
    applicationID: '749347120',
    licenseKey: 'NRBR-8c644bde3c8db35b207',
  },
  frdc: DEV_WRITE_AND_SOURCE_KEYS,
  infr: DEV_WRITE_AND_SOURCE_KEYS,
  frin: DEV_WRITE_AND_SOURCE_KEYS,
  ac: AC_DEV_WRITE_AND_SOURCE_KEYS,
};

export default development;
