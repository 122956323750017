import React, { SVGProps } from 'react';

const Facebook = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 28 32" {...props}>
      <path d="M28 3.544v24.906c0 0.856-0.694 1.544-1.544 1.544h-7.137v-10.837h3.637l0.544-4.225h-4.188v-2.7c0-1.225 0.337-2.056 2.094-2.056h2.238v-3.781c-0.387-0.050-1.712-0.169-3.262-0.169-3.225 0-5.438 1.969-5.438 5.588v3.119h-3.65v4.225h3.65v10.844h-13.4c-0.85 0-1.544-0.694-1.544-1.544v-24.913c0-0.85 0.694-1.544 1.544-1.544h24.906c0.856 0 1.55 0.694 1.55 1.544z" />
    </svg>
  );
};

Facebook.displayName = 'Facebook';

export default Facebook;
