/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ShortArrowLeft = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="15px"
      height="13px"
      viewBox="0 0 15 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-short-arrow-left"
      {...props}
    >
      <path
        d="M6.9273846,1.72844189 C6.50535391,1.36044819 6.47272338,0.735104987 6.85355441,0.327494548 C7.23527649,-0.0796007438 7.88683165,-0.111512191 8.30863247,0.256156719 L14.6606686,5.78347266 C14.8768555,5.97301284 15,6.2406909 15,6.5206105 C15,6.80226095 14.8751614,7.07016664 14.6579821,7.25672663 L8.30656767,12.7463899 C8.11472853,12.9118172 7.87043627,13 7.61859698,13 C7.32746212,13 7.04813965,12.882654 6.85222665,12.670972 C6.47137085,12.2633342 6.50626083,11.6358707 6.93031408,11.2712747 L11.3021347,7.49207815 L1.03028268,7.49207815 C0.461468849,7.49207815 1.77635684e-15,7.04787129 1.77635684e-15,6.50033486 C1.77635684e-15,5.95279844 0.461468849,5.50859157 1.03028268,5.50859157 L11.27249,5.50859157 L6.9273846,1.72844189 Z"
        transform="translate(7.500000, 6.500000) rotate(-180.000000) translate(-7.500000, -6.500000) "
      />
    </svg>
  );
};

ShortArrowLeft.displayName = 'ShortArrowLeft';

export default ShortArrowLeft;
