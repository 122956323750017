/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronLeft = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      focusable="false"
      data-icon="chevron-left"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
      className="icon icon--fill-chevron-left"
      {...props}
    >
      <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z" />
    </svg>
  );
};

ChevronLeft.displayName = 'ChevronLeft';

export default ChevronLeft;
