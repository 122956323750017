/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Phone = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="15px"
      height="16px"
      viewBox="0 0 15 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-phone"
      {...props}
    >
      <path d="M14.6482069,0.907626452 C14.274337,0.577340475 11.7652343,0.00676469924 11.7652343,0.00676469924 C11.2007997,-0.0742318619 10.2960708,0.566540933 9.76884165,2.23057028 C9.30967133,3.64891007 9.52927453,4.8800578 9.92673817,5.15994591 L11.4095135,6.09680614 C10.6762929,8.35301035 8.47935347,10.7181099 6.0900344,11.9960557 L4.99111097,10.6425131 C4.51560653,10.1727331 2.67529545,10.3896239 1.56729751,11.0483959 C0.459299568,11.706268 -0.00894526197,12.4424367 0,12.9131167 C0.0455018113,13.684384 0.981991472,15.4852075 1.25241194,15.7677955 C1.45840336,15.9540876 1.89579485,16.0287845 2.55006718,15.9900861 C2.55823424,15.9891861 2.56640131,15.9891861 2.57456837,15.9882862 C4.81325053,15.8469922 9.15903443,14.4394519 12.1953662,10.4301222 C15.2326055,6.41989242 15.3451294,1.61679634 14.6482069,0.907626452" />
    </svg>
  );
};

Phone.displayName = 'Phone';

export default Phone;
