/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Restart = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-restart"
      {...props}
    >
      <path d="M15.9993 0.666626L20.666 3.99996L15.9993 7.33329V5.33329H13.3327C8.9144 5.33329 5.33268 8.91501 5.33268 13.3333V18.6666C5.33268 23.0849 8.9144 26.6666 13.3327 26.6666H18.666C23.0843 26.6666 26.666 23.0849 26.666 18.6666V13.3333C26.666 9.96808 24.5882 7.08816 21.6455 5.90652L24.0805 4.14109C27.224 5.99668 29.3327 9.41873 29.3327 13.3333V18.6666C29.3327 24.5577 24.5571 29.3333 18.666 29.3333H13.3327C7.44164 29.3333 2.66602 24.5577 2.66602 18.6666V13.3333C2.66602 7.44225 7.44165 2.66663 13.3327 2.66663H15.9993V0.666626Z" fill="#141928"/>
    </svg>
  );
};

Restart.displayName = 'Restart';

export default Restart;