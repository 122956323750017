import React, { SVGProps } from 'react';

const Play = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width={14} height={16} viewBox="0 0 14 16" className="icon icon--play" {...props}>
      <title>play-icon</title>
      <path
        d="M13.265 6.743c.98.591.98 1.923 0 2.514l-10.77 6.498C1.422 16.404 0 15.688 0 14.498V1.502C0 .312 1.421-.404 2.496.245l10.769 6.498z"
        fill="#32313A"
        fillRule="nonzero"
      />
    </svg>
  );
};

Play.displayName = 'Play';

export default Play;
