import Cookies from 'js-cookie';

const _appendScriptTag = (src) => {
  const $script = document.createElement('script');
  $script.async = true;
  $script.src = src;
  document.getElementsByTagName('body')[0].appendChild($script);
};

export const cartEntry = () => {
  const TDID = Cookies.get('ttd_TDID');
  if (TDID) {
    _appendScriptTag(`https://match.adsrvr.org/track/cmf/generic?ttd_pid=${TDID}&ttd_tpi=1`);
  }
};
