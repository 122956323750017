import {
  ActionTypes,
  TrackingAction,
  NotificationsAction,
  Notification,
  AMEXAction,
  AvailabilityResponse,
  SetAvailabilityAction,
  ClearAvailabilityAction,
} from '~/types/common';

export const setTracking = (key: string, id: string): TrackingAction => {
  return { type: ActionTypes.SET_TRACKING, key, id };
};

export const setNotifications = (notifications: Notification[] = []): NotificationsAction => {
  if (notifications?.length) {
    window.scrollTo(0, 0);
  }
  return { type: ActionTypes.SET_NOTIFICATIONS, notifications };
};

export const setAmexOffer = (isAmexOffer: boolean): AMEXAction => {
  return { type: ActionTypes.SET_AMEX, isAmexOffer };
};

export const setAvailability = (availability: AvailabilityResponse): SetAvailabilityAction => {
  return { type: ActionTypes.SET_AVAILABILITY, availability };
};

export const clearAvailability = (): ClearAvailabilityAction => {
  return { type: ActionTypes.CLEAR_AVAILABILITY };
};
