import React, { Component, ErrorInfo, ReactNode } from 'react';
import PageHeader from '../PageHeader';
import OneColumn from '../Layout/OneColumn';
import PhoneNumber from '../PhoneNumber';
import { setNewRelicNoticeError } from '~/helpers/newrelic';
import ErrorBoundaryStyled from './ErrorBoundaryStyled';
import { trackEvent } from '~/helpers/tracking';

interface Props {
  children: ReactNode;
  production: boolean;
  number: string;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  static displayName = 'ErrorBoundary';

  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log for full story
    if (this.props.production) {
      console.error('ErrorBoundary', error);
      console.error('ErrorBoundary', errorInfo);
    }

    trackEvent({
      action: 'clientErrored',
      data: {
       errorMessage: error?.message,
       errorClass: error?.name,
      },
    });
    setNewRelicNoticeError(new Error(`ErrorBoundary: ${error?.toString()} ${errorInfo?.componentStack}`));


    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  public render(): ReactNode {
    const { children, production, number } = this.props;
    const { error, errorInfo, hasError } = this.state;

    if (hasError) {
      return (
        <OneColumn>
          <PageHeader
            title="There’s a problem loading this page"
            subtitle={{
              text: 'There’s a technical problem that has prevented this page from loading. Call our experts to complete your order.',
            }}
          />
          <PhoneNumber number={number} />
          {!production && error && (
            <ErrorBoundaryStyled open>
              <br />
              <strong>{error.toString()}</strong>
              <br />
              {errorInfo.componentStack}
            </ErrorBoundaryStyled>
          )}
        </OneColumn>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
