export interface QuizAnswerChoice {
  id: number;
  answer: string;
  value: number;
  userCount?: number;
}

export interface QuizAnswerChoiceVAS {
  id: number;
  answer: string;
  addOn: {
    default: Array<string>;
    5000?: Array<string>;
  };
  userCount?: number;
}

export interface QuizQuestion {
  id: number;
  question: string;
  subtitle?: string;
  answerChoices: QuizAnswerChoice[] | QuizAnswerChoiceVAS[];
  checkbox?: QuizAnswerChoice;
  allowMultiple: boolean;
  isVAS: boolean;
  weight: number;
  additive: number;
}

export interface QuizSelectedAnswers {
  [key: number]: {
    selected?: number[];
    calculated?: number;
    isVAS?: boolean;
    checkbox?: boolean;
  };
}

export interface QuizResults {
  internet: string;
  addOns: string[];
}

// Redux
export enum ActionTypes {
  SET_QUIZ = 'quiz/set',
}

export interface QuizAction {
  type: ActionTypes.SET_QUIZ;
  key: string;
  value: QuizSelectedAnswers | QuizResults | string | number | boolean;
}

export type Actions = QuizAction;
