import React, { FC, Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';
import { FuelContext } from '~/providers/FuelProvider';
import { PAGE_ROUTES } from '~/constants/pages';
import { DEFAULT_HOURS } from '~/constants/config/common';
import InfoCircle from '~/components/Icons/outline/InfoCircle';
import NotificationsStyled, { GlobalNotificationStyle } from './NotificationsStyled';
import Tooltip from '../../Tooltip';
import setHtml from '~/helpers/setHtml';
import { notificationsIconMap } from '~/constants/iconMap';

const SFCONotifications: FC = () => {
  const { notifications, callInNumber, monarch, singleForm } = useSelector((store: ReduxState) => store);
  const { cartGlobalHoursOfOperation } = monarch;
  const { router } = useContext(FuelContext);
  const review = router.pathname === PAGE_ROUTES.CHECKOUT;

  const hoursOfOperation = (() => {
    if (Array.isArray(cartGlobalHoursOfOperation)) return cartGlobalHoursOfOperation;
    return DEFAULT_HOURS;
  })();

  if (!notifications?.length || !singleForm?.enabled) return null;

  return (
    <Fragment>
      <GlobalNotificationStyle />
      {notifications.map((notification, index) => {
        const Icon = notificationsIconMap[notification.type] || null;

        return (
          <NotificationsStyled key={index} type={notification.type} review={review}>
            {Icon && (
              <div className="notification__status-icon">
                <Icon />
              </div>
            )}
            <p className="notification__message" data-testid="notification__message">
              {React.Children.map(notification.message.split(/(##phoneNumber##|\*tooltip\*)/gim), (message, idx) => {
                if (message.includes('phoneNumber')) {
                  return (
                    <a key={idx} href={`tel:${callInNumber}`}>
                      {callInNumber}
                    </a>
                  );
                } else if (message.includes('tooltip')) {
                  return (
                    <Tooltip
                      key={idx}
                      className="tooltip"
                      position="top"
                      theme="light"
                      html={
                        <div className="tooltip-info">
                          <strong>Hours of Operation</strong>
                          <ul>
                            {hoursOfOperation.map((hours, key) => (
                              <li key={key} {...setHtml(hours)} />
                            ))}
                          </ul>
                        </div>
                      }
                    >
                      <InfoCircle />
                    </Tooltip>
                  );
                } else {
                  return <span key={idx} {...setHtml(message)} />;
                }
              })}
            </p>
          </NotificationsStyled>
        );
      })}
    </Fragment>
  );
};

SFCONotifications.displayName = 'SFCONotifications';

export default SFCONotifications;
