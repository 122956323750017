/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Ticket = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--ticket"
      {...props}
    >
      <path
        fill="currentColor"
        d="M36.7506 17.5L40.2506 21L25.084 36.1667L17.5007 28.5833L21.0007 25.0833L25.084 29.1667L36.7506 17.5Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33399 7H53.6673V19.7475L52.1116 20.2974C48.9365 21.4196 46.6673 24.4478 46.6673 28C46.6673 31.5522 48.9365 34.5804 52.1116 35.7026L53.6673 36.2525V49L2.33398 49V36.2525L3.88974 35.7026C7.0648 34.5803 9.33398 31.5522 9.33398 28C9.33398 24.4478 7.0648 21.4197 3.88975 20.2974L2.33399 19.7475L2.33399 7ZM7.00065 11.6667L7.00065 16.5667C11.1537 18.6897 14.0007 23.01 14.0007 28C14.0007 32.99 11.1537 37.3103 7.00065 39.4333L7.00065 44.3333L49.0007 44.3333V39.4333C44.8476 37.3103 42.0006 32.99 42.0006 28C42.0006 23.01 44.8476 18.6897 49.0007 16.5667V11.6667H7.00065Z"
      />
    </svg>
  );
};

Ticket.displayName = 'Ticket';

export default Ticket;
