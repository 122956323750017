import { Dispatch } from 'redux';
import { setSessionExpired } from '~/redux/actions/common';
import { ReduxState } from '~/types/redux';

const SALES_JOURNEY_ERROR_CODES = [
  'FRAPI-300',
  'FRAPI-301',
  'FRAPI-302',
  'FRAPI-303'
];

const SESSION_EXPIRATION_ERROR_CODES = [
  'FRAPI-205',
  'FRAPI-501'
];

const isSessionExpirationError = (frapiCode, monarch) => {
  if (!frapiCode) return false;

  // Sales Journey Errors
  if (SALES_JOURNEY_ERROR_CODES.includes(frapiCode) && monarch?.AllowSalesJourneyErrorModal) return true;

  // Session Expired Errors
  if (SESSION_EXPIRATION_ERROR_CODES.includes(frapiCode) && monarch?.allowSessionExpiredModal) return true;

  return false;
};

interface HandleExpiredSession {
  monarch?: ReduxState['monarch'];
  dispatch?: Dispatch;
  frapiErrorCode?: string;
}

export const handleExpiredSession = ({ monarch, dispatch, frapiErrorCode }: HandleExpiredSession) => {
  if (monarch && dispatch) {
    const isSessionExpired = isSessionExpirationError(frapiErrorCode, monarch);
    if (isSessionExpired) {
      dispatch(setSessionExpired(true));
    }
  }
};

export default isSessionExpirationError;
