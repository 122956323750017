/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const WifiBox = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--wifibox"
      {...props}
    >
      <path
        d="M21.9599 11.7201C20.1958 10.4289 18.0202 9.66666 15.6666 9.66666H14.3333C12.0149 9.66666 9.86936 10.4063 8.11959 11.6624L6.53752 9.51528C8.72993 7.9326 11.4227 6.99999 14.3333 6.99999H15.6666C18.6206 6.99999 21.3502 7.96061 23.5601 9.58649L21.9599 11.7201Z"
        fill="#141928"
      />
      <path
        d="M20.3596 13.8538C19.0414 12.8973 17.4198 12.3333 15.6666 12.3333H14.3333C12.6072 12.3333 11.0088 12.88 9.70175 13.8096L11.2841 15.957C12.1483 15.3538 13.1994 15 14.3333 15H15.6666C16.8195 15 17.8869 15.3658 18.7592 15.9877L20.3596 13.8538Z"
        fill="#141928"
      />
      <path
        d="M12.3333 20.3333C12.3333 18.8606 13.5272 17.6667 14.9999 17.6667C16.4727 17.6667 17.6666 18.8606 17.6666 20.3333C17.6666 21.8061 16.4727 23 14.9999 23C13.5272 23 12.3333 21.8061 12.3333 20.3333Z"
        fill="#141928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333252 8.99999C0.333252 4.21353 4.21345 0.333328 8.99992 0.333328H20.9999C25.7864 0.333328 29.6666 4.21353 29.6666 8.99999V21C29.6666 25.7865 25.7864 29.6667 20.9999 29.6667H8.99992C4.21345 29.6667 0.333252 25.7865 0.333252 21V8.99999ZM8.99992 2.99999H20.9999C24.3136 2.99999 26.9999 5.68629 26.9999 8.99999V21C26.9999 24.3137 24.3136 27 20.9999 27H8.99992C5.68621 27 2.99992 24.3137 2.99992 21V8.99999C2.99992 5.68629 5.68621 2.99999 8.99992 2.99999Z"
        fill="#141928"
      />
    </svg>
  );
};

WifiBox.displayName = 'WifiBox';

export default WifiBox;
