/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Trash = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="21px"
      height="23px"
      viewBox="0 0 21 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--trash"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2H13.5L13.5 4H7.5V2ZM5.5 4V2C5.5 0.895431 6.39543 0 7.5 0H13.5C14.6046 0 15.5 0.895431 15.5 2V4H20.5V6H15.5H13.5H7.5H5.5L0.5 6V4L5.5 4ZM2.5 16.5V8H0.5V16.5C0.5 19.8137 3.18629 22.5 6.5 22.5H14.5C17.8137 22.5 20.5 19.8137 20.5 16.5V8H18.5V16.5C18.5 18.7091 16.7091 20.5 14.5 20.5H6.5C4.29086 20.5 2.5 18.7091 2.5 16.5ZM5.5 8L5.5 17.5H7.5L7.5 8H5.5ZM9.5 17.5L9.5 8H11.5L11.5 17.5H9.5ZM13.5 17.5L13.5 8H15.5L15.5 17.5H13.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

Trash.displayName = 'Trash';

export default Trash;
