/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const WholeHomeBundlePremium = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-bundle-premium"
      {...props}
    >
      <path
        d="M9.47855 2.99047C9.79866 2.79484 10.2013 2.79484 10.5215 2.99047L17.5215 7.26825C17.8187 7.4499 18 7.77316 18 8.12153V18.4999H19.9C19.9656 18.1768 20 17.8423 20 17.4999V8.12153C20 7.07643 19.4561 6.10665 18.5644 5.56168L11.5644 1.2839C10.604 0.697032 9.39599 0.697032 8.43565 1.2839L1.43565 5.56168C0.543886 6.10665 0 7.07643 0 8.12153V17.4999C0 17.8423 0.0344316 18.1768 0.100022 18.4999H2V8.12153C2 7.77316 2.1813 7.4499 2.47855 7.26825L9.47855 2.99047Z"
        fill="currentColor"
      />
      <path
        d="M19.0004 20.4999H14C13.9985 20.4999 13.9971 20.4999 13.9956 20.4999H6.50441L6.5 20.4999H0.999648C1.91186 21.7143 3.36419 22.4999 5 22.4999H15C16.6358 22.4999 18.0881 21.7143 19.0004 20.4999Z"
        fill="currentColor"
      />
      <path
        d="M3.24463 9.7449C4.75864 8.35105 6.78 7.49986 9.00017 7.49986H11.0002C13.2203 7.49986 15.2417 8.35105 16.7557 9.7449L15.5826 11.3899C14.4074 10.2217 12.7881 9.49986 11.0002 9.49986H9.00017C7.21225 9.49986 5.59293 10.2217 4.41776 11.3899L3.24463 9.7449Z"
        fill="currentColor"
      />
      <path
        d="M14.276 13.2221C13.3593 12.167 12.0076 11.4999 10.5002 11.4999H9.50017C7.99271 11.4999 6.64107 12.167 5.72435 13.2221L6.94281 14.9306C7.47065 14.0722 8.41856 13.4999 9.50017 13.4999H10.5002C11.5818 13.4999 12.5297 14.0722 13.0575 14.9306L14.276 13.2221Z"
        fill="currentColor"
      />
      <path
        d="M8.5 16.9999C8.5 16.1714 9.17157 15.4999 10 15.4999C10.8284 15.4999 11.5 16.1714 11.5 16.9999C11.5 17.8283 10.8284 18.4999 10 18.4999C9.17157 18.4999 8.5 17.8283 8.5 16.9999Z"
        fill="currentColor"
      />
    </svg>
  );
};

WholeHomeBundlePremium.displayName = 'WholeHomeBundlePremium';

export default WholeHomeBundlePremium;
