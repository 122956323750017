import React, { SVGProps } from 'react';

const MagnifyingGlass = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      data-icon="magnifying-glass"
      className="icon icon--outline-magnifying-glass"
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666016 14C0.666016 6.26801 6.93403 0 14.666 0H18.666C26.398 0 32.666 6.26801 32.666 14V18C32.666 20.9629 31.7456 23.7109 30.1751 25.9735L38.4338 34.2322L34.8982 37.7678L26.6395 29.5091C24.3769 31.0796 21.6289 32 18.666 32H14.666C6.93403 32 0.666016 25.732 0.666016 18V14ZM14.666 4H18.666C24.1889 4 28.666 8.47715 28.666 14V18C28.666 23.5228 24.1889 28 18.666 28H14.666C9.14317 28 4.66602 23.5228 4.66602 18V14C4.66602 8.47715 9.14317 4 14.666 4Z"
        fill="#FF0037"
      />
    </svg>
  );
};

MagnifyingGlass.displayName = 'MagnifyingGlass';

export default MagnifyingGlass;
