import { Tracking } from '~/types/common';
import clientSide from './clientSide';

declare global {
  interface Window {
    newrelic: {
      setCustomAttribute: (name: string, value: string) => void;
      noticeError: (error: Error) => void;
      setCurrentRouteName: (name: string) => void;
      addRelease: (name: string, id: string) => void;
      addPageAction: (name: string) => void;
      interaction: () => { save: () => void };
    };
  }
}

export const setNewRelicCustomAttribute = (attributes = [], initialize?: boolean): void => {
  if (clientSide && window.newrelic) {
    attributes.forEach(({ name, value }) => {
      if(value !== undefined) window.newrelic.setCustomAttribute(name, value);
    });
    if (!initialize) window.newrelic.interaction().save();
  }
};

export const setNewRelicNoticeError = (error: Error): void => {
  if (clientSide && window.newrelic) {
    window.newrelic.noticeError(error);
  }
};

export const setNewRelicCurrentRouteName = (name: string): void => {
  if (clientSide && window.newrelic) {
    window.newrelic.setCurrentRouteName(name);
    window.newrelic.interaction().save();
  }
};

export const setNewRelicRelease = (name: string, id: string): void => {
  if (clientSide && window.newrelic) {
    window.newrelic.addRelease(name, id);
  }
};

export const setNewRelicPageAction = (name: string): void => {
  if (clientSide && window.newrelic) {
    window.newrelic.addPageAction(name);
    window.newrelic.interaction().save();
  }
};

export const setTrackingCustomAttributes = (tracking: Tracking, initialize?: boolean): void => {
  setNewRelicCustomAttribute(
    [
      { name: 'Release', value: process.env.release },
      { name: 'ClientSessionId', value: tracking.clientSessionId },
      { name: 'clientSessionId', value: tracking.clientSessionId },
      { name: 'OrderId', value: tracking.orderId },
      { name: 'QuoteId', value: tracking.quoteId },
      { name: 'ClientId', value: tracking.clientId },
      { name: 'WriteKey', value: tracking.writeKey },
      {name: 'RetryAttempts', value: tracking.retryAttempts}
    ],
    initialize
  );
};
