/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const StopwatchRed = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--stopwatch-red"
      {...props}
    >
      <path
        d="M30.8726 5.54867L26.2132 0.889282L24.3276 2.7749L25.7145 4.16178L23.0554 6.82088C21.3997 5.87428 19.4823 5.33329 17.4385 5.33329H14.7718C8.51258 5.33329 3.43848 10.4074 3.43848 16.6666V19.3333C3.43848 25.5925 8.51258 30.6666 14.7718 30.6666H17.4385C23.6977 30.6666 28.7718 25.5925 28.7718 19.3333V16.6666C28.7718 14.3724 28.0901 12.2374 26.9182 10.4531L24.9793 12.392C25.6959 13.6534 26.1051 15.1122 26.1051 16.6666V19.3333C26.1051 24.1198 22.2249 28 17.4385 28H14.7718C9.98534 28 6.10514 24.1198 6.10514 19.3333V16.6666C6.10514 11.8802 9.98534 7.99996 14.7718 7.99996H17.4385C19.7032 7.99996 21.7651 8.86866 23.309 10.291L26.462 7.13803C26.5138 7.0863 26.5719 7.04486 26.6338 7.01369L27.6001 6.0474L28.987 7.43429L30.8726 5.54867Z"
        fill="#FF0037"
      />
      <path d="M12.1051 0.666626H20.1051V3.33329H12.1051V0.666626Z" fill="#FF0037" />
      <path d="M22.7718 19.3333V16.6666H17.4385V11.3333H14.7718V19.3333H22.7718Z" fill="#FF0037" />
    </svg>
  );
};

StopwatchRed.displayName = 'StopwatchRed';

export default StopwatchRed;
