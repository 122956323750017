/* tslint:disable */
/* eslint-disable */

// Redux
export enum ActionTypes {
  INJECT_DATA = 'debugger/inject',
}
// Actions
export interface InjectAction {
  type: ActionTypes.INJECT_DATA;
  state: any;
}

export type Actions = InjectAction;
