import { PAGE_ROUTES } from './pages';

const breadcrumbs = [
  {
    name: 'Configure',
    route: '',
  },
  {
    name: 'Contact',
    route: PAGE_ROUTES.CONTACT_CREDIT,
  },
  {
    name: 'Billing Summary',
    route: PAGE_ROUTES.BILLING_SUMMARY,
  },
];

export default breadcrumbs;
