import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { DEFAULT_FUSE_NUMBER } from '~/constants/config/common';
import clientSide from '~/helpers/clientSide';
import { getSiteKey } from '~/helpers/site';
import { setTrackingCustomAttributes, setNewRelicCustomAttribute, setNewRelicPageAction } from '~/helpers/newrelic';
import { formatPhone } from '~/helpers/phone';
import { setCallInNumber, setTracking } from '~/redux/actions/common';
import { Decisions } from '~/types/providers';
import Cookies from 'js-cookie';
import { MonarchError } from '~/types/monarch';
import LogRocket from 'logrocket';

type Lease = {
  dnis: string;
};

type Tracking = {
  [key: string]: string;
};

declare global {
  interface Window {
    cohesion: (event: string, callback: (data?: Record<string, unknown> | Lease[] | Decisions | Error) => void) => void;
    _Cohesion: {
      anonymousId: string;
      sessionId: string;
      webContext: {
        page: {
          referrer: string;
        };
      };
      tenantId: string;
    };
    monarch: (
      event: string,
      ruleId: string,
      payload: Record<string, unknown>,
      params: Record<string, unknown>,
      callback: (err: MonarchError, result: Record<string, unknown>) => void
    ) => Record<string, unknown>;
    fuse: (meta: string, data: Record<string, unknown>) => void;
    isBot: () => boolean;
  }
}

const useCohesion = (dispatch: Dispatch, tracking: Tracking): void => {
  const site = getSiteKey();
  const defaultFuseNumber = DEFAULT_FUSE_NUMBER[site] || '';

  useEffect(() => {
    if (clientSide) {
      window?.cohesion('ready', () => {
        const { anonymousId, sessionId, webContext, tenantId } = window?._Cohesion;

        LogRocket.identify(tracking.clientSessionId);
        LogRocket.track('custom_tracking', {
          cart_str: `Fuel Cart - ${getSiteKey()}`,
          clientSessionId_str: tracking.clientSessionId,
          sessionId: sessionId,
          domain_str: window?.location.hostname,
          releaseTag_str: process.env.release,
        });

        dispatch(setTracking('anonymousId', anonymousId));
        dispatch(setTracking('sessionId', sessionId));
        dispatch(setTracking('tenantId', tenantId));
        dispatch(setTracking('referrer', webContext.page.referrer));
        setTrackingCustomAttributes(tracking, true);

        if (window?.isBot()) {
          dispatch(setCallInNumber(defaultFuseNumber));
          setNewRelicCustomAttribute([{ name: 'BotDetected', value: 'true' }]);
        }
      });

      window?.cohesion('fuse:leasesReceived', (leases) => {
        const amex = Cookies.get('isAmexOffer') === 'true';
        if (!amex) {
          if (leases[0]?.dnis) {
            dispatch(setCallInNumber(formatPhone(leases[0].dnis)));
          } else {
            dispatch(setCallInNumber(defaultFuseNumber));
          }
        }
      });

      window?.cohesion('fuse:error', (err) => {
        dispatch(setCallInNumber(defaultFuseNumber));
        setNewRelicCustomAttribute([{ name: 'FuseError', value: err }]);
        setNewRelicPageAction('fuse:error');
      });
    }
  }, []);
};

export default useCohesion;
