import React, { SVGProps } from 'react';

const MinusCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="17px"
      height="17px"
      viewBox="0 0 521 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-minus-circle"
      {...props}
    >
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"
      />
    </svg>
  );
};

MinusCircle.displayName = 'MinusCircle';

export default MinusCircle;
