/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const AngleDown = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="10px"
      height="6px"
      viewBox="0 0 10 6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-angle-down"
      {...props}
    >
      <path
        d="M-.041.71c0-.19.082-.378.243-.515a.773.773 0 011.043.05l3.699 3.844L8.672.215a.773.773 0 011.043-.05.663.663 0 01.053.98L5.492 5.585a.76.76 0 01-.548.228.756.756 0 01-.547-.228L.149 1.174a.665.665 0 01-.19-.465"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

AngleDown.displayName = 'AngleDown';

export default AngleDown;
