/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Shield = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg 
      width="40"
      height="45"
      viewBox="0 0 40 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--shield"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M40 5L35.9363 5.40637C32.0819 5.79181 28.2059 4.92355 24.8843 2.93057L20 0L15.1157 2.93058C11.7941 4.92355 7.91814 5.79181 4.06372 5.40637L0 5V21.6762C0 28.7015 3.68598 35.2116 9.71009 38.8261L20 45L30.2899 38.826C36.314 35.2116 40 28.7015 40 21.6762V5ZM36 9.41738C31.3996 9.80665 26.7901 8.73879 22.8263 6.36055L20 4.66476L17.1737 6.36055C13.2099 8.7388 8.60037 9.80665 4 9.41738V21.6762C4 27.2964 6.94878 32.5045 11.7681 35.3961L20 40.3352L28.2319 35.3961C33.0512 32.5045 36 27.2964 36 21.6762V9.41738Z" fill="#FF0037"/>
    </svg>
  );
};

Shield.displayName = 'Shield';

export default Shield;
