import React, { SVGProps } from 'react';

const Error = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--error"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10.8C0 4.83534 4.83534 0 10.8 0H13.2C19.1647 0 24 4.83534 24 10.8V13.2C24 19.1647 19.1647 24 13.2 24H10.8C4.83534 24 0 19.1647 0 13.2V10.8ZM10.8 2.4H13.2C17.8392 2.4 21.6 6.1608 21.6 10.8V13.2C21.6 17.8392 17.8392 21.6 13.2 21.6H10.8C6.1608 21.6 2.4 17.8392 2.4 13.2V10.8C2.4 6.1608 6.1608 2.4 10.8 2.4Z"
        fill="#141928"
      />
      <path
        d="M12.1131 13C12.849 13 13.4622 12.5349 13.5233 11.9302L13.995 7.26356C14.0638 6.58333 13.4127 6 12.5847 6H11.4153C10.5873 6 9.93627 6.58333 10.005 7.26356L10.4767 11.9302C10.5379 12.5349 11.151 13 11.887 13H12.1131Z"
        fill="#141928"
      />
      <path
        d="M14 16.8C14 17.4627 13.4031 18 12.6667 18H11.3333C10.597 18 10 17.4627 10 16.8V16.2C10 15.5372 10.597 15 11.3333 15H12.6667C13.4031 15 14 15.5372 14 16.2V16.8Z"
        fill="#141928"
      />
    </svg>
  );
};

Error.displayName = 'Error';

export default Error;
