/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const MapMarkerAlt = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24px"
      height="30px"
      viewBox="0 0 24 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--map-marker-outline"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 2.66667H10.6667C6.24839 2.66667 2.66667 6.24839 2.66667 10.6667V12.4163C2.66667 14.4632 3.45128 16.4323 4.85905 17.9183L10.2367 23.5947L8.40608 25.5398L2.92317 19.7522C1.04616 17.7709 0 15.1455 0 12.4163V10.6667C0 4.77563 4.77563 0 10.6667 0H13.3333C19.2244 0 24 4.77563 24 10.6667V12.4163C24 15.1455 22.9538 17.7709 21.0768 19.7522L13.8367 27.3946L12 29.3333L10.227 27.4618L16.9113 20.2718L19.141 17.9183C20.5487 16.4323 21.3333 14.4632 21.3333 12.4163V10.6667C21.3333 6.24839 17.7516 2.66667 13.3333 2.66667ZM12.6667 7.33333C14.5076 7.33333 16 8.82572 16 10.6667V12C16 13.8409 14.5076 15.3333 12.6667 15.3333H11.3333C9.49238 15.3333 8 13.8409 8 12V10.6667C8 8.82572 9.49238 7.33333 11.3333 7.33333H12.6667Z"
        fill="#141928"
      />
    </svg>
  );
};

MapMarkerAlt.displayName = 'MapMarkerAlt';

export default MapMarkerAlt;
