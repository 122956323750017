import styled, { css } from 'styled-components';

export const H1 = styled.h1.attrs({
  className: 'h1',
})`
  ${({ theme }) => {
    const {} = theme;
    const { media } = theme;

    return css`
      font-size: 26px;
      font-weight: 700;
      line-height: 1.2;

      ${media.tablet.up} {
        font-size: 38px;
        line-height: 1.05;
      }
    `;
  }};
`;

export const H2 = styled.h2.attrs({
  className: 'h2',
})`
  ${() => {
    return css`
      font-size: 26px;
      font-weight: 700;
      line-height: 1.2;
    `;
  }};
`;

export const H3 = styled.h3.attrs({
  className: 'h3',
})`
  ${({ theme }) => {
    const {} = theme;
    const { colors = {} } = theme;

    return css`
      color: ${colors.primary.gray};
      font-size: 26px;
      font-weight: 400;
      line-height: 1.15;
    `;
  }};
`;

export const H4 = styled.h4.attrs({
  className: 'h4',
})`
  ${() => {
    return css`
      font-size: 22px;
      font-weight: 700;
    `;
  }};
`;

export const H5 = styled.h5.attrs({
  className: 'h5',
})`
  ${() => {
    return css`
      font-size: 16px;
      font-weight: 700;
      line-height: 1;
    `;
  }};
`;

export const H6 = styled.h6.attrs({
  className: 'h6',
})`
  ${() => {
    return css``;
  }};
`;
