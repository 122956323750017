// DO NOT EDIT THIS FILE. THIS FILE IS GENERATED IN NEXT.CONFIG.JS
import { lazy } from "react";

const listOfPreampAssets = {
  ['Session Expired Modal']: lazy(() => import('./assets/Session Expired Modal')),
  ['address-check-address-form-gravity']: lazy(() => import('./assets/address-check-address-form-gravity')),
  ['allconnect-unserv-redesign']: lazy(() => import('./assets/allconnect-unserv-redesign')),
  ['contact-page-config']: lazy(() => import('./assets/contact-page-config')),
  ['outage-contact-form']: lazy(() => import('./assets/outage-contact-form')),
  ['redesign-plan-package-page-config']: lazy(() => import('./assets/redesign-plan-package-page-config')),
};

export default listOfPreampAssets;
