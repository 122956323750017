import { Address } from './address';
import { LegalMap } from './legal';
import { BaseResponse, InstallationOptions } from './common';
import { BillingSummary, Contact } from './contact-credit';
import { Disclosure } from './order';

export interface GetProductsResponse extends BaseResponse {
  quoteId: string;
  quoteNumber: string;
  accountUuid: string;
  availableProducts: Product[];
  broadbandType: string;
  addressHasExistingService: boolean;
  legal: LegalMap;
  customer: Omit<Contact, 'firstname' | 'lastname'> & { firstName: string; lastName: string };
  gmtOffset?: number;
  serviceAddress: Address;
  unservReason?: string;
  firstAvailable: string;
}

export interface ResumeQuoteResponse {
  success: boolean;
  quoteId: string;
  quoteNumber: string;
  serviceAddress: Address;
  billingSummary: BillingSummary;
  creditChecked?: string;
  cartPage: string;
  accountUuid: string;
  sweetenerSelected?: boolean;
  selectedInternetProductId?: string;
  selectedVoiceProductId?: string;
  selectedVASProductIds?: Array<string>;
  selectedSecureProductIds?: Array<string>;
  selectedVideoProductIds?: Array<string>;
  installationOptions?: InstallationOptions;
  contactVerifyRequired?: boolean;
}

export interface SelectProductsResponse {
  success: boolean;
  billingSummary: BillingSummary;
  installationOptions?: InstallationOptions;
  contactVerifyRequired: boolean;
  contactVerifyComplete?: boolean;
}

export interface PhoneVerificationResponse {
  success: boolean;
  message: string;
}

export interface PhoneVerificationRequest {
  code: string;
}

export interface PhoneVerificationResponse {
  success: boolean;
  message: string;
}

export interface PhoneVerificationRequest {
  code: string;
}

export interface CreditBypassResponse {
  success: boolean;
  billingSummary: BillingSummary;
  disclosures: Disclosure[];
}

export interface ContactGetResponse {
  success: boolean;
  accountUuid: string;
  hasAutoPay: string;
  hasPaperlessBill: string;
}

export interface SelectedProductRequest {
  selectedProducts: SelectedProduct[];
  isLandingPage?: boolean;
}

export interface SelectedProduct {
  price?: Price;
  additionalOptions?: AdditionalOptions;
  productId: string;
  name?: string;
  type: string;
  dataspeed?: {
    downloadSpeedMbps: number;
    uploadSpeedMbps: number;
  };
  configurations?: SelectedProductConfiguration[];
}

export interface SelectedProductConfiguration {
  productId?: string;
  productKey: string;
  name?: string;
  active: boolean;
}

export interface CarouselProps {
  productIndex: number;
  paginate: (index: number) => void;
  direction: number;
}

export type Option = {
  value: string;
  label: string;
};

export type AddonBundle = {
  name: string;
  displayName: string;
  price: number;
  productIds: [];
  tooltip: string;
};

export type Feature = {
  icon: string;
  text: string;
  bold: boolean;
};

export type SweetenerBanner = {
  text: string;
  legal: string;
  bold: boolean;
};

interface AmountWithSweetenerType {
  [key: string]: number;
}

export type Price = {
  amount: number;
  amountMajor?: number;
  amountMinor?: number;
  amountNoAutopay?: number;
  originalAmount?: number;
  amountACH?: number;
  amountDebit?: number;
  amountCredit?: number;
  amountWithSweetener?: AmountWithSweetenerType;
};

export type Sweetener = {
  name: string;
  amount: number;
  legal: string;
  key?: string;
  productId?: string;
};

interface DetailsBannerItem {
  name: string;
  text: string;
}

export type Dataspeed = {
  uploadInKbps: number;
  downloadInKbps: number;
  uploadInMbps: number;
  downloadInMbps: number;
};

type ConfigurationsOptions = {
  active: boolean;
  id: string;
  name: string;
  displayName: string;
  price: string;
};

type Configurations = {
  id: string;
  name: string;
  options: ConfigurationsOptions[];
};

type AdditionalOptions = {
  productId: string;
  name: string;
  displayName: string;
  description: string;
  type: string;
  displayType: string;
  price: Price;
  productKey: string;
};

export type Product = {
  productId: string;
  name: string;
  description: string;
  dataspeed: Dataspeed;
  type?: string;
  serviceType?: string;
  price: Price;
  features: Feature[];
  displayType: string;
  sweeteners: {
    [key: string]: Sweetener;
  };
  is200?: boolean;
  is500?: boolean;
  is1Gig?: boolean;
  is2Gig?: boolean;
  is5Gig?: boolean;
  is7Gig?: boolean;
  shortLegal: string;
  shortLegalIndiana?: string;
  detailsBanner: DetailsBannerItem[];
  configurations?: Configurations[];
  productConfigurations?: Configurations[];
  additionalOptions?: AdditionalOptions[];
  disclaimer?: string;
  tooltip?: string;
  productKey?: string;
  promo?: string;
  displayStrikethrough?: boolean;
  displayName?: string;
  labelId?: string;
  isVrcSelected?: boolean;
};

export type Bundle = {
  productIds: unknown;
  name: string;
  headline: string;
  description: string;
  price: number;
  included: boolean;
};

export type SelectedProductsState = {
  internet: Product | undefined;
  voice: Product | undefined;
  secure?: {
    [key: string]: SelectedProduct;
  };
  modal?: Product;
  vas?: {
    [key: string]: Product;
  };
  video?: boolean;
};

export type SelectedResumeProductsState = {
  internet: Product | undefined;
  voice: Product | undefined;
  secure?: {
    [key: string]: Product;
  };
  modal?: Product;
  vas?: {
    [key: string]: Product;
  };
  video?: {
    [key: string]: Product;
  };
};

export enum ModalKeys {
  AGREEMENTS = 'agreements',
  EERO = 'eero',
  VAS = 'vas',
  COMMERCIAL_CC = 'commercial_cc',
  RESUME = 'resume',
  YTV = 'ytv',
  FROZEN_CREDIT = 'frozen_credit',
  LIFT_CREDIT_FREEZE = 'lift_credit_freeze',
  OTP_VERIFY = 'otp_verify',
  EDIT_ADDRESS = 'edit_address',
  EDIT_PLAN = 'edit_plan',
  BACK_BALANCE = 'back_balance',
  AUTOPAY_ERROR = 'autopay_error',
  AUTOPAY_DISCOUNT = 'autopay_discount',
  BROADBAND_CONSUMER_LABEL = 'broadband_consumer_label',
  VRC_OFFER = 'vrc_offer',
  COMPARE_PLANS = 'compare_plans',
  NFLST = 'nflst',
  WIFI_SECURE = 'wifi_secure',
}

export type Modals =
  | ModalKeys.AGREEMENTS
  | ModalKeys.EERO
  | ModalKeys.VAS
  | ModalKeys.COMMERCIAL_CC
  | ModalKeys.RESUME
  | ModalKeys.YTV
  | ModalKeys.FROZEN_CREDIT
  | ModalKeys.LIFT_CREDIT_FREEZE
  | ModalKeys.OTP_VERIFY
  | ModalKeys.EDIT_ADDRESS
  | ModalKeys.EDIT_PLAN
  | ModalKeys.BACK_BALANCE
  | ModalKeys.AUTOPAY_ERROR
  | ModalKeys.AUTOPAY_DISCOUNT
  | ModalKeys.BROADBAND_CONSUMER_LABEL
  | ModalKeys.VRC_OFFER
  | ModalKeys.COMPARE_PLANS
  | ModalKeys.NFLST
  | ModalKeys.WIFI_SECURE
  | undefined;

// Redux
export enum ActionTypes {
  SELECT_PRODUCT = 'product/select',
  CLEAR_SELECT_PRODUCT = 'product/select/clear',
  SET_PRODUCTS = 'products/set',
  SET_OFFER_TYPE = 'products/offerType/set',
  SET_BROADBAND_TYPE = 'products/broadbandType/set',
  SET_SELECTED_SWEETENER = 'products/planPageSelections/selectedSweetener/set',
  SET_PHONE_SELECTION = 'products/planPageSelections/phoneSelection/set',
  SET_ADDON_BUNDLE = 'products/planPageSelections/selectedAddonBundle/set',
  SET_FIRST_AVAILABLE = 'products/firstAvailable/set',
  SET_NDI_BANNER = 'products/ndiBanner/set',
  SET_LANDING_PAGE_PRODUCT = 'products/landingPageProduct/set',
  SET_LANDING_PAGE_PRODUCT_AVAILABLE = 'products/landingPageProductAvailable/set',
}

export enum SweetenerKeys {
  EERO = 'eero',
  VRC = 'vrc',
  WAIVE_INSTALL = 'waive-install-charge',
  WIFI_SECURITY_FREE_TRIAL = 'wifi-security-free-trial',
}

export interface SetProductsAction {
  type: ActionTypes.SET_PRODUCTS;
  available: {
    [key: string]: Product[];
  };
  offerLevelSweeteners: {
    has2Gig: boolean;
    sweetenerLegalKeys: SweetenerKeys[];
  };
}

export interface SetOfferTypeAction {
  type: ActionTypes.SET_OFFER_TYPE;
  offerType: string;
}

export interface SetBroadbandTypeAction {
  type: ActionTypes.SET_BROADBAND_TYPE;
  broadbandType: string;
}

export interface SelectProductAction {
  type: ActionTypes.SELECT_PRODUCT;
  selectedState: SelectedProductsState | SelectedResumeProductsState;
}

export interface ClearSelectProductAction {
  type: ActionTypes.CLEAR_SELECT_PRODUCT;
}

export interface SetSelectedSweetenerAction {
  type: ActionTypes.SET_SELECTED_SWEETENER;
  selectedSweetener: Option;
}

export interface SetAddonBundle {
  type: ActionTypes.SET_ADDON_BUNDLE;
  selectedAddonBundle: AddonBundle;
}

export interface SetPhoneSelection {
  type: ActionTypes.SET_PHONE_SELECTION;
  phoneSelection: string;
}

export interface FirstAvailableAction {
  type: ActionTypes.SET_FIRST_AVAILABLE;
  firstAvailable: string;
}

export interface SetNDIBannerAction {
  type: ActionTypes.SET_NDI_BANNER;
  ndiBanner: boolean;
}

export interface SetLandingPageProduct {
  type: ActionTypes.SET_LANDING_PAGE_PRODUCT;
  landingPageProduct: string;
}

export interface SetLandingPageProductAvailable {
  type: ActionTypes.SET_LANDING_PAGE_PRODUCT_AVAILABLE;
  landingPageProductAvailable: boolean;
}

export type Actions =
  | SetProductsAction
  | SelectProductAction
  | ClearSelectProductAction
  | SetOfferTypeAction
  | SetBroadbandTypeAction
  | SetSelectedSweetenerAction
  | SetAddonBundle
  | SetPhoneSelection
  | FirstAvailableAction
  | SetNDIBannerAction
  | SetLandingPageProduct
  | SetLandingPageProductAvailable;

export interface PlanCardsMetadata {
  [key: string]: {
    bannerEnabled: string;
    bannerDetails: {
      bannerText: string;
      bannerIcon: string;
    };
    name: string;
    order: string;
    bestFor?: string;
    featureList?: [{ text: string; legal: string }];
    useBulletsWithFeatureList: string;
    outerBanner?: {
      outerBannerText?: string;
      outerBannerBackground?: string;
    };
    totalValueEnabled?: string;
  };
}

export interface MatchedProducts {
  [key: string]: Product;
}
