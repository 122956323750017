/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Search = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--search"
      {...props}
    >
      <path d="M9.63780365,10.8002894 C8.62957259,11.5650637 7.37250145,12.0188743 6.00943717,12.0188743 C2.69051667,12.0188743 4.97379915e-14,9.32835767 4.97379915e-14,6.00943717 C4.97379915e-14,2.69051667 2.69051667,-7.10542736e-15 6.00943717,-7.10542736e-15 C9.32835767,-7.10542736e-15 12.0188743,2.69051667 12.0188743,6.00943717 C12.0188743,7.42377089 11.530283,8.72398799 10.7126799,9.75050879 L13.5943192,12.632148 C13.8876731,12.9255019 13.8876731,13.4011226 13.5943192,13.6944765 C13.3009653,13.9878304 12.8253446,13.9878304 12.5319907,13.6944765 L9.63780365,10.8002894 Z M6.00943717,10.516515 C8.49862755,10.516515 10.516515,8.49862755 10.516515,6.00943717 C10.516515,3.52024679 8.49862755,1.50235929 6.00943717,1.50235929 C3.52024679,1.50235929 1.50235929,3.52024679 1.50235929,6.00943717 C1.50235929,8.49862755 3.52024679,10.516515 6.00943717,10.516515 Z M11.1021107,11.4584445 L11.4584445,11.1021107 C11.8489688,10.7115864 12.4821338,10.7115864 12.8726581,11.1021107 L15.7077583,13.9372109 C16.0982826,14.3277352 16.0982826,14.9609001 15.7077583,15.3514244 L15.3514244,15.7077583 C14.9609001,16.0982826 14.3277352,16.0982826 13.9372109,15.7077583 L11.1021107,12.8726581 C10.7115864,12.4821338 10.7115864,11.8489688 11.1021107,11.4584445 Z" />
    </svg>
  );
};

Search.displayName = 'Search';

export default Search;
