/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Gamepad = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--gamepad"
      {...props}
    >
      <path
        d="M8.52197 11.5686C8.52197 10.8756 9.10423 10.3137 9.8225 10.3137C10.5408 10.3137 11.123 10.8756 11.123 11.5686V12.1961H11.7733C12.4915 12.1961 13.0738 12.7579 13.0738 13.451C13.0738 14.144 12.4915 14.7059 11.7733 14.7059H11.123V15.3333C11.123 16.0264 10.5408 16.5882 9.8225 16.5882C9.10423 16.5882 8.52197 16.0264 8.52197 15.3333V14.7059H7.8717C7.15344 14.7059 6.57118 14.144 6.57118 13.451C6.57118 12.7579 7.15344 12.1961 7.8717 12.1961H8.52197V11.5686Z"
        fill="#141928"
      />
      <path
        d="M23.478 10.9412C23.478 11.6342 22.8958 12.1961 22.1775 12.1961C21.4592 12.1961 20.877 11.6342 20.877 10.9412C20.877 10.2481 21.4592 9.68628 22.1775 9.68628C22.8958 9.68628 23.478 10.2481 23.478 10.9412Z"
        fill="#141928"
      />
      <path
        d="M22.1775 17.2157C22.8958 17.2157 23.478 16.6539 23.478 15.9608C23.478 15.2677 22.8958 14.7059 22.1775 14.7059C21.4592 14.7059 20.877 15.2677 20.877 15.9608C20.877 16.6539 21.4592 17.2157 22.1775 17.2157Z"
        fill="#141928"
      />
      <path
        d="M20.877 13.451C20.877 14.144 20.2947 14.7059 19.5765 14.7059C18.8582 14.7059 18.2759 14.144 18.2759 13.451C18.2759 12.7579 18.8582 12.1961 19.5765 12.1961C20.2947 12.1961 20.877 12.7579 20.877 13.451Z"
        fill="#141928"
      />
      <path
        d="M24.7786 14.7059C25.4968 14.7059 26.0791 14.144 26.0791 13.451C26.0791 12.7579 25.4968 12.1961 24.7786 12.1961C24.0603 12.1961 23.478 12.7579 23.478 13.451C23.478 14.144 24.0603 14.7059 24.7786 14.7059Z"
        fill="#141928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14317 8.74941C3.80853 6.34184 6.06822 4.66667 8.65052 4.66667C10.1622 4.66667 11.612 5.24612 12.6809 6.27754L13.6125 7.17648H18.3875L19.3191 6.27754C20.388 5.24612 21.8378 4.66667 23.3495 4.66667C25.9318 4.66667 28.1915 6.34184 28.8568 8.74941L31.8149 19.453C32.7282 22.7578 30.1467 26 26.6021 26C25.0727 26 23.6151 25.3736 22.592 24.2767L18.347 19.7255H13.653L9.40798 24.2767C8.38486 25.3736 6.92733 26 5.39792 26C1.85326 26 -0.72822 22.7578 0.185108 19.453L3.14317 8.74941ZM8.65052 7.17648C7.24663 7.17648 6.01813 8.08719 5.6564 9.39609L2.69834 20.0997C2.22535 21.8111 3.56223 23.4902 5.39792 23.4902C6.18996 23.4902 6.94479 23.1658 7.47463 22.5978L12.4946 17.2157H19.5054L24.5254 22.5978C25.0552 23.1658 25.81 23.4902 26.6021 23.4902C28.4378 23.4902 29.7747 21.8111 29.3017 20.0997L26.3436 9.39609C25.9819 8.08719 24.7534 7.17648 23.3495 7.17648C22.5276 7.17648 21.7395 7.4915 21.1583 8.05224L19.4649 9.68628H12.5351L10.8417 8.05224C10.2605 7.4915 9.47236 7.17648 8.65052 7.17648Z"
        fill="#141928"
      />
    </svg>
  );
};

Gamepad.displayName = 'Gamepad';

export default Gamepad;
