import React, { SVGProps } from 'react';

const Rectangle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-rectangle"
      {...props}
    >
      <rect y="0.5" width={'100%'} height={'100%'} fill="#FF0037" />
    </svg>
  );
};

Rectangle.displayName = 'Rectangle';

export default Rectangle;
