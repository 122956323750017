import React, { SVGProps } from 'react';

const Bolt = (props: SVGProps<SVGSVGElement>): JSX.Element => (
<svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon icon--fill-bolt" {...props}>
    <title>High Speed Internet icon</title>
    <g fill="none" fillRule="evenodd">
    <circle fill="#01315F" cx={45} cy={45} r={45} />
    <path d="M10.4069 5.70996C10.7231 5.70996 10.969 5.85059 11.1446 6.13184C11.2851 6.41309 11.2851 6.69434 11.1446 6.97559L4.96194 17.6631C4.7863 17.9443 4.5404 18.085 4.22424 18.085C3.94321 18.085 3.69731 17.9795 3.55679 17.7686C3.38115 17.5576 3.34602 17.3115 3.41628 17.0303L5.0322 10.21H0.851874C0.570843 10.21 0.36007 10.1396 0.219555 9.92871C0.043911 9.75293 -0.0263466 9.54199 0.0087822 9.26074L1.1329 0.823242C1.1329 0.612305 1.23829 0.436523 1.41393 0.295898C1.55445 0.155273 1.76522 0.0849609 1.976 0.0849609H6.75351C7.03454 0.0849609 7.24532 0.225586 7.42096 0.436523C7.56148 0.647461 7.63173 0.893555 7.56148 1.13965L6.3671 5.70996H10.4069Z" fill="#141928"/>
    </g>
</svg>
);

Bolt.displayName = 'Bolt';

export default Bolt;
