/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const TV = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="20px"
      height="16px"
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--tv"
      {...props}
    >
      <path
        d="M1 0h18.18c.452 0 .82.363.82.807v10.73c0 .608-.43 1.01-1 1.01h-8.308v1.498c1.941.44 3.231.76 3.87.955.316.148.438.28.438.5 0 .324-.279.5-.5.5h-9a.508.508 0 01-.5-.5c0-.183.08-.328.242-.435.806-.343 3.026-.857 4.066-1.076v-1.442H1c-.622 0-1-.413-1-.947V1c0-.52.4-1 1-1zm.5 11h17V1.5h-17V11z"
        fillRule="nonzero"
      />
    </svg>
  );
};

TV.displayName = 'TV';

export default TV;
