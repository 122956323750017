/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CheckCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--check-circle"
      {...props}
    >
      <path d="M14.5,8.00041346 L16,8.00041346 C16,12.4185716 12.4183673,16 8,16 C3.58163267,16 0,12.4185716 0,8.00041346 C0,3.58156951 3.58149159,0 8,0 C12.4185084,0 16,3.58156951 16,8.00041346 L14.5,8.00041346 C14.5,4.40998933 11.590074,1.5 8,1.5 C4.40992602,1.5 1.5,4.40998933 1.5,8.00041346 C1.5,11.5901253 4.41004065,14.5 8,14.5 C11.5899594,14.5 14.5,11.5901253 14.5,8.00041346 Z M4.50847886,7.44874438 L6.46610794,9.63270816 L10.9825091,4.69638242 C11.2621152,4.39077921 11.7365211,4.36970438 12.0421243,4.64931045 C12.3477275,4.92891653 12.3688023,5.40332238 12.0891962,5.70892558 L7.013753,11.2562718 C6.71436884,11.583492 6.19796146,11.5808582 5.9019306,11.2506012 L3.39152114,8.44994639 C3.11504684,8.14150703 3.14095964,7.66734082 3.449399,7.39086652 C3.75783836,7.11439223 4.23200456,7.14030503 4.50847886,7.44874438 Z" />
    </svg>
  );
};

CheckCircle.displayName = 'CheckCircle';

export default CheckCircle;
