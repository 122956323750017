/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CloudData = (props: SVGProps<SVGSVGElement>): JSX.Element => {

  return (
    <svg width="32" height="31" viewBox="0 0 32 31" className="icon icon--cloud-data" {...props}>
      <path
        d="M8 12.3334V8.33337C8 5.38786 10.3878 3.00004 13.3333 3.00004H18.6667C21.6122 3.00004 24 5.38786 24 8.33337V11H26.6667C28.1394 11 29.3333 12.1939 29.3333 13.6667V18.3334C29.3333 19.8061 28.1394 21 26.6667 21H26V23.6667H26.6667C29.6122 23.6667 32 21.2789 32 18.3334V13.6667C32 10.7212 29.6122 8.33337 26.6667 8.33337C26.6667 3.9151 23.0849 0.333374 18.6667 0.333374H13.3333C8.91506 0.333374 5.33333 3.9151 5.33333 8.33337V9.66671H4.66667C2.08934 9.66671 0 11.756 0 14.3334V19C0 21.5774 2.08934 23.6667 4.66667 23.6667H6V21H4.66667C3.5621 21 2.66667 20.1046 2.66667 19V14.3334C2.66667 13.2288 3.5621 12.3334 4.66667 12.3334H8Z"
        fill="currentColor"
      />
      <path
        d="M18.6667 17.5523L16.9428 19.2762L15.0572 17.3906L20 12.4478L24.9428 17.3906L23.0572 19.2762L21.3333 17.5523V28.3334H18.6667V17.5523Z"
        fill="currentColor"
      />
      <path
        d="M13.3333 25.1144L15.0572 23.3906L16.9428 25.2762L12 30.219L7.05719 25.2762L8.94281 23.3906L10.6667 25.1144V14.3334H13.3333V25.1144Z"
        fill="currentColor"
      />
    </svg>
  );
};

CloudData.displayName = 'CloudData';

export default CloudData;
