import React, { FC } from 'react';
import { Main } from './LayoutStyled';

interface Props {
  children: JSX.Element[] | JSX.Element | string;
}

const OneColumn: FC<Props> = ({ children }) => {
  return <Main>{children}</Main>;
};

OneColumn.displayName = 'OneColumn';

export default OneColumn;
