/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Shield = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox="0 0 14 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-shield"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-948.000000, -911.000000)" fill="#01315F" fillRule="nonzero">
          <g transform="translate(916.000000, 413.000000)">
            <path d="M39.4921696,512.640621 C40.4036257,512.276039 41.2786236,511.683592 42.1171634,510.863282 C43.1197653,509.878909 43.9218466,508.730474 44.5234076,507.417977 C45.2161142,505.850273 45.5624677,504.127621 45.5624677,502.250029 C45.5624677,501.976584 45.489551,501.730491 45.3437182,501.511741 C45.1978853,501.292992 44.9973647,501.138044 44.7421571,501.046899 L39.4921696,498.859404 C39.1640454,498.713571 38.8359212,498.713571 38.507797,498.859404 L33.2578095,501.046899 C33.0026019,501.138044 32.8020813,501.292992 32.6562484,501.511741 C32.5104156,501.730491 32.437499,501.976584 32.437499,502.250029 C32.437499,503.963559 32.7473939,505.576836 33.3671842,507.089853 C33.9140579,508.438808 34.6705667,509.614586 35.6367101,510.617188 C36.511708,511.546874 37.468737,512.221351 38.507797,512.640621 C38.8359212,512.786454 39.1640454,512.786454 39.4921696,512.640621 Z M38.1249854,509.332035 C38.0292824,509.332035 37.9489605,509.3064 37.8840192,509.255131 L34.9804616,506.351574 C34.8893162,506.260428 34.8437432,506.15561 34.8437432,506.037121 C34.8437432,505.918632 34.8893162,505.813814 34.9804616,505.722669 L35.5820227,505.121108 C35.6731682,505.029962 35.7779859,504.984389 35.8964751,504.984389 C36.0149642,504.984389 36.119782,505.029962 36.2109275,505.121108 L38.1249854,507.035166 L42.2265381,502.933613 C42.3176836,502.842468 42.4225013,502.796895 42.5409905,502.796895 C42.6594797,502.796895 42.7642974,502.842468 42.8554429,502.933613 L43.4570039,503.535174 C43.5481494,503.62632 43.5937224,503.731137 43.5937224,503.849626 C43.5937224,503.968116 43.5481494,504.072933 43.4570039,504.164079 L38.4257659,509.195317 C38.3528493,509.286462 38.2525894,509.332035 38.1249854,509.332035 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

Shield.displayName = 'Shield';

export default Shield;
