import styled, { css } from 'styled-components';

export const FooterStyled = styled.footer`
  ${({ theme, showFooter }) => {
    const { colors, media } = theme;

    return css`
      opacity: ${showFooter ? 1 : 0} !important;

      padding-top: 65px;
      ${media.tablet.up} {
        padding: 43px 0 62px;
      }

      ${media.tablet.up} {
        border-top: 1px solid ${colors.secondary.gray};
      }

      h2,
      h3 {
        margin-bottom: 5px;
      }

      h2 {
        font-size: 24px;
      }

      ul {
        text-align: right;

        li {
          color: ${colors.primary.gray};
          font-size: 400;
          font-size: 16px;
          line-height: 23px;
        }
      }

      .footer {
        &__phone {
          display: flex;
          font-size: 18px;
          margin-top: 20px;

          .phone-number {
            font-weight: 700;
          }
        }

        &__operations {
          text-align: right;
          display: none;

          ${media.tablet.up} {
            display: block;
            flex: 1 0 auto;
          }
        }

        &__content {
          display: flex;
          justify-content: space-between;
          line-height: 1.25;
          margin-bottom: 2rem;
        }

        &__content,
        &__legal,
        &__copyright-wrapper {
          ${media.tablet.down} {
            max-width: 100%;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
          }
        }

        &__legal-text {
          font-size: 11px;
          line-height: 1.36;
          list-style: none;
          position: relative;

          li {
            padding-left: 1.65rem;
            margin-bottom: 1.25rem;
          }
          
          li:before {
            content: "[" attr(data-annotation) "]";
            left: 0;
            position: absolute;
            text-align: right;
            width: 1.25rem;
          }

          a {
            text-decoration: underline;
          }
        }

        &__copyright {
          color: ${colors.primary.gravity};
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          padding-top: 30px;

          ${media.desktop.up} {
            padding-top: 40px;
          }
        }

        &__links {
          ${media.tablet.down} {
            max-width: 100%;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
          }
        }

        &__links-text {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 50px 0 30px;
          row-gap: 16px;
    
          li {
            color: ${colors.primary.gravity};
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            margin-right: 30px;
          }

          li:last-child {
            display: block;
          }

          li:hover {
            text-decoration: underline;
          }
        }
      }

      small {
        font-family: 'Avenir Next Condensed';
        margin: 10px 0;
        width: 100%;
      }
    }

    // Overrides default One trust styles
    button#ot-sdk-btn.ot-sdk-show-settings {
      color: ${colors.primary.gravity};
      font-weight: 700;
      border: none;
      background: none;
      font-size: 12px;
      width: auto;
      padding: 0;

      &:hover {
        color: var( --color-midnight-express );
        background: none;
        text-decoration: underline;
      }
    }
    `;
  }};
`;
