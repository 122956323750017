/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ShieldIdentity = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="40"
      height="45"
      viewBox="0 0 40 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--shieldIdentity"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M19 11C15.6863 11 13 13.6863 13 17V19C13 22.3137 15.6863 25 19 25H21C24.3137 25 27 22.3137 27 19V17C27 13.6863 24.3137 11 21 11H19ZM19 15C17.8954 15 17 15.8954 17 17V19C17 20.1046 17.8954 21 19 21H21C22.1046 21 23 20.1046 23 19V17C23 15.8954 22.1046 15 21 15H19Z" fill="#FF0037"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M40 5L35.9363 5.40637C32.0819 5.79181 28.2059 4.92355 24.8843 2.93057L20 0L15.1157 2.93058C11.7941 4.92355 7.91814 5.79181 4.06372 5.40637L0 5V21.6762C0 28.7015 3.68598 35.2116 9.71009 38.8261L20 45L30.2899 38.826C36.314 35.2116 40 28.7015 40 21.6762V5ZM36 9.41738C31.3996 9.80665 26.7901 8.73879 22.8263 6.36055L20 4.66476L17.1737 6.36055C13.2099 8.7388 8.60037 9.80665 4 9.41738V21.6762C4 25.9555 5.70954 29.9958 8.65265 32.9566L8.77207 32.8365C11.2123 30.3809 14.5313 29 17.9932 29H21.623C25.2422 29 28.6923 30.5084 31.1485 33.1532C34.2133 30.176 36 26.051 36 21.6762V9.41738ZM27.9286 35.5781C26.252 33.9317 23.9897 33 21.623 33H17.9932C15.7017 33 13.5007 33.8739 11.8353 35.4364L20 40.3352L27.9286 35.5781Z" fill="#FF0037"/>
    </svg>
  );
};

ShieldIdentity.displayName = 'ShieldIdentity';

export default ShieldIdentity;
