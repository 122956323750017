import React, { SVGProps } from 'react';

const Indicator = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      className="icon icon--fill-indicator"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect className="icon icon--fill-indicator-inner" x="2" y="2" width="28" height="28" rx="14" fill="red" />
      <rect
        className="icon icon--fill-indicator-outer"
        x="2"
        y="2"
        width="28"
        height="28"
        rx="14"
        stroke="#9E9E9E"
        strokeWidth="4"
      />
      <circle className="icon icon--fill-indicator-circle" cx="16" cy="16" r="8" fill="#FF0037" />
    </svg>
  );
};

Indicator.displayName = 'HeartCircle';

export default Indicator;
