import { EventData } from '~/types/events';
import { development, log, production } from './actions';
import { EVENTS, getContext } from './trackers/tagular';
import { getSiteData } from '../site';
import { v4 as uuid } from 'uuid';

const trackingActions = process.env.environment === 'production' ? production : development;

export const trackEvent = ({ action, data = {}, event = {} }: EventData): void => {
  if (action in trackingActions) {
    trackingActions[action].forEach((trackMethod) => trackMethod(data, event, action));
  }
};

export const fireAsyncEvent = async ({action, data}) => {
  const atomContext = getContext();

  try {
    log(data, null, `ASYNC ${action}`);
    await fetch('https://ingest.make.rvapps.io/v2/t', {
      method: 'POST',
      body: JSON.stringify({
        anonymousId: atomContext?.anonymousId || '',
        event: EVENTS[action],
        messageId: uuid(),
        properties: {
          atomContext,
          webElement: {
            elementType: data?.elementType || '',
            location: data?.location || '',
            position: data?.position || '',
            htmlId: data?.htmlID || '',
            text: data?.text || '',
          },
        },
        sentAt: new Date().toISOString(),
        timestamp: new Date().toISOString(),
        writeKey: getSiteData('writeKey')
      }),
      headers: {
        "Authorization": 'Basic ' + Buffer.from(`${getSiteData('writeKey')}:`).toString('base64')
      }
    });
  } catch (e) {
    console.log(`failed to event: ASYNC ${action}`);
  }
};
