/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const EyeClosed = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--outline-eyeClosed"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1377 2.13818L2.13775 14.1382L1.19494 13.1954L3.38954 11.0008L1.06494 8.48927C0.695929 8.09059 0.712704 7.47022 1.10272 7.09206L4.75113 3.55458C6.49088 1.86773 9.21605 1.80218 11.0324 3.35792L13.1949 1.19537L14.1377 2.13818ZM10.0856 4.30472C8.78928 3.26251 6.89657 3.33155 5.67928 4.51183L2.26469 7.8226L4.33305 10.0573L4.97892 9.41139C4.77835 8.98282 4.6663 8.50455 4.6663 8.00011C4.6663 6.15916 6.15869 4.66677 7.99964 4.66677C8.50408 4.66677 8.98235 4.77883 9.41091 4.9794L10.0856 4.30472ZM8.35816 6.03215C8.24184 6.0111 8.12202 6.00011 7.99964 6.00011C6.89507 6.00011 5.99964 6.89554 5.99964 8.00011C5.99964 8.12249 6.01063 8.24231 6.03168 8.35863L8.35816 6.03215Z"
        fill="#141928"
      />
      <path
        d="M6.82283 11.1198L7.94327 9.99933C7.96199 9.99985 7.98078 10.0001 7.99964 10.0001C9.1042 10.0001 9.99963 9.10468 9.99963 8.00011C9.99963 7.98126 9.99937 7.96247 9.99886 7.94374L11.1193 6.8233C11.2574 7.18923 11.333 7.58584 11.333 8.00011C11.333 9.84106 9.84058 11.3334 7.99964 11.3334C7.58537 11.3334 7.18876 11.2579 6.82283 11.1198Z"
        fill="#141928"
      />
      <path
        d="M13.7346 7.8226L11.8994 6.04321L12.8423 5.10028L14.8965 7.09206C15.2866 7.47022 15.3033 8.09059 14.9343 8.48927L11.4244 12.2814C9.75481 14.0853 7.01628 14.2586 5.14122 12.8014L6.09469 11.8479C7.42758 12.7784 9.29332 12.621 10.4459 11.3757L13.7346 7.8226Z"
        fill="#141928"
      />
    </svg>
  );
};

EyeClosed.displayName = 'EyeClosed';

export default EyeClosed;
