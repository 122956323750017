import { FC, useEffect } from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import FuelProvider from '~/providers/FuelProvider';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import useAppSetup from '~/hooks/useAppSetup';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ErrorBoundary from '~/components/ErrorBoundary';
import Notifications from '~/components/Notifications';
import SFCONotifications from '~/components/SingleFormCheckout/Notifications';
import { Container } from '~/components/Layout/LayoutStyled';
import Loader from '~/components/Loader';
import 'react-tippy/dist/tippy.css';
import { CohesionScript, withCohesion } from '@red-digital/bricks';
import { PAGE_ROUTES } from '~/constants/pages';
import listOfPreampAssets from '../../preamp/listOfPreampAssets';
import SessionModal from '~/components/Modal/screens/SessionExpired';
import '~/styles/scss/base.scss';
import '~/styles/bricks/all.scss';
import { callMonarchWithPreamp } from '~/helpers/monarch';
import { sanitizeNetworkData } from '~/helpers/sanitizeNetworkData';

LogRocket.init('vuvnvg/frontier', {
  release: process.env.release,
  network: {
    requestSanitizer: sanitizeNetworkData(['addressKey']),
    responseSanitizer: sanitizeNetworkData(['addressKey']),
  },
  dom: {
    privateClassNameBlocklist: ['fs-mask'],
  },
});

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { store, production, router } = useAppSetup();
  const { pathname: currentPage } = router;
  const showHeaderAndFooter = currentPage !== PAGE_ROUTES.BUY_FIBER;
  const allowSessionExpiredModal = store?.getState()?.monarch?.allowSessionExpiredModal;

  useEffect(() => {
    setupLogRocketReact(LogRocket);
  }, []);

  return (
    <FuelProvider store={store} production={production} router={router}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <CohesionScript src="https://cdn.cohesionapps.com/cohesion/cohesion-latest.min.js" />
      {showHeaderAndFooter && <Header />}
      <ErrorBoundary production={production} number={store?.getState()?.callInNumber}>
        <Container>
          <SFCONotifications />
          <Notifications />
        </Container>
        <Component {...pageProps} />
        {showHeaderAndFooter && <Footer />}
        <Loader />
      </ErrorBoundary>
      {allowSessionExpiredModal && <SessionModal />}
    </FuelProvider>
  );
};

App.displayName = 'App';

export default withCohesion({
  App,
  assets: listOfPreampAssets,
  callMonarchWithPreamp,
});
