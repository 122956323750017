import { Actions, ActionTypes } from '~/types/quiz';
import { ReduxState } from '~/types/redux';
import merge from 'lodash/merge';

const quiz = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_QUIZ:
      return merge({}, state, {
        quiz: {
          [action.key]: action.value,
        },
      });
    default:
      return state;
  }
};

export default quiz;
