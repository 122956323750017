/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronUp = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      focusable="false"
      data-icon="chevron-up"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-chevron-up"
      width="16"
      height="16"
      viewBox="0 0 22 13"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1377 10.0286L12.1949 10.9714L7.83296 6.60951L3.47103 10.9714L2.52822 10.0286L7.83296 4.72389L13.1377 10.0286Z"
        fill="currentColor"
      />
    </svg>
  );
};

ChevronUp.displayName = 'ChevronUp';

export default ChevronUp;
