import { APOptions } from './order';
import { Price } from './products';

export type PaperBillAction = {
  type: ActionTypes.SET_PAPER_BILL;
  paperBill: boolean;
};

export type PaperBillFeeAction = {
  type: ActionTypes.SET_PAPER_BILL_FEE;
  paperBillFee: Price;
};

export type SetAutoPayAction = {
  type: ActionTypes.SET_AUTO_PAY;
  key: string;
  value: boolean | string;
};

export type ResetAutoPayAction = {
  type: ActionTypes.RESET_AUTO_PAY;
};

export type UpfrontChargeAction = {
  type: ActionTypes.SET_UPFRONT_CHARGE;
  key: string;
  value: boolean | string;
};

export enum ActionTypes {
  SET_PAPER_BILL = 'paperBill/set',
  SET_PAPER_BILL_FEE = 'paperBillFee/set',
  SET_AUTO_PAY = 'autoPay/set',
  RESET_AUTO_PAY = 'autoPay/reset',
  SET_UPFRONT_CHARGE = 'upfrontCharge/set',
}

export type Actions = PaperBillAction | PaperBillFeeAction | SetAutoPayAction | ResetAutoPayAction | UpfrontChargeAction;

export interface CheckboxData {
  id: string;
  label: string;
  text: string | null;
}

export interface MonthlyPrice {
  amount: number;
  originalAmount: number;
}

export interface AutoPay {
  accepted?: boolean;
  methodSelected?: APOptions;
  methodEntered?: string;
  discountChangeAccepted?: boolean;
  error?: boolean;
}

export interface UpfrontCharge {
  methodSelected?: APOptions;
  methodEntered?: string;
}
