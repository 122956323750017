import { ProgressBar } from '../ProgressBarBricks';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';

interface Props {
  percentage?: number | string;
}
const CheckoutProgress: FC<Props> = () => {
  const { singleForm } = useSelector((store: ReduxState) => store);
  return <ProgressBar className="progress-bar" percentage={singleForm?.progress} />;
};

CheckoutProgress.displayName = 'CheckoutProgress';
export default CheckoutProgress;
