/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Caution = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-alert"
      {...props}
    >
      <path
        d="M25.5012 35.0005C25.5012 36.1051 24.6057 37.0005 23.5012 37.0005H21.5012C20.3966 37.0005 19.5012 36.1051 19.5012 35.0005V33.0005C19.5012 31.896 20.3966 31.0006 21.5012 31.0006H23.5012C24.6057 31.0006 25.5012 31.896 25.5012 33.0005V35.0005Z"
        fill="#FF0037"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.143 3.62697C19.3606 -0.771492 25.6406 -0.77149 27.8582 3.62697L43.8265 35.2993C45.8384 39.2899 42.9379 44.0005 38.4689 44.0005H6.53222C2.06318 44.0005 -0.837287 39.2899 1.17464 35.2993L17.143 3.62697ZM24.2864 5.42774L40.2548 37.1001C40.9254 38.4303 39.9586 40.0005 38.4689 40.0005H6.53222C5.04255 40.0005 4.07572 38.4303 4.74636 37.1001L20.7147 5.42774C21.4539 3.96159 23.5472 3.96159 24.2864 5.42774Z"
        fill="#FF0037"
      />
      <path
        d="M19.5005 16.0005H25.5005L24.5005 28.0005H20.5005L19.5005 16.0005Z"
        fill="#FF0037"
      />
    </svg>
  );
};

Caution.displayName = 'AngleDown';

export default Caution;
