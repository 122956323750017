/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { FC } from 'react';
import { SocialNavigationStyled } from './SocialMenuStyled';

interface SocialLink {
  icon: JSX.Element;
  href: string;
  title: string;
  target?: string;
}

interface Props {
  links: SocialLink[];
  isAddressOrPlansPage: boolean;
}

const SocialNavigation: FC<Props> = ({ ...props }) => {
  const { links, isAddressOrPlansPage } = props;

  return (
    <SocialNavigationStyled
      isAddressOrPlansPage={isAddressOrPlansPage}
      className="social-navigation"
      aria-label="Social Navigation"
    >
      <ul className="menu" aria-label="Social Menu" role="menu">
        {links.map((link: SocialLink, index: number) => (
          <li key={index} className="li" role="none">
            <a href={link.href} target={link.target || '_blank'} className="link" title={link.title} role="menuitem">
              <span className="icon">{link.icon}</span> {link.title}
            </a>
          </li>
        ))}
      </ul>
    </SocialNavigationStyled>
  );
};

SocialNavigation.displayName = 'Social Navigation';

export default SocialNavigation;
