/* eslint-disable */
/**
 * Valassis Tracking service
 */

const CART_ENTRY_NC = 'Resi';
const CART_ENTRY = `https://action.dstillery.com/orbserv/nspix?adv=cl1005049&ns=1579&nc=${CART_ENTRY_NC}&ncv=42&dstOrderId=[OrderId]&dstOrderAmount=[OrderAmount]`;

const ORDER_CONFIRMATION = 'https://action.media6degrees.com/orbserv/hbjs?pixId=45298&pcv=72';

const NOT_SERVICABLE_NC = 'CPNS';
const NOT_SERVICABLE = `https://action.dstillery.com/orbserv/nspix?adv=cl1005049&ns=1579&nc=${NOT_SERVICABLE_NC}&ncv=42&dstOrderId=[OrderId]&dstOrderAmount=[OrderAmount]`;

const SERVICEABILITY_CONFIRMED_NC = 'CPSC';
const SERVICEABILITY_CONFIRMED = `https://action.dstillery.com/orbserv/nspix?adv=cl1005049&ns=1579&nc=${SERVICEABILITY_CONFIRMED_NC}&ncv=42&dstOrderId=[OrderId]&dstOrderAmount=[OrderAmount]`;

const PACKAGE_SELECTED_NC = 'CPPS';
function packageSelectedSrc(orderId) {
  return `https://action.dstillery.com/orbserv/nspix?adv=cl1005049&ns=1579&nc=${PACKAGE_SELECTED_NC}&ncv=42&dstOrderId=${orderId}&dstOrderAmount=[OrderAmount]`;
}

function createPixel(src) {
  const $img = document.createElement('img');
  $img.src = src;
  $img.height = 1;
  $img.width = 1;

  return $img;
}

function createScript(url) {
  const $script = document.createElement('script');
  $script.src = url;
  $script.async = true;

  return $script;
}

function appendPixelFactory(id) {
  return function eventHandler() {
    document.getElementsByTagName('body')[0].appendChild(createPixel(id));
  };
}

export const cartEntry = appendPixelFactory(CART_ENTRY);

export const orderConfirmation = () => {
  document.getElementsByTagName('body')[0].appendChild(createScript(ORDER_CONFIRMATION));
};

export const notServiceable = appendPixelFactory(NOT_SERVICABLE);

export const packageSelected = (event) => {
  if (event && event.detail && event.detail.orderId) {
    document.getElementsByTagName('body')[0].appendChild(createPixel(packageSelectedSrc(event.detail.orderId)));
  }
};

export const seviceabilityConfirmed = appendPixelFactory(SERVICEABILITY_CONFIRMED);
