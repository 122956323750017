import { ActionTypes, Actions } from '~/types/debugger';
import { ReduxState } from '~/types/redux';
import merge from 'lodash/merge';

const debug = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.INJECT_DATA:
      return merge({}, state, action.state);
    default:
      return state;
  }
};

export default debug;
