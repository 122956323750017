/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ChevronDown = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      focusable="false"
      data-icon="chevron-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 13"
      className="icon icon--fill-chevron-down"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.161133 2.27612L2.04675 0.390503L10.7706 9.11436L19.4945 0.390503L21.3801 2.27612L10.7706 12.8856L0.161133 2.27612Z"
        fill="currentColor"
      />
    </svg>
  );
};

ChevronDown.displayName = 'ChevronDown';

export default ChevronDown;
