import styled, { css } from 'styled-components';

const PhoneNumberStyled = styled.a.attrs({
  className: 'phone-number',
})`
  ${({ theme, singleForm }) => {
    const { colors, media } = theme;

    return css`
      border-radius: 4px;
      color: ${singleForm ? colors.primary.gravity : colors.primary.red};
      cursor: pointer;
      display: inline;
      text-wrap: nowrap;

      &:hover {
        color: ${singleForm && colors.primary.red};
      }

      ${media.tablet.up} {
        border: 0;
        font-size: 18px;
        padding: 0;
        text-align: left;
      }

      svg {
        fill: ${colors.primary.darkBlue};
        margin-right: 0.5rem;

        ${singleForm &&
        `
          position: relative;
          top: 2px;
          transform: rotate(90deg);
        `}
      }
    `;
  }};
`;

export const PhoneNumberHeaderStyled= styled.header.attrs(() => ({
  className: 'phone-number-header',
}))`
  ${({ theme, singleForm }) => {
  const { media, colors } = theme;

    return css`
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      gap: 5px;
      align-items: baseline;

      ${media.tablet.up} {
        display: block;
        text-align: end;
      }

      svg {
        fill: ${colors.primary.darkBlue};
        margin-right: 0.5rem;

        ${singleForm &&
        `
          position: relative;
          top: 2px;
          transform: rotate(90deg);
        `}
      }

    `;
  }};
`;


export default PhoneNumberStyled
