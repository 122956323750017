import { ActionTypes, Actions } from '~/types/legal';
import { ReduxState } from '~/types/redux';
import merge from 'lodash/merge';

const legal = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_LEGAL_MAP:
      return merge({}, state, {
        legal: {
          ...state.legal,
          legalMap: action.legalMap,
        },
      });
    case ActionTypes.ADD_ACTIVE_LEGAL:
      const currentActiveLegal = state.legal?.activeLegal ?? [];
      const nonDuplicates = action.activeLegal.filter((l) => !currentActiveLegal.includes(l));
      return merge({}, state, {
        legal: {
          ...state.legal,
          activeLegal: [...currentActiveLegal, ...nonDuplicates],
        },
      });
    case ActionTypes.RESET_ACTIVE_LEGAL:
      return {
        ...state,
        legal: {
          ...state.legal,
          activeLegal: [],
        },
      };
    default:
      return state;
  }
};

export default legal;
