type Breakpoint = {
  minWidth?: number;
  maxWidth?: number;
  content: number;
};

interface Breakpoints {
  [key: string]: Breakpoint;
}

type MediaQueryType = {
  up?: string;
  down?: string;
  only?: string;
};

interface MediaQueriesInterface {
  [key: string]: MediaQueryType;
}

const mediaQueries = (breakpoints: Breakpoints): MediaQueriesInterface => {
  const media: MediaQueriesInterface = {};
  const breakpoinstsArray = Object.entries(breakpoints);

  breakpoinstsArray.forEach(([key, { minWidth, maxWidth }], index) => {
    const mediaSettings: MediaQueryType = (media[key] = {});
    const nextBreakpoint = breakpoinstsArray[index + 1];

    if (minWidth) {
      mediaSettings.up = `@media screen and (min-width: ${minWidth}px)`;
    }

    if (maxWidth) {
      mediaSettings.down = `@media screen and (max-width: ${maxWidth}px)`;
    }

    if (index === 0) {
      mediaSettings.only = `@media screen and (max-width: ${maxWidth}px)`;
    } else if (nextBreakpoint) {
      mediaSettings.only = `@media screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
    } else {
      mediaSettings.only = `@media screen and (min-width: ${minWidth}px)`;
    }
  });

  return media;
};

export default mediaQueries;
