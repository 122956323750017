import React, { SVGProps } from 'react';

const ConfirmationCheck = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="57" height="54" viewBox="0 0 57 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24.332 5.66634H29.6654C35.6696 5.66634 41.0117 8.50115 44.4263 12.9054L26.9987 30.333L18.332 21.6663L14.332 25.6663L26.9987 38.333L56.9987 8.33301L52.9987 4.33301L48.2211 9.11065C43.8195 3.7515 37.1416 0.333008 29.6654 0.333008H24.332C11.0772 0.333008 0.332031 11.0782 0.332031 24.333V29.6663C0.332031 42.9212 11.0772 53.6663 24.332 53.6663H29.6654C42.9202 53.6663 53.6654 42.9212 53.6654 29.6663V24.333C53.6654 22.8704 53.5345 21.4383 53.2839 20.0478L48.332 24.9997V29.6663C48.332 39.9757 39.9747 48.333 29.6654 48.333H24.332C14.0227 48.333 5.66536 39.9757 5.66536 29.6663V24.333C5.66536 14.0237 14.0227 5.66634 24.332 5.66634Z"
        fill="#FF0037"
      />
    </svg>
  );
};

ConfirmationCheck.displayName = 'ConfirmationCheck';
export default ConfirmationCheck;
