/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const ExternalLink = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="19px"
      height="16px"
      viewBox="0 0 19 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--external"
      {...props}
    >
      <g fillRule="nonzero">
        <path d="M14.778 8.725V14.5c0 .828-.71 1.5-1.584 1.5H1.584C.708 16 0 15.328 0 14.5v-11C0 2.672.709 2 1.583 2h8.18c.21 0 .412.079.56.22l.528.5c.5.472.146 1.28-.56 1.28h-8.18v10h10.556V9.225a.73.73 0 01.232-.53l.527-.5c.499-.473 1.352-.138 1.352.53z" />
        <path d="M19 1.167C19 .522 18.449 0 17.769 0h-5.234c-.51 0-.924.392-.924.875v.559c0 .493.43.888.95.875l2.216-.06-8.21 7.723a.722.722 0 00-.002 1.062l.788.746a.823.823 0 001.121-.002l8.152-7.777-.063 2.1c-.014.492.403.899.923.899h.59c.51 0 .924-.392.924-.875V1.167z" />
      </g>
    </svg>
  );
};

ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
