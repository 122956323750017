/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const AngleUp = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16px"
      height="10px"
      viewBox="0 0 16 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-angle-up"
      {...props}
    >
      <path
        d="M13.9999678,9.45313584 C14.1562172,9.45313584 14.2968419,9.40626095 14.4218418,9.31251118 L15.3593396,8.37501341 C15.4530893,8.25001347 15.4999642,8.11720153 15.4999642,7.97657686 C15.4999642,7.8359522 15.4530893,7.70314025 15.3593396,7.57814031 L8.42185611,0.687531739 C8.29685617,0.593781963 8.15623151,0.546907075 7.99998212,0.546907075 C7.84373273,0.546907075 7.71873279,0.593781963 7.62498301,0.687531739 L0.640624665,7.57814031 C0.546874888,7.70314025 0.5,7.8359522 0.5,7.97657686 C0.5,8.11720153 0.562499613,8.25001347 0.687499553,8.37501341 L1.57812243,9.31251118 C1.70312237,9.40626095 1.84374703,9.45313584 2,9.45313584 C2.15624581,9.45313584 2.28124575,9.40626095 2.37499553,9.31251118 L7.99998212,3.73439948 L13.6249687,9.31251118 C13.7187185,9.40626095 13.8437184,9.45313584 13.9999678,9.45313584 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

AngleUp.displayName = 'AngleUp';

export default AngleUp;
