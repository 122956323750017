import React, { SVGProps } from 'react';

const Box = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16px"
      height="15px"
      viewBox="0 0 16 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--box"
      {...props}
    >
      <path d="M1.39049826,13.6487298 L1.39791425,4.52044922 L14.6085747,4.52044922 L14.6095017,13.6487298 L1.39049826,13.6487298 Z M3.8414832,1.35127019 L7.30475087,1.35127019 L7.30475087,3.16917903 L2.19884125,3.16917903 L3.8414832,1.35127019 Z M12.1594438,1.35127019 L13.7993048,3.16917903 L8.69524913,3.16917903 L8.69524913,1.35127019 L9.99119351,1.35127019 L12.1594438,1.35127019 Z M15.998146,3.55924569 L12.79073,0 L9.99119351,0 L8.4004635,0 L3.21205098,0 L0,3.5556423 C0,3.5556423 0.0148319815,3.55834484 0.017612978,3.55924569 L0.00834298957,3.55924569 L0,13.9505135 C0,14.5279563 0.446813441,15 0.992815759,15 L15.0062572,15 C15.5531866,15 16,14.5279563 16,13.9505135 L15.998146,3.55924569 Z" />
    </svg>
  );
};

Box.displayName = 'Box';

export default Box;
