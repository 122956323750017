import clientSide from './clientSide';
import { trackEvent } from './tracking';

type ErrorTracking = {
  url: string;
  retryCount: number;
  success: boolean;
};

export const apiErrorTest = (query: string): string => {
  if (clientSide) {
    const errorParams = new URLSearchParams(window.location.search);
    const { apiTest, errorRate, errorStatus, errorMessage } = Object.fromEntries(errorParams);

    if (apiTest) {
      const params = {
        errorMessage,
        // sanitize errorStatus and errorRate
        ...(Number(errorStatus) >= 0 ? { errorStatus } : ''),
        ...(Number(errorRate) ? { errorRate } : ''),
      };
      return `${query}${new URLSearchParams(params).toString()}`;
    }
  }

  return '';
};

export const apiErrorTracking = ({ url, retryCount, success }: ErrorTracking): void => {
  trackEvent({
    action: 'apiRetry',
    data: {
      detail: {
        url,
        retryCount,
        success,
      },
    },
  });
};
