/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const AutoPay = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="28"
    height="32"
    viewBox="0 0 28 32"
     className="icon icon--auto-pay"
     {...props}>
      <path
        d="M14.0004 0.666626L18.667 3.99996L14.0004 7.33329V5.33329H11.3337C6.91538 5.33329 3.33366 8.91501 3.33366 13.3333V18.6666C3.33366 22.0917 5.4861 25.0141 8.5121 26.1548L6.49282 28.1741C3.03492 26.41 0.666992 22.8148 0.666992 18.6666V13.3333C0.666992 7.44225 5.44262 2.66663 11.3337 2.66663H14.0004V0.666626Z"
        fill="currentColor"
      />
      <path
        d="M21.9408 4.05947L19.96 6.0403C22.7357 7.29557 24.667 10.0889 24.667 13.3333V18.6666C24.667 23.0849 21.0853 26.6666 16.667 26.6666H14.0003V24.6666L9.33366 28L14.0003 31.3333V29.3333H16.667C22.558 29.3333 27.3337 24.5577 27.3337 18.6666V13.3333C27.3337 9.36061 25.1619 5.89516 21.9408 4.05947Z"
        fill="currentColor"
      />
      <path
        d="M13.0832 9.33362V11.2501H10.417C9.12832 11.2501 8.08365 12.2948 8.08365 13.5835V14.6667C8.08365 15.9554 9.12832 17 10.417 17H16.9168C17.1009 17 17.2501 17.1493 17.2501 17.3334V18.4166C17.2501 18.6007 17.1009 18.7499 16.9168 18.7499H8.66699V20.7499H13.0832V22.6667H15.0832V20.7499H16.9168C18.2054 20.7499 19.2501 19.7053 19.2501 18.4166V17.3334C19.2501 16.0447 18.2054 15 16.9168 15H10.417C10.2329 15 10.0836 14.8508 10.0836 14.6667V13.5835C10.0836 13.3994 10.2329 13.2501 10.417 13.2501H18.6667V11.2501H15.0832V9.33362H13.0832Z"
        fill="currentColor"
      />
    </svg>
  );
};

AutoPay.displayName = 'AutoPay';

export default AutoPay;
