import React, { SVGProps } from 'react';

const Coin = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="icon icon--coin"
    >
      <path
        d="M17.1999 11.2502H20.7834V13.2502H12.5337C12.3496 13.2502 12.2003 13.3995 12.2003 13.5836V14.6668C12.2003 14.8509 12.3496 15.0002 12.5337 15.0002H19.0335C20.3221 15.0002 21.3668 16.0448 21.3668 17.3335V18.4167C21.3668 19.7054 20.3221 20.7501 19.0335 20.7501H17.1999V22.6668H15.1999V20.7501H10.7837V18.7501H19.0335C19.2176 18.7501 19.3668 18.6008 19.3668 18.4167V17.3335C19.3668 17.1494 19.2176 17.0002 19.0335 17.0002H12.5337C11.245 17.0002 10.2003 15.9555 10.2003 14.6668V13.5836C10.2003 12.2949 11.245 11.2502 12.5337 11.2502H15.1999V9.33374H17.1999V11.2502Z"
        fill={props?.fill || '#141928'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.78369 13.3334C2.78369 7.44238 7.55932 2.66675 13.4504 2.66675H18.7837C24.6747 2.66675 29.4504 7.44238 29.4504 13.3334V18.6667C29.4504 24.5578 24.6747 29.3334 18.7837 29.3334H13.4504C7.55932 29.3334 2.78369 24.5578 2.78369 18.6667V13.3334ZM13.4504 5.33341H18.7837C23.202 5.33341 26.7837 8.91514 26.7837 13.3334V18.6667C26.7837 23.085 23.202 26.6667 18.7837 26.6667H13.4504C9.03208 26.6667 5.45036 23.085 5.45036 18.6667V13.3334C5.45036 8.91514 9.03208 5.33341 13.4504 5.33341Z"
        fill={props?.fill || '#141928'}
      />
    </svg>
  );
};

Coin.displayName = 'Laptop';

export default Coin;
