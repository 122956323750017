import { environmentMap } from '~/constants/config';
import { DOMAINS } from '~/constants/config/common';
import clientSide from './clientSide';
interface ReturnType {
  isFRDC: boolean;
  isINFR: boolean;
  isFRIN: boolean;
  isAC: boolean;
}

export const getSite = (): ReturnType => {
  const siteMap = {
    isFRDC: false,
    isINFR: false,
    isFRIN: false,
    isAC: false,
  };

  if (clientSide) {
    const hostnameParts = window.location?.hostname?.split('.') ?? [];
    if (/pr\-/.test(hostnameParts?.[0])) hostnameParts.shift();
    const hostname = hostnameParts.join('.');
    if (DOMAINS.frdc.includes(hostname)) {
      siteMap.isFRDC = true;
    } else if (DOMAINS.infr.includes(hostname)) {
      siteMap.isINFR = true;
    } else if (DOMAINS.frin.includes(hostname)) {
      siteMap.isFRIN = true;
    } else if (DOMAINS.ac.includes(hostname)) {
      siteMap.isAC = true;
    }
  }

  return siteMap;
};

export const getSiteKey = (): string => {
  const { isFRDC, isINFR, isFRIN, isAC } = getSite();
  if (isFRDC) return 'frdc';
  if (isINFR) return 'infr';
  if (isFRIN) return 'frin';
  if (isAC) return 'ac';
  return '';
};

export const getSiteData = (key: string): string => {
  const siteKey = getSiteKey();
  const data = environmentMap?.[process.env.environment]?.[siteKey] || {};
  return data[key] || '';
};
