import styled, { css } from 'styled-components';

export const SessionModalStyled = styled.div.attrs({
  className: 'session-screen',
})`
  ${({ theme }) => {
    const { colors, media } = theme;
    return css`
      background-color: ${colors.primary.white};
      width: 90%;
      padding: 64px 24px;
      max-width: 1110px;
      border-radius: 32px;
      position: relative;
      text-align: center;

      .icon--fill-close-thin {
        fill: ${colors.secondary.gray};
        height: 40px;
        width: 40px;
        padding: 13px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }

      .session {
        &__modal {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          max-width: 497px;
          margin: 0 auto;
        }

        &__title {
          width: 100%;
          font-size: 30px;
          font-weight: 700;

          ${media.tablet.up} {
            font-size: 42px;
          }
        }

        &__subtitle {
          display: flex;
          flex-direction: column;
          gap: 30px;
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: -0.16px;
        }

        &__button {
          font-size: 18px;
          max-height: 46px;
          text-transform: uppercase;
          width: 252px;
        }
      }
    `;
  }}
`;
