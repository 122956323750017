/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Arrows = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--arrows"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3334 11.6667L22.3334 4.66666L29.3334 11.6667L27.3334 13.6667L22.3334 8.66666L17.3334 13.6667L15.3334 11.6667ZM21.0001 28H23.6667L23.6667 13.3333H21.0001L21.0001 28ZM11.0001 4.66666H8.33342V19.3333H11.0001V4.66666ZM16.6667 21L9.66675 28L2.66675 21L4.66675 19L9.66675 24L14.6667 19L16.6667 21Z"
        fill="#141928"
      />
    </svg>
  );
};

Arrows.displayName = 'Arrows';

export default Arrows;
