import clientSide from './clientSide';
import Cookies from 'js-cookie';

export function getQuoteId(): string {
  if (clientSide) {
    const params = new URLSearchParams(window.location.search);
    const quoteIdString = params.get('quoteId') || Cookies.get('quoteId') || '';
    return quoteIdString;
  }
  return '';
}
