/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const MultipleUsers = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="icon icon--multipleusers"
    >
      <g clipPath="url(#clip0_501_1885)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1177 0C5.49026 0 0.117676 5.37258 0.117676 12V17.3333C0.117676 23.9608 5.49026 29.3333 12.1177 29.3333H17.451C17.9908 29.3333 18.5222 29.2977 19.0431 29.2286C20.3866 30.9173 22.4594 31.9999 24.785 31.9999C28.8351 31.9999 32.1183 28.7167 32.1183 24.6666C32.1183 22.3407 31.0355 20.2677 29.3465 18.9242C29.4154 18.4037 29.451 17.8727 29.451 17.3333V12C29.451 5.37258 24.0784 0 17.451 0H12.1177ZM26.7804 17.608C26.783 17.5168 26.7843 17.4252 26.7843 17.3333V12C26.7843 6.84534 22.6057 2.66667 17.451 2.66667H12.1177C6.96302 2.66667 2.78434 6.84534 2.78434 12V17.3333C2.78434 19.148 3.30221 20.8417 4.19822 22.2747C6.56914 20.4312 9.54861 19.3333 12.7845 19.3333H16.7845C17.7096 19.3333 18.6138 19.4231 19.4888 19.5943C20.8236 18.2009 22.703 17.3333 24.785 17.3333C25.4767 17.3333 26.146 17.429 26.7804 17.608ZM17.9295 22.0571C17.553 22.0193 17.171 22 16.7845 22H12.7845C10.2048 22 7.82641 22.8619 5.92159 24.3134C7.56968 25.7775 9.73983 26.6667 12.1177 26.6667H17.451C17.5432 26.6667 17.6351 26.6653 17.7266 26.6627C17.5475 26.0281 17.4517 25.3585 17.4517 24.6666C17.4517 23.7475 17.6208 22.8678 17.9295 22.0571ZM8.78434 10.6667C8.78434 7.72115 11.1722 5.33333 14.1177 5.33333H15.451C18.3965 5.33333 20.7843 7.72115 20.7843 10.6667V12C20.7843 14.9455 18.3965 17.3333 15.451 17.3333H14.1177C11.1722 17.3333 8.78434 14.9455 8.78434 12V10.6667ZM14.1177 8C12.6449 8 11.451 9.19391 11.451 10.6667V12C11.451 13.4728 12.6449 14.6667 14.1177 14.6667H15.451C16.9238 14.6667 18.1177 13.4728 18.1177 12V10.6667C18.1177 9.19391 16.9238 8 15.451 8H14.1177ZM23.4517 19.3333H26.1183V23.3333H30.1177V26H26.1183V29.9999H23.4517V26H19.451V23.3333H23.4517V19.3333Z"
          fill="#141928"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="32" height="32" fill="white" transform="translate(0.117676)" />
        </clipPath>
      </defs>
    </svg>
  );
};

MultipleUsers.displayName = 'MultipleUsers';

export default MultipleUsers;
