import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { AMEX, C2F } from './common';
import { Modals } from './products';
import { NextRouter } from 'next/router';

export interface Disclosure {
  disclosureKey: string;
  name: string;
  text?: string;
  options: string[];
}

export interface NavBarProps {
  className: string;
  localeUtils: {
    getMonths: () => void;
  };
  month: Date;
  showNextButton: boolean;
  showPreviousButton: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
}
interface Window {
  end: string;
  scheduleId: string;
  start: string;
}

export interface PIIFormProps {
  fundingAccountToken: string;
  ref: MutableRefObject<HTMLIFrameElement>;
  sendPostMessage: (token: string) => void;
  setFundingAccountToken: Dispatch<SetStateAction<string>>;
  src: string;
}

export interface UseOrderProps {
  activeStep: number;
  activeModal: Modals;
  setActiveModal: (key?: Modals) => void;
  setCommercialCCModal: () => void;
  paperBill: boolean;
  totalUpfrontCharges?: number;
  nextEnabled: boolean;
  submitDisabled: boolean;
  filteredDisclosures: Disclosure[];
  handleOrderSubmit: () => void;
  handleSubmitCommercialModal: () => void;
  handleDisclosureCheckbox: (event) => void;
  setActiveStep: Dispatch<SetStateAction<number>>;
  handleAutopayClick: (checked: boolean) => void;
  handlePaper: (paperBill: boolean) => void;
  handlePaperless: (paperBill: boolean) => void;
  iframes: {
    credit: PIIFormProps;
    debit: PIIFormProps;
    ach: PIIFormProps;
  };
  install: {
    data: InstallScheduleData;
    loading: boolean;
  };
  monarch: {
    [key: string]: string | number | boolean | string[] | Record<string, unknown>;
  };
  c2f?: C2F;
  amexOffer?: AMEX;
  isCopper?: boolean;
  installOptions?: {
    data: InstallOptionsData;
    error: Error | InstallOptionsData;
    handleInstallOptions?: (installType: string) => void;
  };
  installationOptionsPending?: boolean;
  router: NextRouter;
}

interface Window {
  end: string;
  scheduleId: string;
  start: string;
}
export interface InstallScheduleData {
  appointmentMap?: {
    [key: string]: Window[];
  };
  success?: boolean;
}

export interface InstallOptionsData {
  success?: boolean;
}
export interface InstallOptionsFetchedProperties {
  error?: Error | InstallOptionsData;
  data?: InstallOptionsData;
  fetch: (options?: RequestInit) => void;
}

export interface InstallFetchedProperties {
  error?: InstallScheduleData | Error;
  loading: boolean;
  data?: InstallScheduleData;
  fetch: (options?: RequestInit) => void;
}

interface PaymentSessionFetchResponse {
  success?: boolean;
  sessionToken?: string;
}
export interface PaymentSessionFetchedProperties {
  error?: Error | PaymentSessionFetchResponse;
  loading?: boolean;
  data?: PaymentSessionFetchResponse;
  fetch: (options?: RequestInit) => Promise<PaymentSessionFetchResponse>;
}

// Redux
export enum ActionTypes {
  SET_SELECTED_TIME = 'time/set/selected',
  SET_SELECTED_DATE = 'date/set/selected',
}

export interface SelectedAppointment {
  scheduleId: string;
  startTime?: string;
  endTime?: string;
  timeFrame: string;
  date?: string;
}

export interface SelectedTimeAction {
  type: ActionTypes.SET_SELECTED_TIME;
  appointment: SelectedAppointment;
}

export interface SelectedDateAction {
  type: ActionTypes.SET_SELECTED_DATE;
  date: string;
}

export enum APOptions {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  ACH = 'ACH',
}

export type AutopayOptions = APOptions.CREDIT | APOptions.DEBIT | APOptions.ACH | null;

export type Actions = SelectedTimeAction | SelectedDateAction;

export interface OrderPageConfigMetadata {
  title: {
    enabled: string;
    copy: string;
  };
  subtitle: {
    enabled: string;
    copy: string;
  };
  banner: {
    bannerEnabled: string;
    bannerSubtitle: string;
    bannerTitle: string;
    banner200: {
      enabled: string;
      banner200Title: string;
      banner200Subtitle: string;
    };
    banner500: {
      enabled: string;
      banner500Title: string;
      banner500Subtitle: string;
    };
    banner1000: {
      enabled: string;
      bannerGigTitle: string;
      bannerGigSubtitle: string;
    };
    banner2000: {
      enabled: string;
      banner2GigTitle: string;
      banner2GigSubtitle: string;
    };
    banner5000: {
      enabled: string;
      banner5GigTitle: string;
      banner5GigSubtitle: string;
    };
    banner7000: {
      enabled: string;
      banner7GigTitle: string;
      banner7GigSubtitle: string;
    };
  };
  countdownBanner: {
    enabled: string;
    tooltip: string;
    subText: string;
  };
  install: {
    calendarKey: {
      icon: string;
      title: string;
      subtitle: string;
    };
    selfInstall: {
      enabled: string;
      title: string;
      selected: string;
      label: string;
      copy: string;
      calendarCopy?: {
        calendarLabel?: string;
        calendarSubcopy?: string;
      };
    };
    expertInstall: {
      enabled?: string;
      title?: string;
      selected: string;
      label: string;
      copy: string;
      calendarCopy?: {
        calendarLabel?: string;
        calendarSubcopy?: string;
      };
    };
  };
  installCalendar: {
    buttonText: string;
    calendarRedesignEnabled: string;
    calendarKey: {
      icon: string;
      title: string;
      subtitle: string;
    };
    tooltip: {
      enabled: string;
      title: string;
      tooltipText: string;
    };
  };
  phoneNumberHeader: {
    enabled: string;
  };
}
