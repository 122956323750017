import { Container } from '../Layout/LayoutStyled';
import SocialNavigation from '../SocialMenu';
import { Facebook, LinkedIn, TwitterX } from '~/components/Icons/fill';

const socialLinks = [
  { icon: <LinkedIn />, href: 'https://www.linkedin.com/company/163906/', title: 'LinkedIn' },
  { icon: <TwitterX />, href: 'https://twitter.com/FrontierCorp', title: 'Twitter-X' },
  { icon: <Facebook />, href: 'https://www.facebook.com/FrontierCorp', title: 'Facebook' },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FooterLinks = ({ pageRoute }) => {
  const isAddressOrPlansPage = pageRoute === '/' || pageRoute === '/plan-package';

  return (
    <Container className="footer__links">
      <ul className="footer__links-text">
        <li>
          <a href="https://frontier.com/buy">Home</a>
        </li>
        <li>
          <a href="https://frontier.com/sitemap-adhoc-page">Site Map</a>
        </li>
        <li>
          <a href="https://frontier.com/corporate/retailstores/locations">Store Locator</a>
        </li>
        <li>
          <a href="https://frontier.com/corporate/policies">Policies &amp; Notifications</a>
        </li>
        <li>
          <a href="https://frontier.com/corporate/terms">Terms &amp; Conditions</a>
        </li>
        <li>
          <a href="https://frontier.com/corporate/privacy-policy">Privacy Policy</a>
        </li>
        <li>
          <a href="https://frontier.com/corporate/privacy-policy-california">California Privacy Policy</a>
        </li>
        <li>
          <a href="https://frontier.com/corporate/privacy-policy-connecticut-texas">CT, TX Privacy Policy</a>
        </li>
      </ul>
      <button id="ot-sdk-btn" className="ot-sdk-show-settings">
        Do Not Sell or Share My Personal Information
      </button>
      <SocialNavigation links={socialLinks} isAddressOrPlansPage={isAddressOrPlansPage} />
    </Container>
  );
};

FooterLinks.displayName = 'FooterLinks';

export default FooterLinks;
