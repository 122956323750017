/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Lock = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="13px"
      height="16px"
      viewBox="0 0 13 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-lock"
      {...props}
    >
      <path d="M7.84237145,13.5937394 L5.1594549,13.5937394 L5.72836471,11.4460701 C5.25259904,11.1765907 4.9284209,10.6748327 4.9284209,10.0923217 C4.9284209,9.22853578 5.63156786,8.52988545 6.5,8.52988545 C7.36843214,8.52988545 8.07340545,9.22853578 8.07340545,10.0923217 C8.07340545,10.6748327 7.74831413,11.1765907 7.27346165,11.4460701 L7.84237145,13.5937394 Z M6.5,1.36100715 C8.37749368,1.36100715 9.90524024,2.87807644 9.90524024,4.74356357 L9.90524024,5.94760122 L3.09658612,5.94760122 L3.09658612,4.74356357 C3.09658612,2.87807644 4.6234195,1.36100715 6.5,1.36100715 L6.5,1.36100715 Z M11.275007,4.74356357 C11.275007,2.1340592 9.12629952,1.77635684e-15 6.5,1.77635684e-15 C3.87461366,1.77635684e-15 1.72681933,2.1340592 1.72681933,4.74356357 L1.72681933,5.98480209 L1,5.98480209 C0.557767581,5.98480209 1.1812773e-13,6.29909789 1.1812773e-13,7 L1.1812773e-13,15 C1.1812773e-13,15.4117965 0.335894619,16 1,16 L12,16 C12.3781982,16 13,15.6888996 13,15 L13,7 C13,6.41967191 12.5302381,6 12,6 L11.275007,5.98480209 L11.275007,4.74356357 Z" />
    </svg>
  );
};

Lock.displayName = 'Lock';

export default Lock;
