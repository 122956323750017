import React from 'react';
import classnames from 'classnames';
import { BodyVariants, FontWeights } from './common';

type BodyTags = 'p' | 'span' | 'label' | 'small';

export type BodyProps<T extends BodyTags> = {
  type?: T;
  variant?: BodyVariants;
  children: React.ReactNode;
  modifier?: FontWeights;
} & React.ComponentPropsWithoutRef<T>;

export const Body = React.forwardRef<HTMLElement, BodyProps<BodyTags>>(
  ({ children, type = 'p', variant = 'body-md', className, modifier, ...rest }: BodyProps<BodyTags>, ref) => {
    let testId = `body-${type}`;
    const variantClassName = `b-${variant}`;
    const classNames = classnames(className, 'body', `body-${type}`, {
      [`${variantClassName}`]: Boolean(variantClassName),
      [`font-${modifier}`]: Boolean(modifier),
    });

    testId += variant ? `-${variant}` : '';

    return React.createElement(
      type,
      {
        ref,
        'data-testid': testId,
        className: classNames,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(rest as any),
      },
      children
    );
  }
);

Body.displayName = 'Body';
