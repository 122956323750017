import React, { SVGProps } from 'react';

const Youtube = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_5739_54832)">
        <path
          d="M7.9426 8.01953L11.5779 10.1664L7.9426 12.3132V8.01953ZM17.9168 3.17429V17.1425C17.9168 18.1941 17.0958 19.0473 16.0839 19.0473H2.64238C1.63045 19.0473 0.809448 18.1941 0.809448 17.1425V3.17429C0.809448 2.12271 1.63045 1.26953 2.64238 1.26953H16.0839C17.0958 1.26953 17.9168 2.12271 17.9168 3.17429ZM16.313 10.1703C16.313 10.1703 16.313 7.80525 16.0228 6.67033C15.8624 6.04334 15.3927 5.55128 14.7932 5.38461C13.7125 5.07906 9.36313 5.07906 9.36313 5.07906C9.36313 5.07906 5.01373 5.07906 3.93307 5.38461C3.33355 5.55128 2.86386 6.04334 2.70348 6.67033C2.41326 7.80128 2.41326 10.1703 2.41326 10.1703C2.41326 10.1703 2.41326 12.5354 2.70348 13.6703C2.86386 14.2973 3.33355 14.7695 3.93307 14.9362C5.01373 15.2378 9.36313 15.2378 9.36313 15.2378C9.36313 15.2378 13.7125 15.2378 14.7932 14.9322C15.3927 14.7656 15.8624 14.2933 16.0228 13.6664C16.313 12.5354 16.313 10.1703 16.313 10.1703Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="17.1074" height="20.3175" fill="white" transform="translate(0.809448)" />
        </clipPath>
      </defs>
    </svg>
  );
};

Youtube.displayName = 'Youtube';

export default Youtube;
