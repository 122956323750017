/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const WifiBoxWhite = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--wifibox-white"
      {...props}
    >
      <path
        d="M29.1349 15.6951C26.8196 14.0005 23.9641 13 20.875 13H19.125C16.0822 13 13.2661 13.9707 10.9696 15.6194L8.8931 12.8013C11.7706 10.724 15.3049 9.5 19.125 9.5H20.875C24.7521 9.5 28.3347 10.7608 31.2352 12.8948L29.1349 15.6951Z"
        fill="white"
      />
      <path
        d="M27.0346 18.4956C25.3044 17.2402 23.1762 16.5 20.875 16.5H19.125C16.8595 16.5 14.7616 17.2175 13.0461 18.4376L15.123 21.2561C16.2572 20.4644 17.6369 20 19.125 20H20.875C22.3882 20 23.7892 20.4801 24.934 21.2963L27.0346 18.4956Z"
        fill="white"
      />
      <path
        d="M16.5 27C16.5 25.067 18.067 23.5 20 23.5C21.933 23.5 23.5 25.067 23.5 27C23.5 28.933 21.933 30.5 20 30.5C18.067 30.5 16.5 28.933 16.5 27Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 12.125C0.75 5.84276 5.84276 0.75 12.125 0.75H27.875C34.1572 0.75 39.25 5.84276 39.25 12.125V27.875C39.25 34.1572 34.1572 39.25 27.875 39.25H12.125C5.84276 39.25 0.75 34.1572 0.75 27.875V12.125ZM12.125 4.25H27.875C32.2242 4.25 35.75 7.77576 35.75 12.125V27.875C35.75 32.2242 32.2242 35.75 27.875 35.75H12.125C7.77576 35.75 4.25 32.2242 4.25 27.875V12.125C4.25 7.77576 7.77576 4.25 12.125 4.25Z"
        fill="white"
      />
    </svg>
  );
};

WifiBoxWhite.displayName = 'WifiBoxWhite';

export default WifiBoxWhite;
