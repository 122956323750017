import clientSide from './clientSide';

export const formatPhone = (value = ''): string => {
  let phone = '';
  if (value.length > 0) phone += value.substring(0, 3);
  if (value.length > 3) phone += `-${value.substring(3, 6)}`;
  if (value.length > 6) phone += `-${value.substring(6, 10)}`;
  return phone;
};

export const unformatPhone = (value = ''): string => {
  return value.replace(/\D/g, '');
};

/* The primary input has hover state; Enable hover state on devices that include a mouse/stylus */
export const hasHoverState =
  clientSide && window?.matchMedia && window?.matchMedia('(hover: hover) and (pointer: fine)').matches;
