const getValue = (colorVal) => {
  const value = colorVal;
  if (value >= 255) return 255;
  if (value <= 0) return 0;
  return value;
};

const convertHexToRgba = (hexCode = '#000000', opacity = 1): string => {
  const hexRegex = /#[a-f\d]{3}(?:[a-f\d]{3})?\b/i;
  if (!hexRegex.test(hexCode)) return hexCode;

  let hex = hexCode.replace('#', '');

  // convert hex3 to hex6
  if (hex.length === 3) {
    const h = hex.split('');
    hex = `${h[0]}${h[0]}${h[1]}${h[1]}${h[1]}${h[1]}`;
  }

  const RR = getValue(parseInt(hex.slice(0, 2), 16));
  const GG = getValue(parseInt(hex.slice(2, 4), 16));
  const BB = getValue(parseInt(hex.slice(4, 6), 16));

  return `rgba(${RR}, ${GG}, ${BB}, ${opacity})`;
};

export default convertHexToRgba;
