import React, { SVGProps } from 'react';

const Check = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="24px"
      height="20px"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-check"
      {...props}
    >
      <path
        d="M24 2.72 21.85.572 7.523 14.9 2.15 9.527 0 11.676l7.522 7.522L24 2.721Z"
        fill={props.fill || 'currentColor'}
      />
    </svg>
  );
};

Check.displayName = 'Check';

export default Check;
