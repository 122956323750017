/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CircleUp = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="icon icon--circle-up"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.104492 14.4C0.104492 6.4471 6.55159 0 14.5045 0H17.7045C25.6574 0 32.1045 6.4471 32.1045 14.4V17.6C32.1045 25.5529 25.6574 32 17.7045 32H14.5045C6.55159 32 0.104492 25.5529 0.104492 17.6V14.4ZM14.5045 3.2H17.7045C23.8901 3.2 28.9045 8.21441 28.9045 14.4V17.6C28.9045 23.7856 23.8901 28.8 17.7045 28.8L17.7045 13.6L22.1045 18L24.5045 15.6L16.1045 7.2L7.70449 15.6L10.1045 18L14.5045 13.6L14.5045 28.8C8.3189 28.8 3.30449 23.7856 3.30449 17.6V14.4C3.30449 8.21441 8.3189 3.2 14.5045 3.2Z"
        fill="#141928"
      />
    </svg>
  );
};

CircleUp.displayName = 'CircleUp';

export default CircleUp;
