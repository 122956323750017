import { Actions, ActionTypes } from '~/types/common';
import { ReduxState } from '~/types/redux';
import merge from 'lodash/merge';
import assign from 'lodash/assign';
import { getDefaultState } from '../setup/getInitialState';

const common = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_TRACKING:
      return merge({}, state, {
        tracking: {
          [action.key]: action.id,
        },
      });
    case ActionTypes.SET_NOTIFICATIONS:
      return assign({}, state, {
        notifications: action.notifications,
      });
    case ActionTypes.SET_PAGE_LOADING:
      return merge({}, state, {
        pageLoading: action.loading,
      });
    case ActionTypes.SET_CALL_IN_NUMBNER:
      return merge({}, state, {
        callInNumber: action.callInNumber,
      });
    case ActionTypes.REMOVE_TOKEN:
      return assign({}, state, {
        token: undefined,
      });
    case ActionTypes.REMOVE_ACP:
      return assign({}, state, {
        acp: undefined,
      });
    case ActionTypes.REMOVE_EXPIRATION_BANNER:
      return assign({}, state, {
        expirationBanner: {
          expirationTime: undefined,
          expired: undefined,
        },
      });
    case ActionTypes.SET_MONARCH_DECISION:
      return assign({}, state, {
        monarch: action.payload,
      });
    case ActionTypes.SET_PREAMP_ACTION:
      return merge({}, state, {
        preamp: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.COUNTDOWN_EXPIRATION:
      return assign({}, state, {
        countdownExpiration: action.countdownExpiration,
      });
    case ActionTypes.SET_C2F:
      return merge({}, state, {
        c2f: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.SET_RESUME:
      return merge({}, state, {
        resume: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.SET_AMEX:
      return assign({}, state, {
        amexOffer: true,
      });
    case ActionTypes.SET_EXPIRATION_BANNER:
      return merge({}, state, {
        expirationBanner: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.SET_FEE_CONSENT:
      return assign({}, state, {
        commercialFeeConsent: action.commercialFeeConsent,
      });
    case ActionTypes.SET_CARD_TYPE:
      return assign({}, state, {
        cardType: action.cardType,
      });
    case ActionTypes.SET_COMMERCIAL_CC_FEE:
      return assign({}, state, {
        commercialCreditCardfee: action.commercialCreditCardfee,
      });
    case ActionTypes.SET_COOKIE_PASS:
      return assign({}, state, {
        cookiePass: action.cookiePass,
      });
    case ActionTypes.SET_SESSION_EXPIRED:
      return assign({}, state, {
        sessionExpired: action.expired,
      });
    case ActionTypes.SET_SINGLE_FORM:
      return merge({}, state, {
        singleForm: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.SET_HIDE_DEBUG:
      return merge({}, state, {
        hideDebug: action.value,
      });
    case ActionTypes.RESET_STATE:
      const isProd = process.env.environment === 'production';
      return getDefaultState(isProd);
    case ActionTypes.SET_INSTALLATION_OPTIONS:
      return merge({}, state, {
        installationOptions: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.SET_AVAILABILITY:
      return assign({}, state, {
        availability: action.availability,
      });
    case ActionTypes.CLEAR_AVAILABILITY:
      return assign({}, state, {
        availability: undefined,
      });
    case ActionTypes.SET_CUSTOM_OFFERS:
      return merge({}, state, {
        customOffers: {
          [action.key]: action.value,
        },
      });
    case ActionTypes.SET_DISCOUNT_OPTIONS:
      const updatedTracking = [...state.discountOptions.tracking];
      action.value.forEach((newOption) => {
        const existingIndex = updatedTracking.findIndex((option) => option.name === newOption.name);
        if (existingIndex !== -1) {
          updatedTracking[existingIndex] = newOption;
        } else {
          updatedTracking.push(newOption);
        }
      });
      return {
        ...state,
        discountOptions: {
          ...state.discountOptions,
          tracking: updatedTracking,
        },
      };
    case ActionTypes.SET_LIW_PAGE_REASON:
      return merge({}, state, {
        liwPageReason: action.reason,
      });
    case ActionTypes.SET_BUYFLOW_REDIRECT_DELAY:
      return merge({}, state, {
        buyflowRedirectDelay: action.value,
      });
    default:
      return state;
  }
};

export default common;
