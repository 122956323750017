import { ActionTypes, Actions } from '~/types/order';
import { ReduxState } from '~/types/redux';
import merge from 'lodash/merge';
import assign from 'lodash/assign';

const appointment = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_TIME:
      const { appointment } = action;
      const { scheduleId, timeFrame, startTime, endTime } = appointment;
      return merge({}, state, {
        appointment: {
          scheduleId,
          timeFrame,
          startTime,
          endTime,
        },
      });

    case ActionTypes.SET_SELECTED_DATE:
      const { date } = action;
      return assign({}, state, {
        appointment: {
          date,
        },
      });

    default:
      return state;
  }
};

export default appointment;
