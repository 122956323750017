import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import { Body } from './Body';
import { BodyVariants, FontWeights } from './common';

interface ProgressBarProps extends React.ComponentPropsWithoutRef<'div'> {
  children?: string;
  orientation?: 'horizontal' | 'vertical';
  type?: 'determinate' | 'indeterminate';
  percentage?: number;
  bodyProps?: {
    variant?: BodyVariants;
    modifier?: FontWeights;
  };
}

export const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      children,
      className = '',
      orientation = 'horizontal',
      type = 'determinate',
      percentage = 0,
      bodyProps: { variant = 'caption-lg', modifier = 500 } = {},
      ...rest
    },
    ref
  ) => {
    const wrapperClassNames = classnames(
      'b-progress-bar__wrapper',
      `b-progress-bar__wrapper--${orientation}`,
      className
    );
    const progressBarClassNames = classnames('b-progress-bar', `b-progress-bar--${orientation}`);
    const trackClassNames = classnames('b-progress-bar__track', {
      'b-progress-bar__track--indeterminate': type === 'indeterminate',
      'b-progress-bar__track--horizontal': type === 'indeterminate' && orientation === 'horizontal',
      'b-progress-bar__track--vertical': type === 'indeterminate' && orientation === 'vertical',
      'b-progress-bar__track--determinate': type === 'determinate',
    });

    const trackInlineStyles: CSSProperties = {};
    if (type === 'determinate') {
      trackInlineStyles[orientation === 'horizontal' ? 'width' : 'height'] = `${percentage}%`;
    }

    return (
      <div className={wrapperClassNames}>
        <div
          className={progressBarClassNames}
          ref={ref}
          data-testid="progress-bar"
          role="progressbar"
          aria-label="Progress Bar"
          aria-valuemin={0}
          aria-valuemax={100}
          aria-valuenow={type === 'determinate' ? percentage : undefined}
          {...rest}
        >
          <div className={trackClassNames} style={trackInlineStyles} />
        </div>
        {children && orientation === 'horizontal' && (
          <Body className="b-progress-bar__label" variant={variant} modifier={modifier}>
            {children}
          </Body>
        )}
      </div>
    );
  }
);

ProgressBar.displayName = 'ProgressBar';
