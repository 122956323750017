import React, { SVGProps } from 'react';

const Warning = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      className="icon--fill-warning"
      fill="none"
    >
      "
      <path
        d="M18.1053 24.6667C18.1053 25.4031 17.5083 26 16.7719 26H15.4386C14.7022 26 14.1053 25.4031 14.1053 24.6667V23.3334C14.1053 22.597 14.7022 22 15.4386 22H16.7719C17.5083 22 18.1053 22.597 18.1053 23.3334V24.6667Z"
        fill="#FF0037"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5331 3.75099C14.0115 0.81868 18.1982 0.818681 19.6766 3.75099L30.3222 24.8659C31.6634 27.5263 29.7298 30.6667 26.7504 30.6667H5.4593C2.47995 30.6667 0.546301 27.5263 1.88758 24.8659L12.5331 3.75099ZM17.2954 4.9515L27.941 26.0664C28.3881 26.9532 27.7435 28 26.7504 28H5.4593C4.46619 28 3.82164 26.9532 4.26873 26.0664L14.9143 4.9515C15.4071 3.97407 16.8026 3.97406 17.2954 4.9515Z"
        fill="#FF0037"
      />
      <path d="M14.1049 12H18.1049L17.4382 20H14.7715L14.1049 12Z" fill="#FF0037" />
    </svg>
  );
};

Warning.displayName = 'Warning';
export default Warning;
