import React, { SVGProps } from 'react';

const Gift = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="icon icon--gift"
    >
      <path
        d="M30.6666 10C30.6666 6.3181 27.6818 3.33333 23.9999 3.33333H7.99992C4.31802 3.33333 1.33325 6.3181 1.33325 9.99999V22C1.33325 22.4566 1.37916 22.9025 1.46661 23.3333H3.99992L3.99992 9.99999C3.99992 7.79086 5.79078 5.99999 7.99992 5.99999L23.9999 6C26.2091 6 27.9999 7.79086 27.9999 10V23.3333H30.5332C30.6207 22.9025 30.6666 22.4566 30.6666 22V10Z"
        fill={props?.fill || '#141928'}
      />
      <path
        d="M29.3337 26H2.6661C3.88237 27.6193 5.81883 28.6667 7.99992 28.6667H23.9999C26.181 28.6667 28.1175 27.6193 29.3337 26Z"
        fill={props?.fill || '#141928'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3333 23.3333L23.3333 21.3333L19.3333 17.3333H19.9999C22.2091 17.3333 23.9999 15.5425 23.9999 13.3333V12C23.9999 9.79086 22.2091 8 19.9999 8H18.6666C17.6421 8 16.7076 8.38513 15.9999 9.01852C15.2922 8.38513 14.3577 8 13.3333 8H11.9999C9.79078 8 7.99992 9.79086 7.99992 12V13.3333C7.99992 15.5425 9.79078 17.3333 11.9999 17.3333H13.3333L9.33325 21.3333L11.3333 23.3333L16.3333 18.3333L21.3333 23.3333ZM13.3333 10.6667C14.0696 10.6667 14.6666 11.2636 14.6666 12V14.6667H11.9999C11.2635 14.6667 10.6666 14.0697 10.6666 13.3333V12C10.6666 11.2636 11.2635 10.6667 11.9999 10.6667H13.3333ZM21.3333 13.3333C21.3333 14.0697 20.7363 14.6667 19.9999 14.6667H17.3333V12C17.3333 11.2636 17.9302 10.6667 18.6666 10.6667H19.9999C20.7363 10.6667 21.3333 11.2636 21.3333 12V13.3333Z"
        fill={props?.fill || '#141928'}
      />
    </svg>
  );
};

Gift.displayName = 'Laptop';

export default Gift;
