import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { CloseThin } from '~/components/Icons/fill';
import { Restart } from '~/components/Icons/fill';
import clientSide from '~/helpers/clientSide';
import useMyCustomQuote from '~/hooks/pages/useMyCustomQuote';
import { ReduxState } from '~/types/redux';

const ExitButton: FC = () => {
  const { resetQuiz, exitQuiz } = useMyCustomQuote();
  const isResultsPage = clientSide && window?.location?.href.includes('/my-custom-quote/plan-package');
  const { quiz } = useSelector((store: ReduxState) => store);
  const { quizSessionFromLP } = quiz;

  if (quizSessionFromLP) return null;

  return isResultsPage ?  (
    <button className="quiz__exit" data-testid="restart-quiz__exit" onClick={resetQuiz}>
      <span>Restart Quiz</span>
      <Restart />
    </button>
  ) : (
    <button className="quiz__exit" data-testid="exit-quiz__exit" onClick={exitQuiz}>
      <span>Exit</span>
      <CloseThin />
    </button>
  );
};

ExitButton.displayName = 'ExitButton';

export default ExitButton;
