export const brandPaidSearch = {
  '3572574': true,
  '3572577': true,
  '3572580': true,
  '3572583': true,
  '3572586': true,
  '3572589': true,
  '3572610': true,
  '3572616': true,
  '3572619': true,
  '3572622': true,
  '3572637': true,
  '3572640': true,
  '3572643': true,
  '3572714': true,
  '3572718': true,
  '3572743': true,
  '3572749': true,
  '3572752': true,
  '3572757': true,
  '3572775': true,
  '3572778': true,
  '3572781': true,
  '3573311': true,
  '3573314': true,
  '3573317': true,
  '3573320': true,
  '3573323': true,
  '3573326': true,
  '3573329': true,
  '3573335': true,
  '3573338': true,
  '3573341': true,
  '3573344': true,
  '3573347': true,
  '3573350': true,
  '3573353': true,
  '3573356': true,
  '3573359': true,
  '3573365': true,
  '3573368': true,
  '3573371': true,
  '3573374': true,
  '3573377': true,
  '3573380': true,
  '3573383': true,
  '3573386': true,
  '3573389': true,
  '3573392': true,
  '3573398': true,
  '3573404': true,
  '3573407': true,
  '3573410': true,
  '3573413': true,
  '3573416': true,
  '3573419': true,
  '3573422': true,
  '3573425': true,
  '3573431': true,
  '3573434': true,
  '3573437': true,
  '3573440': true,
  '3573443': true,
  '3573446': true,
  '3573449': true,
  '3573452': true,
  '3573455': true,
  '3573458': true,
  '3573464': true,
  '3573470': true,
  '3573473': true,
  '3573476': true,
  '3573479': true,
  '3573482': true,
  '3573485': true,
  '3573488': true,
  '3573491': true,
  '3573497': true,
  '3573500': true,
  '3573503': true,
  '3573506': true,
  '3573509': true,
  '3573512': true,
  '3573524': true,
  '3573527': true,
  '3573530': true,
  '3573533': true,
  '3573536': true,
  '3573539': true,
  '3573554': true,
  '3573557': true,
  '3573560': true,
  '3573563': true,
  '3573566': true,
  '3573569': true,
  '3573584': true,
  '3573587': true,
  '3573590': true,
  '3573599': true,
  '3573806': true,
  '3573809': true,
  '3573812': true,
  '3573827': true,
  '3573830': true,
  '3573833': true,
  '3573836': true,
  '3573839': true,
  '3573842': true,
  '3573845': true,
  '3573863': true,
  '3573866': true,
  '3573869': true,
  '3573872': true,
  '3573875': true,
  '3573878': true,
  '3573881': true,
  '3573896': true,
  '3573899': true,
  '3573902': true,
  '3573905': true,
  '3573908': true,
  '3573911': true,
  '3573914': true,
  '3573937': true,
  '3573940': true,
  '3573943': true,
  '3573946': true,
  '3573949': true,
  '3573952': true,
  '3573955': true,
  '3573961': true,
  '3573964': true,
  '3573967': true,
  '3573970': true,
  '3573973': true,
  '3573991': true,
  '3573994': true,
  '3573997': true,
  '3574000': true,
  '3574003': true,
  '3574009': true,
  '3574012': true,
  '3574015': true,
  '3574018': true,
  '3574024': true,
  '3574027': true,
  '3574030': true,
  '3574033': true,
  '3574036': true,
  '3574045': true,
  '3574057': true,
  '3574060': true,
  '3574063': true,
  '3574066': true,
  '3574069': true,
  '3574072': true,
  '3574075': true,
  '3574078': true,
  '3574081': true,
  '3574084': true,
  '3574090': true,
  '3574093': true,
  '3574096': true,
  '3574099': true,
  '3574102': true,
  '3574117': true,
  '3574123': true,
  '3574126': true,
  '3576509': true,
  '3576515': true,
  '3576518': true,
  '3576521': true,
  '3576524': true,
  '3576530': true,
  '3576533': true,
  '3576536': true,
  '3576580': true,
  '3576583': true,
  '3576586': true,
  '3576589': true,
  '3576592': true,
  '3576595': true,
  '3576598': true,
  '3576601': true,
  '3576604': true,
  '3576607': true,
};
export const nonBrandSEO = {
  '3538214': true,
  '3538215': true,
  '3538216': true,
  '3538217': true,
  '3538218': true,
  '3538219': true,
  '3538220': true,
  '3538221': true,
  '3538222': true,
  '3538226': true,
  '3538227': true,
  '3538228': true,
  '3538347': true,
  '3538348': true,
  '3538349': true,
  '3538420': true,
  '3538421': true,
  '3538422': true,
  '3538423': true,
  '3538424': true,
  '3538425': true,
  '3538426': true,
  '3538427': true,
  '3538428': true,
  '3538429': true,
  '3538430': true,
  '3538431': true,
  '3538432': true,
  '3538433': true,
  '3538434': true,
  '3538435': true,
  '3538436': true,
  '3538437': true,
  '3538438': true,
  '3538439': true,
  '3538440': true,
  '3538441': true,
  '3538442': true,
  '3538443': true,
  '3538444': true,
  '3538445': true,
  '3538446': true,
  '3538447': true,
  '3538448': true,
  '3538449': true,
  '3538450': true,
  '3538451': true,
  '3538452': true,
  '3538453': true,
  '3538454': true,
  '3538455': true,
  '3538456': true,
  '3538457': true,
  '3538458': true,
  '3538459': true,
  '3538460': true,
  '3538461': true,
  '3538707': true,
  '3538708': true,
  '3538709': true,
  '3539417': true,
  '3539418': true,
  '3539419': true,
  '3539420': true,
  '3539421': true,
  '3539422': true,
  '3540465': true,
  '3540466': true,
  '3540467': true,
  '3540468': true,
  '3540469': true,
  '3540470': true,
  '3540471': true,
  '3540472': true,
  '3540473': true,
  '3540474': true,
  '3540475': true,
  '3540476': true,
  '3540477': true,
  '3540478': true,
  '3540479': true,
  '3540480': true,
  '3540481': true,
  '3540482': true,
  '3540483': true,
  '3540484': true,
  '3540485': true,
  '3540486': true,
  '3540487': true,
  '3540488': true,
  '3540489': true,
  '3540490': true,
  '3540491': true,
  '3540492': true,
  '3540493': true,
  '3540494': true,
  '3540495': true,
  '3540496': true,
  '3540497': true,
  '3540498': true,
  '3540499': true,
  '3540500': true,
  '3540561': true,
  '3540562': true,
  '3540563': true,
  '3540564': true,
  '3540565': true,
  '3540566': true,
  '3542511': true,
  '3542512': true,
  '3542513': true,
  '3543363': true,
  '3543364': true,
  '3543365': true,
  '3543366': true,
  '3543367': true,
  '3543368': true,
  '3543369': true,
  '3543370': true,
  '3543371': true,
  '3543372': true,
  '3543373': true,
  '3543374': true,
  '3543375': true,
  '3543376': true,
  '3543377': true,
  '3543378': true,
  '3543379': true,
  '3543380': true,
  '3543614': true,
  '3543615': true,
  '3543616': true,
  '3543617': true,
  '3543618': true,
  '3543619': true,
  '3543620': true,
  '3543621': true,
  '3543622': true,
  '3543623': true,
  '3543624': true,
  '3543625': true,
  '3543626': true,
  '3543627': true,
  '3543628': true,
  '3543629': true,
  '3543630': true,
  '3543631': true,
  '3545506': true,
  '3545507': true,
  '3545508': true,
  '3546136': true,
  '3546137': true,
  '3546138': true,
  '3546258': true,
  '3546259': true,
  '3546260': true,
  '3546261': true,
  '3546262': true,
  '3546263': true,
  '3546264': true,
  '3546265': true,
  '3546266': true,
  '3546267': true,
  '3546268': true,
  '3546269': true,
  '3546270': true,
  '3546271': true,
  '3546272': true,
  '3546273': true,
  '3546274': true,
  '3546275': true,
  '3546586': true,
  '3546587': true,
  '3546588': true,
  '3546876': true,
  '3546877': true,
  '3546878': true,
  '3546879': true,
  '3546880': true,
  '3546881': true,
  '3546882': true,
  '3546883': true,
  '3546884': true,
  '3546885': true,
  '3546886': true,
  '3546887': true,
  '3546888': true,
  '3546889': true,
  '3546890': true,
  '3546891': true,
  '3546892': true,
  '3546893': true,
  '3547639': true,
  '3547640': true,
  '3547641': true,
  '3548827': true,
  '3548828': true,
  '3548829': true,
  '3548836': true,
  '3548837': true,
  '3548838': true,
  '3548839': true,
  '3548840': true,
  '3548841': true,
  '3548842': true,
  '3548843': true,
  '3548844': true,
  '3548845': true,
  '3548846': true,
  '3548847': true,
  '3548848': true,
  '3548849': true,
  '3548850': true,
  '3548887': true,
  '3548888': true,
  '3548889': true,
  '3548890': true,
  '3548891': true,
  '3548892': true,
  '3548893': true,
  '3548894': true,
  '3548895': true,
  '3548896': true,
  '3548897': true,
  '3548898': true,
  '3548899': true,
  '3548900': true,
  '3548901': true,
  '3548902': true,
  '3548903': true,
  '3548904': true,
  '3549181': true,
  '3549182': true,
  '3549183': true,
  '3549184': true,
  '3549185': true,
  '3549186': true,
  '3549187': true,
  '3549188': true,
  '3549189': true,
  '3549190': true,
  '3549191': true,
  '3549192': true,
  '3549193': true,
  '3549194': true,
  '3549195': true,
  '3549196': true,
  '3549197': true,
  '3549198': true,
  '3550000': true,
  '3550001': true,
  '3550002': true,
  '3552541': true,
  '3552542': true,
  '3552543': true,
  '3552633': true,
  '3552634': true,
  '3552635': true,
  '3552638': true,
  '3552639': true,
  '3552640': true,
  '3555455': true,
  '3555456': true,
  '3555457': true,
  '3555607': true,
  '3555608': true,
  '3555609': true,
  '3555610': true,
  '3555611': true,
  '3555612': true,
  '3555613': true,
  '3555614': true,
  '3555615': true,
  '3555616': true,
  '3555617': true,
  '3555618': true,
  '3555619': true,
  '3555620': true,
  '3555621': true,
  '3555622': true,
  '3555623': true,
  '3555624': true,
  '3555743': true,
  '3555744': true,
  '3555745': true,
  '3555746': true,
  '3555747': true,
  '3555748': true,
  '3555749': true,
  '3555750': true,
  '3555751': true,
  '3555752': true,
  '3555753': true,
  '3555754': true,
  '3555755': true,
  '3555756': true,
  '3555757': true,
  '3555758': true,
  '3555759': true,
  '3555760': true,
  '3555761': true,
  '3555762': true,
  '3555763': true,
  '3555764': true,
  '3555765': true,
  '3555766': true,
  '3555767': true,
  '3555768': true,
  '3555769': true,
  '3555770': true,
  '3555771': true,
  '3555772': true,
  '3555773': true,
  '3555774': true,
  '3555775': true,
  '3555776': true,
  '3555777': true,
  '3555778': true,
  '3556413': true,
  '3556414': true,
  '3556415': true,
  '3556425': true,
  '3556426': true,
  '3556427': true,
  '3556428': true,
  '3556429': true,
  '3556430': true,
  '3556443': true,
  '3556444': true,
  '3556445': true,
  '3556501': true,
  '3556502': true,
  '3556503': true,
  '3556791': true,
  '3556792': true,
  '3556793': true,
  '3564304': true,
  '3564305': true,
  '3564306': true,
  '3565417': true,
  '3565418': true,
  '3565419': true,
  '3565420': true,
  '3565421': true,
  '3565422': true,
  '3565423': true,
  '3565424': true,
  '3565425': true,
  '3565621': true,
  '3565622': true,
  '3565623': true,
  '3567070': true,
  '3567071': true,
  '3567072': true,
  '3569010': true,
  '3569011': true,
  '3569012': true,
  '3569948': true,
  '3569949': true,
  '3569950': true,
  '3569954': true,
  '3569955': true,
  '3569956': true,
  '3570001': true,
  '3570002': true,
  '3570003': true,
  '3571767': true,
  '3571768': true,
  '3571769': true,
  '3571770': true,
  '3575352': true,
  '3575353': true,
  '3575354': true,
  '3575359': true,
  '3575360': true,
  '3575361': true,
  '3575375': true,
  '3575376': true,
  '3575377': true,
  '3575478': true,
  '3575479': true,
  '3575480': true,
  '3575481': true,
  '3575482': true,
  '3575483': true,
  '3575568': true,
  '3575569': true,
  '3575570': true,
  '3575572': true,
  '3575573': true,
  '3575574': true,
  '3575575': true,
  '3575576': true,
  '3575577': true,
  '3575578': true,
  '3575579': true,
  '3575580': true,
  '3575597': true,
  '3575598': true,
  '3575599': true,
  '3576245': true,
  '3576246': true,
  '3576247': true,
  '3576248': true,
  '3576249': true,
  '3576250': true,
  '3576251': true,
  '3576252': true,
  '3576253': true,
  '3576270': true,
  '3576271': true,
  '3576272': true,
  '3576273': true,
  '3576274': true,
  '3576275': true,
  '3576276': true,
  '3576277': true,
  '3576278': true,
  '3576279': true,
  '3576280': true,
  '3576281': true,
  '3576282': true,
  '3576283': true,
  '3576284': true,
  '3576540': true,
  '3576541': true,
  '3576542': true,
  '3576686': true,
  '3576687': true,
  '3576688': true,
  '3577059': true,
  '3577060': true,
  '3577061': true,
};
export const nonBrandPaidSearch = {
  '3575528': true,
  '3575504': true,
  '3575516': true,
  '3575501': true,
};
