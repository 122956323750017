/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Verified = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--verified"
      {...props}
    >
      <path
        d="M19.8346 9.66732L21.8346 11.6673L11.8346 21.6673L6.83463 16.6673L8.83463 14.6673L11.8346 17.6673L19.8346 9.66732Z"
        fill="#13892E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8346 3.66732L24.1255 3.93823C21.5559 4.19519 18.9719 3.61635 16.7575 2.2877L13.5013 0.333984L10.2451 2.2877C8.03069 3.61635 5.44673 4.19519 2.87711 3.93823L0.167969 3.66732V14.7848C0.167969 19.4683 2.62529 23.8084 6.64136 26.218L13.5013 30.334L20.3612 26.218C24.3773 23.8084 26.8346 19.4683 26.8346 14.7848V3.66732ZM24.168 6.61224C21.1011 6.87175 18.028 6.15985 15.3855 4.57435L13.5013 3.44383L11.6171 4.57435C8.9746 6.15985 5.90155 6.87175 2.83464 6.61224V14.7848C2.83464 18.5316 4.80049 22.0037 8.01335 23.9314L13.5013 27.2241L18.9893 23.9314C22.2021 22.0037 24.168 18.5316 24.168 14.7848V6.61224Z"
        fill="#13892E"
      />
    </svg>
  );
};

Verified.displayName = 'Verified';

export default Verified;
