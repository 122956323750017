/* eslint-disable */
/**
 *  Bing Tracking service
 */

export const pageView = (e) => {
  const { page = '' } = e.detail || {};
  if (window && window.uetq && page !== '') {
    window.uetq.push('event', 'page_view', { page_path: `/${page}` });
  }
};
