import Cookies from 'js-cookie';

import { AFFILIATE_IDS, CHANNEL_IDS, USER_IDS } from '~/constants/config/common';
import { nonBrandPaidSearch, nonBrandSEO, brandPaidSearch } from '~/constants/mcids';
import clientSide from './clientSide';
import { getSite, getSiteData } from './site';

interface FtrAttribution {
  affiliateId: string;
  channelId: string;
  userId: string;
}

const mapFtrAttribtionFtr = (): FtrAttribution => {
  if (!clientSide) return { affiliateId: '', userId: '', channelId: '' };

  let mapKey = '';
  let userid = '';
  const { isFRIN, isINFR, isFRDC, isAC } = getSite();
  const mcidCookie = Cookies.get('marketing');
  const mcidCookieData = mcidCookie ? JSON.parse(mcidCookie) : {};
  const mcid = mcidCookieData.mcid ? mcidCookieData.mcid : getSiteData('mcid');
  const { referrer } = document;
  const isCopper2Fiber = window.location?.pathname?.includes('fiber') ?? false;
  const isAmexOffer = window.location?.href?.includes('amex') ?? false;

  // check if MCID in non brand seo or paid search
  const nonBrandPaidSearchMcid = nonBrandPaidSearch[mcid] || false;
  const seoMcid = nonBrandSEO[mcid] || false;
  const brandPaidSearchMcid = brandPaidSearch[mcid] || false;

  //  INFR /d/ or /m/ OR FRIN /ps/
  const paidSearchCatchAll = Boolean(
    referrer?.includes('/d/') || referrer?.includes('/m/') || referrer?.includes('/ps/')
  );

  // TODO: Remove mapKey, channel, and affiliate when 100% Frapi
  if (isCopper2Fiber && isFRDC) {
    mapKey = 'rvfrdcc2fcart';
    userid = USER_IDS.FRDC;
  } else if (isAmexOffer) {
    mapKey = 'rvfrdcamexcart';
    userid = USER_IDS.AMEX;
  } else if (isAC) {
    mapKey = 'rvrprop';
    userid = USER_IDS.AC;
  } else if (isFRDC && !isAmexOffer) {
    mapKey = 'rvfrdccart';
    userid = USER_IDS.FRDC;
  } else if (isFRIN || isINFR) {
    // TODO Remove Everything Below in Mid-October to Refactor
    mapKey = 'rvpdmdcart';
    userid = USER_IDS.FRIN;
  } else if (mcid === '3577153' || mcid === '3577489') {
    // 3577153 - ALLCONNECT (entityID 38)
    // 3577489 - ALLCONNECT (entityID 38)
    mapKey = 'rvrvprop';
    userid = USER_IDS.AC;
  } else if (nonBrandPaidSearchMcid || brandPaidSearchMcid) {
    // nonBrandPaidSearchMcid - entityID 42
    // brandPaidSearchMcid - entityID 37
    mapKey = 'rvpdmdcart';
    userid = USER_IDS.FRIN;
  } else if (seoMcid) {
    mapKey = 'rvseocart'; // entityID 41
    userid = USER_IDS.INFR;
  } else if (paidSearchCatchAll) {
    mapKey = 'rvpdmdcart'; // entityID 37
    userid = USER_IDS.FRIN;
  }

  return {
    affiliateId: AFFILIATE_IDS[mapKey] || getSiteData('affiliateId'),
    userId: userid,
    channelId: CHANNEL_IDS[mapKey] || getSiteData('channelId'),
  };
};

const mapFtrAttribtionAc = (): FtrAttribution => {
  return {
    affiliateId: AFFILIATE_IDS.rvrvprop,
    userId: USER_IDS.AC,
    channelId: CHANNEL_IDS.rvrvprop,
  };
};
export default process.env.allconnect === 'true' ? mapFtrAttribtionAc : mapFtrAttribtionFtr;
