// Sanitize the request and the response
export const sanitizeNetworkData = (sensitiveFields) => (data) => {
  // Fields that need to be sanitized
  const sanitizeObject = (obj) => {
      const sanitizedObj = { ...obj };

      for (const key in sanitizedObj) {
          sensitiveFields.forEach((field) => {
              if (sanitizedObj[field]) {
                  sanitizedObj[key] = '***';
              }
          });
      }

      return sanitizedObj;
  };

  if (data.body) {
      try {
          const body = JSON.parse(data.body);
        
          // Loop through each object in the data
          for (const key in body) {
              sensitiveFields.forEach((field) => {
                  if (body[key][field]) {
                      body[key] = sanitizeObject(body[key]);
                  }
              });
          }

          data.body = JSON.stringify(body);
      } catch {}
  }

  return data;
};