import React, { FC, useContext, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { Provider as ReduxProvider } from 'react-redux';
import { NextRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from '../styles';
import { Store } from 'redux';
import { setPageLoading } from '~/redux/actions/common';
import { trackEvent } from '~/helpers/tracking';
import { EventData } from '~/types/events';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { setNotifications } from '~/redux/actions/global';
import { setMonarchDecision } from '~/redux/actions/common';
import { DEFAULT_API_VERSION } from '~/constants/config/common';
import { CohesionContext, CohesionContextValues } from '@red-digital/bricks';

const Debugger = dynamic(() => import('../components/Debugger'), { ssr: false });

interface FuelContextProps {
  router: NextRouter;
  production: boolean;
  trackEvent: ({ action, data, event }: EventData) => void;
}

interface FuelProviderProps {
  children: JSX.Element[] | JSX.Element;
  store: Store;
  production: boolean;
  router: NextRouter;
}

export const FuelContext = React.createContext<FuelContextProps | null>(null);

const FuelProvider: FC<FuelProviderProps> = ({ children, store, router, production }) => {
  const { monarchResponse } = useContext<CohesionContextValues>(CohesionContext);

  // Remove the loader when the route changes.
  if (router?.events?.on) {
    router.events.on('routeChangeComplete', (url, options) => {
      const shallow = options?.shallow ?? false;

      if (url.includes('/confirmation')) {
        trackEvent({
          action: 'elementViewed',
          data: {
            elementType: 'PAGE TRANSITION',
            location: '/confirmation',
            position: 'TRANSITION START',
            text: 'tracking?.redirectUrl',
          },
        });
      }
      if (!shallow && !url.includes('/confirmation')) {
        store.dispatch(setNotifications());
        store.dispatch(setPageLoading(false));
      }
    });
  }

  useEffect(() => {
    // Set monarch decision to redux state
    if (typeof monarchResponse === 'object') {
      const rules = Object.entries(monarchResponse || {});
      const valueMap = { apiVersion: DEFAULT_API_VERSION };

      if (rules.length) {
        rules.forEach(([key, returnValue]) => (valueMap[key] = returnValue));
        store.dispatch(setMonarchDecision(valueMap));
      }
    }
  }, [monarchResponse]);

  return (
    <ReduxProvider store={store}>
      <GoogleReCaptchaProvider useEnterprise reCaptchaKey={process.env.recaptchaSiteKey}>
        <FuelContext.Provider
          value={{
            production,
            router,
            trackEvent,
          }}
        >
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {children}
            {!production && <Debugger />}
          </ThemeProvider>
        </FuelContext.Provider>
      </GoogleReCaptchaProvider>
    </ReduxProvider>
  );
};

FuelProvider.displayName = 'FuelProvider';

export default FuelProvider;
