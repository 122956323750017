/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Award = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      focusable="false"
      data-icon="award"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-award"
      width="24"
      height="34"
      {...props}
    >
      <g>
        <path
          d="M17.295 23.252l1.517-2.462 2.738-.934.304-2.876 2.06-2.028-.98-2.722.98-2.722-2.06-2.027-.304-2.876-2.737-.934-1.518-2.462-2.87.347L11.989 0 9.55 1.556l-2.87-.347-1.516 2.462-2.738.934-.304 2.876-2.06 2.027.98 2.723-.98 2.722 2.06 2.027.304 2.876 2.737.934 1.518 2.462 2.87-.347 2.437 1.556 2.438-1.556 2.87.347zM3.601 12.23c0-4.625 3.762-8.387 8.387-8.387s8.387 3.762 8.387 8.387c0 4.624-3.762 8.387-8.387 8.387s-8.387-3.763-8.387-8.387z"
          fill="#E14643"
        />
        <path
          d="M11.988 5.789a6.45 6.45 0 00-6.442 6.442 6.45 6.45 0 006.442 6.441 6.45 6.45 0 006.442-6.441 6.45 6.45 0 00-6.442-6.442zM9.091 24.92l-3.413.412-1.804-2.927-.613-.209-2.645 8.267 4.761-.262 3.726 2.977 2.192-6.852zM20.102 22.405l-1.804 2.927-3.413-.412-2.204 1.406 2.192 6.852L18.6 30.2l4.76.262-2.644-8.267z"
          fill="#17355E"
        />
      </g>
    </svg>
  );
};

Award.displayName = 'Award';

export default Award;
