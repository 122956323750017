import { ActionTypes, ActiveLegalAction, LegalAction, LegalMap, ResetLegalAction } from '~/types/legal';

export const setLegal = (legalMap: LegalMap): LegalAction => {
  return { type: ActionTypes.SET_LEGAL_MAP, legalMap };
};

export const addActiveLegal = (activeLegal: string[]): ActiveLegalAction => {
  return { type: ActionTypes.ADD_ACTIVE_LEGAL, activeLegal };
};

export const resetActiveLegal = (): ResetLegalAction => {
  return { type: ActionTypes.RESET_ACTIVE_LEGAL };
};
