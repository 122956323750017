/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const DiagonalRIght = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--DiagonalRIght"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8599 8.13025L7.71399 26.2761L5.82837 24.3905L23.9742 6.24463L25.8599 8.13025Z"
        fill="#141928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1662 7.99999L13.4377 7.99999L13.4377 5.33331L26.7711 5.33331L26.7515 18.6666L24.0848 18.6666L24.1662 7.99999Z"
        fill="#141928"
      />
    </svg>
  );
};

DiagonalRIght.displayName = 'DiagonalRIght';

export default DiagonalRIght;
