const slugify = (text = '', spaceReplace = '-'): string => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, spaceReplace)
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/-+/g, '-');
};

export default slugify;
