/* eslint-disable */
/**
 * Jivox Tracking service
 */

const _appendScriptTag = (src) => {
  // check if jivox and amazon mcid
  // if so append mcid to script url as flag to jivox that this is amazon traffic
  if (src.includes('pxl.jivox.com') && String(window.mcid) === '3576375') {
    src += '&mcid=3576375';
  }
  const $script = document.createElement('script');
  $script.async = true;
  $script.src = src;
  document.getElementsByTagName('body')[0].appendChild($script);
};

export const cartEntry = (event) => {
  const { offerCode = 'N/A' } = event.detail || {};
  _appendScriptTag(`https://pxl.jivox.com/tags/conv/pxjs.php?px=45c8ff9f597a6b&cOpt=${offerCode}&rev=0`);
  _appendScriptTag(`https://pxl.jivox.com/tags/re/pxjs.php?px=85d117124c8a48&cData=Cart_Entry_Page`);
};

export const serviceabilityRan = (event) => {
  _appendScriptTag('https://pxl.jivox.com/tags/re/pxjs.php?px=15d1170e7d8605&cData=Serviceability_Page');
};

export const packageSelected = (event) => {
  const offerId = event.detail && event.detail.products && event.detail.products[0];
  _appendScriptTag(`https://pxl.jivox.com/tags/re/pxjs.php?px=95d117142f41a5&cData=${offerId}`);
};

// All of these are the same standard parameters we see in our floodlights as well.
export const orderConfirmation = (event) => {
  const src = `https://pxl.jivox.com/tags/conv/pxjs.php?px=95b202d8f1d1fd&cOpt=${event.detail.quantity}|${event.detail.productInfo}&rev=${event.detail.monthlyRecurring}`;
  _appendScriptTag(src);
};
