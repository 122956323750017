import merge from 'lodash/merge';
import local from './environments/local';
import development from './environments/development';
import production from './environments/production';
import { AFFILIATE_IDS, CHANNEL_IDS, MONARCH_SOURCE_ID, MONARCH_TOKEN, USER_IDS } from './common';

export const commonConfigs = {
  frdc: {
    tenantId: '2646f3b4-2a12-48ac-979d-ad332d8e53a6',
    apiKey: '2646f3b4-2a12-48ac-979d-ad332d8e53a6.be7259f3-a108-4532-b2d6-cabba9185e07',
    fuseLease: 'YMRmKnyGqunDD2ZVVahkUY',
    siteId: 'koD169ZB2cN6Gww4YYfCLt',
    mcid: '',
    affiliateId: AFFILIATE_IDS.rvfrdccart,
    channelId: CHANNEL_IDS.rvfrdccart,
    userId: USER_IDS.FRDC,
    token: MONARCH_TOKEN,
    sourceId: MONARCH_SOURCE_ID,
  },
  infr: {
    tenantId: '14b35403-1631-4d6b-90e8-d407cc8c939c',
    apiKey: '14b35403-1631-4d6b-90e8-d407cc8c939c.ac505935-7947-44ac-935e-8bd7167385f8',
    fuseLease: 'qEk6PrZcJoSyErMUQX2pLj',
    siteId: 'koD169ZB2cN6Gww4YYfCLt',
    mcid: '',
    affiliateId: AFFILIATE_IDS.rvseocart,
    channelId: CHANNEL_IDS.rvseocart,
    userId: USER_IDS.INFR,
    token: MONARCH_TOKEN,
    sourceId: MONARCH_SOURCE_ID,
  },
  frin: {
    tenantId: 'e1f374e9-08d8-4f42-a744-ddfdaee997dd',
    apiKey: 'e1f374e9-08d8-4f42-a744-ddfdaee997dd.7e61b9c0-1aaa-4e95-b76e-7d26a4eb9df2',
    fuseLease: 'qEk6PrZcJoSyErMUQX2pLj',
    siteId: 'koD169ZB2cN6Gww4YYfCLt',
    mcid: '',
    affiliateId: AFFILIATE_IDS.rvpdmdcart,
    userId: USER_IDS.FRIN,
    channelId: CHANNEL_IDS.rvpdmdcart,
    token: MONARCH_TOKEN,
    sourceId: MONARCH_SOURCE_ID,
  },
  ac: {
    tenantId: 'dc0c22a1-ea8a-45ae-ae9a-f63379366a5',
    apiKey: 'dc0c22a1-ea8a-45ae-ae9a-f63379366a5.22844c38-cb41-4423-8dd6-0527e0de19f1',
    fuseLease: 'Vc3PbZJbzFsznbJLadT4Vn',
    siteId: 'koD169ZB2cN6Gww4YYfCLt',
    mcid: '3577153',
    affiliateId: AFFILIATE_IDS.rvrvprop,
    channelId: CHANNEL_IDS.rvrvprop,
    userId: USER_IDS.AC,
    token: MONARCH_TOKEN,
    sourceId: MONARCH_SOURCE_ID,
  },
};

export const environmentMap = {
  local: merge({}, local, commonConfigs),
  development: merge({}, development, commonConfigs),
  production: merge({}, production, commonConfigs),
};

const config = environmentMap[process.env.environment || 'local'];

export default config;
