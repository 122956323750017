import React, { SVGProps } from 'react';

const Location = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-rectangle"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2H8C4.68629 2 2 4.68629 2 8V9.31222C2 10.8474 2.58846 12.3242 3.64429 13.4387L7.67755 17.696L6.30456 19.1548L2.19238 14.8142C0.784618 13.3282 0 11.3591 0 9.31222V8C0 3.58172 3.58172 0 8 0H10C14.4183 0 18 3.58172 18 8V9.31222C18 11.3591 17.2154 13.3282 15.8076 14.8142L10.3775 20.546L9 22L7.67025 20.5964L12.6834 15.2039L14.3557 13.4387C15.4115 12.3242 16 10.8474 16 9.31222V8C16 4.68629 13.3137 2 10 2ZM9.5 5.5C10.8807 5.5 12 6.61929 12 8V9C12 10.3807 10.8807 11.5 9.5 11.5H8.5C7.11929 11.5 6 10.3807 6 9V8C6 6.61929 7.11929 5.5 8.5 5.5H9.5Z"
        fill="#141928"
      />
    </svg>
  );
};

Location.displayName = 'Location';

export default Location;
