const production = {
  api: `https://fuel-ol-prod.azurewebsites.net/api/`,
  newrelic: {
    accountID: '3301494',
    applicationID: '749349944',
    licenseKey: 'NRBR-1e4c10792b45cd928f8',
  },
  frdc: {
    writeKey: 'wk_1NT297N9odoW0CqWvYg7phqu6Jl',
    sourceKey: 'src_1NT295J0sRek2E2S9wmewl1hSjr',
  },
  infr: {
    writeKey: 'wk_1NT298Z1fu0BKyOCPNAXHDkZ9OP',
    sourceKey: 'src_1NT297rret2SKU4MnEHx4eIxk2A',
  },
  frin: {
    writeKey: 'wk_1NT299B6zAqwtypc6ULw3pZ3vyJ',
    sourceKey: 'src_1NT299Hykp1EOgLj96gZgh2RX5P',
  },
  ac: {
    affiliateId: 'RV-RV Proprietary',
    writeKey: 'wk_1NT28Xs9LiZuknWRbR9cXPtucjV',
    sourceKey: 'src_1NT28RdkYcgBjTRFLTgYcWcrH1n',
  },
};

export default production;
