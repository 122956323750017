/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Bill = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--bill"
      {...props}
    >
      <path d="M10.6667 25.3333H21.3333V22.6667H10.6667V25.3333Z" fill="#141928" />
      <path d="M21.3333 20.6667L10.6667 20.6667V18H21.3333V20.6667Z" fill="#141928" />
      <path
        d="M17 7.33333H19.6667V9.33333H13.3333C13.1492 9.33333 13 9.48257 13 9.66666V9.99999C13 10.1841 13.1492 10.3333 13.3333 10.3333H18C19.2887 10.3333 20.3333 11.378 20.3333 12.6667V13C20.3333 14.2887 19.2887 15.3333 18 15.3333H17V16.6667H15V15.3333H11.6667V13.3333H18C18.1841 13.3333 18.3333 13.1841 18.3333 13V12.6667C18.3333 12.4826 18.1841 12.3333 18 12.3333H13.3333C12.0447 12.3333 11 11.2887 11 9.99999V9.66666C11 8.378 12.0447 7.33333 13.3333 7.33333H15V5.99999H17V7.33333Z"
        fill="#141928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.33333C8.31811 1.33333 5.33334 4.3181 5.33334 7.99999V24C5.33334 27.6819 8.31811 30.6667 12 30.6667H20C23.6819 30.6667 26.6667 27.6819 26.6667 24V7.99999C26.6667 4.3181 23.6819 1.33333 20 1.33333H12ZM8.00001 7.99999C8.00001 5.79086 9.79087 3.99999 12 3.99999H20C22.2091 3.99999 24 5.79086 24 7.99999V24C24 26.2091 22.2091 28 20 28H12C9.79087 28 8.00001 26.2091 8.00001 24V7.99999Z"
        fill="#141928"
      />
    </svg>
  );
};

Bill.displayName = 'Bill';

export default Bill;
