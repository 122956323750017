import React, { FC, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { FooterStyled } from './FooterStyled';
import FooterLinks from './FooterLinks';
import PhoneNumber from '../PhoneNumber';
import { PAGE_ROUTES } from '../../constants/pages';
import { DEFAULT_HOURS } from '~/constants/config/common';
import { Container } from '../Layout/LayoutStyled';
import { H2 } from '../Typography';
import { useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';
import { FuelContext } from '~/providers/FuelProvider';
import setHtml from '~/helpers/setHtml';
import { trackEvent } from '~/helpers/tracking';
import { logError } from '@red-digital/bricks';

const Footer: FC = () => {
  const { router } = useContext(FuelContext);
  const { pathname } = router;
  const { legal, callInNumber, monarch, cookiePass } = useSelector((store: ReduxState) => store);
  const { cartGlobalHoursOfOperation, cartShowDuringOfficeHours } = monarch;

  const hidePhone = 
  monarch?.cartHideOnServiceability ||
  monarch?.cartHideOnProductSelection ||
  monarch?.cartHideOnContactCredit ||
  monarch?.cartHideAfterContactCredit;

  const hoursOfOperation = (() => {
    if (Array.isArray(cartGlobalHoursOfOperation)) return cartGlobalHoursOfOperation;
    return DEFAULT_HOURS;
  })();

  const { activeLegal = [], legalMap = {} } = legal || {};

  const currentYear = new Date().getFullYear();

  const handleCallButton = () => {
    const currentPage = pathname === '/' ? 'address-check' : router.route.slice(1);
    trackEvent({
      action: 'elementClicked',
      data: {
        elementType: 'BUTTON',
        location: currentPage,
        position: 'footer',
        text: 'call button',
      },
    });
  };

  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    if (!cookiePass) setShowFooter(true);

    // Reveal footer after 2 seconds as a fallback
    setTimeout(() => setShowFooter(true), 2000);
  }, [cookiePass]);

  return (
    <FooterStyled data-testid="footer-component" showFooter={showFooter}>
      {cartShowDuringOfficeHours && !hidePhone && (
        <Container className="footer__content">
          <div className="footer__need-help">
                <H2>Need help?</H2>
                <p id="phone-label">Give us a call and our specialists can help&nbsp;you</p>
                <div className="footer__phone">
                  <PhoneNumber number={callInNumber} aria-labelledby="phone-label" onClick={handleCallButton} />
                </div>
          </div>
          <div className="footer__operations">
            <H2>Hours of Operation</H2>
            <ul>
              {hoursOfOperation.map((hours, index) => (
                <li data-testid={`hours-of-operation-${index}`} key={index} {...setHtml(hours)} />
                ))}
            </ul>
          </div>
        </Container>
      )}

      {[PAGE_ROUTES.PLAN_PACKAGE, PAGE_ROUTES.PLAN_FIBER, PAGE_ROUTES.PLAN_RESUME].includes(router.pathname) && (
        <Container className="footer__legal">
          <ol className="footer__legal-text">
            {activeLegal.map((legalKey, number) => {
              const text = legalMap[legalKey]?.text ?? '';
              if (!legalMap[legalKey]) {
                logError(`Legal Error: No legal text found for ${legalKey}`);
                console.error(`No legal found for ${legalKey}`);
                return;
              }
              return <li key={`legal-${legalKey}`} data-testid={`legal-${legalKey}`} {...setHtml(text)} data-annotation={number + 1} />;
            })}
          </ol>
        </Container>
      )}

      <Container className="footer__copyright-wrapper">
        <p className="footer__copyright">
          &copy;{currentYear} Frontier Communications Parent,&nbsp;Inc. All&nbsp;rights&nbsp;reserved.
        </p>
      </Container>
      <FooterLinks pageRoute={router.pathname} />
    </FooterStyled>
  );
};

Footer.displayName = 'Footer';

Footer.propTypes = {
  router: PropTypes.object,
};

export default Footer;
