import styled, { css, keyframes } from 'styled-components';

const animateWidth = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100px;
  }
`;

export const ProgressStyled = styled.span.attrs({
  className: 'progress',
})`
  ${({ theme }) => {
    const { colors } = theme;

    return css`
      display: none;

      ${theme.media.tablet.up} {
        align-items: center;
        color: ${colors.primary.darkBlue};
        display: flex;
        justify-content: space-between;
        margin-right: 30px;
        position: relative;
        padding-bottom: 20px;
        width: 285px;
        z-index: 1;
      }

      .step-name {
        color: ${colors.secondary.darkBlue};
        font-weight: 700;

        ${theme.media.tablet.up} {
          position: absolute;
          top: 40px;
          white-space: nowrap;
        }
      }

      .step-line {
        background: ${colors.primary.darkBlue};
        height: 2px;
        position: absolute;
        width: 96px;

        &:first {
          display: none;
        }
      }

      .step-number {
        color: ${colors.primary.darkBlue};
        font-weight: 700;
        font-size: 18px;
      }

      .step-check {
        display: none;
      }

      div {
        align-items: center;
        background-color: #fff;
        border: 2px solid ${colors.primary.darkBlue};
        border-radius: 50%;
        display: flex;
        font-size: 700;
        height: 32px;
        justify-content: center;
        position: relative;
        transition: background-color 2s;
        width: 32px;

        ${theme.media.tablet.up} {
          font-size: 12px;
          color: ${colors.primary.darkBlue};
        }

        &:after {
          background: ${colors.primary.darkBlue};
          content: '';
          display: block;
          left: 100%;
          height: 2px;
          position: absolute;
          width: 100px;
          z-index: -1;
        }

        &:last-child {
          &:after {
            content: none;
          }
        }

        &.is-complete,
        &.is-current {
          border: 2px solid ${colors.primary.red};

          .step-name {
            font-weight: 700;
          }
        }

        &.is-current {
          transition: border-color 2s;
        }

        &.is-complete {
          background-color: ${colors.primary.red};

          &:after {
            animation: ${animateWidth} 0.75s linear 1;
            background-color: ${colors.primary.red};
          }

          .step-number {
            color: ${colors.primary.red};
            display: none;
          }

          .step-check {
            color: ${colors.primary.white};
            display: block;
            width: 12px;
          }
        }

        &.is-current {
          .step-name,
          .step-number {
            color: ${colors.primary.red};
            transition: color 2s;
          }
        }
      }
    `;
  }};
`;
