import React, { useContext, useEffect, useState, FC } from 'react';
import getAsset from '~/helpers/getAsset';
import Progress from '../Progress';
import { HeaderStyled } from './HeaderStyled';
import { Container } from '../Layout/LayoutStyled';
import { FuelContext } from '~/providers/FuelProvider';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';
import { getSite } from '../../helpers/site';
import ExitButton from '../Quiz/components/ExitButton';
import { ArrowRight } from '../Icons/fill';
import ProgressBar from '../SingleFormCheckout/ProgressBar';
import { setSingleForm } from '~/redux/actions/common';
import clientSide from '~/helpers/clientSide';
import { PAGE_ROUTES } from '~/constants/pages';
import { trackEvent } from '~/helpers/tracking';
import { CohesionContext } from '@red-digital/bricks';
import { FrontierLogo } from '../Icons/outline';

const Header: FC = () => {
  const [baseUrl, setBaseUrl] = useState('/');
  const { router } = useContext(FuelContext);
  const { tracking, cookiePass, singleForm, pageLoading } = useSelector((store: ReduxState) => store);
  const { preampDecisions } = useContext(CohesionContext);
  const [showHeader, setShowHeader] = useState(false);
  const [isRedHeader, setIsRedHeader] = useState(false);
  const dispatch = useDispatch();
  const { isFRIN } = getSite();

  const review = clientSide && router.pathname === PAGE_ROUTES.CHECKOUT && window.location.hash === '#review';
  const checkout = clientSide && router.pathname === PAGE_ROUTES.CHECKOUT && window.location.hash !== '#review';
  const unserviceableAllconnect = clientSide && router.pathname === PAGE_ROUTES.UNSERVICEABLE_ALLCONNECT;

  const handleSingleFormBack = () => {
    if (review) {
      dispatch(setSingleForm('progress', 65));
      router.push(PAGE_ROUTES.CHECKOUT);
      trackEvent({
        action: 'elementClicked',
        data: {
          elementType: 'Arrow Button',
          position: 'back_button',
          location: 'review_page',
          text: 'Back',
        },
      });
    }
    if (checkout) {
      dispatch(setSingleForm('progress', 65));
      dispatch(setSingleForm('contactCredit', 'inactive'));
      dispatch(setSingleForm('installation', 'inactive'));
      dispatch(setSingleForm('payment', 'inactive'));
      dispatch(setSingleForm('dob', ''));
      trackEvent({
        action: 'elementClicked',
        data: {
          elementType: 'Arrow Button',
          position: 'back_button',
          location: 'checkout_page',
          text: 'Back',
        },
      });
      router.push(PAGE_ROUTES.PLAN_PACKAGE);
    }
  };
  const [quoteString, setQuoteString] = useState('');

  useEffect(() => {
    /*
     * FRIN's homepage is /ps/
     * INFR and FRDC's homepage is /
     */

    if (isFRIN) {
      setBaseUrl('/ps/');
    }
  }, []);

  // We have an unserviceable-allconnect redesign test where the header needs to be red.
  // Once this wins/loses, we need to remove this logic.
  useEffect(() => {
    if (preampDecisions) {
      const hasAllconnectRedesignAsset = Object.entries(preampDecisions).some(
        ([, asset]) => (asset as Record<string, string>)?.assetName.includes('allconnect-unserv-redesign')
      );
      const shouldShowRedHeader = hasAllconnectRedesignAsset && unserviceableAllconnect;
      if (isRedHeader !== shouldShowRedHeader) {
        setIsRedHeader(shouldShowRedHeader);
      }
    }
  }, [preampDecisions, unserviceableAllconnect, router.route]);

  useEffect(() => {
    if (!cookiePass) setShowHeader(true);
    // Reveal header after 2 seconds as a fallback
    setTimeout(() => setShowHeader(true), 2000);
  }, [cookiePass]);

  useEffect(() => {
    setQuoteString(`Quote Number ${tracking?.quoteNumber}`);
  }, [router.route, tracking?.quoteNumber]);

  // Note: for the Product Modularization Redesign (PMR) project, if the PMR page asset is loaded
  // there is logic in the components/PmrRedesign/index.tsx file to set display to 'none' for the default header when PMR is active.
  // we tried first putting that logic here, but the styles ended up fighting
  return (
    <HeaderStyled hidden showHeader={showHeader} isRedHeader={isRedHeader}>
      <Container>
        {singleForm?.enabled === true && (router.route.includes('checkout') || router.route.includes('confirmation')) && (
          <>
            {!pageLoading && !router.route.includes('confirmation') && (
              <ArrowRight onClick={handleSingleFormBack} className="checkout__back" />
            )}
            <span className="checkout__container">
              <img
                className="checkout__frontier-icon"
                src={getAsset('/images/logos/frontier-logo.svg')}
                alt="Frontier"
                width="36"
                height="36"
              />
              Checkout
            </span>
          </>
        )}
        {(singleForm?.enabled !== true || router.route.includes('plan-package') ||  router.route === '/' || router.route.includes('liw')) && (
          <a href={baseUrl} aria-label="Home">
            <FrontierLogo width="36" height="36" />
          </a>
        )}
        {router.route.includes('my-custom-quote') && <ExitButton />}
        {router.route.includes('plan-package') && !router.route.includes('my-custom-quote') && (
          <p className="quoteNumber">{quoteString}</p>
        )}
        <Progress route={router.route} />
      </Container>
      {router.route.includes('checkout') && !pageLoading && <ProgressBar percentage={singleForm?.progress} />}
    </HeaderStyled>
  );
};

Header.displayName = 'Header';

export default Header;
