/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Wifi = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="17px"
      height="15px"
      viewBox="0 0 17 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-wifi"
      {...props}
    >
      <path
        d="M.5 2.829A9.89 9.89 0 0 1 7.082.333h2.836A9.89 9.89 0 0 1 16.5 2.83l-.822 1.168a8.478 8.478 0 0 0-5.76-2.246H7.082a8.478 8.478 0 0 0-5.76 2.246L.5 2.83ZM4.843 9l-.857-1.218a4.953 4.953 0 0 1 3.805-1.776H9.21c1.527 0 2.894.69 3.805 1.776L12.158 9A3.542 3.542 0 0 0 9.21 7.424H7.79c-1.229 0-2.312.625-2.948 1.576Z"
        fill="#141928"
      />
      <path
        d="M9.918 4.588c1.615 0 3.073.675 4.106 1.759l.829-1.178a7.067 7.067 0 0 0-4.935-2H7.082a7.067 7.067 0 0 0-4.936 2l.83 1.178a5.656 5.656 0 0 1 4.106-1.76h2.836Z"
        fill="#141928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.019 11.324c0-1.175.952-2.127 2.127-2.127h.709c1.175 0 2.127.952 2.127 2.127v.709a2.127 2.127 0 0 1-2.127 2.127h-.71a2.127 2.127 0 0 1-2.126-2.127v-.71Zm1.418 0a.71.71 0 0 1 .709-.71h.709a.71.71 0 0 1 .709.71v.709a.709.709 0 0 1-.71.709h-.708a.709.709 0 0 1-.71-.71v-.708Z"
        fill="#141928"
      />
    </svg>
  );
};

Wifi.displayName = 'Wifi';

export default Wifi;
