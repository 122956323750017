export const calculateDays = (date: string): number => {
    if (!date) return null;

    const oneDay = 24 * 60 * 60 * 1000;
    const todaysDate = +new Date();
    const futureDate = +new Date(date);
    return (
        Math.ceil(Math.abs(todaysDate - futureDate) / oneDay)
    );
};
