/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Wrench = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--wrench"
      {...props}
    >
      <path
        d="M9.6292 6.25245V8.80621L11.1292 10.36L13.7367 10.36L19.0752 5.0215C19.4003 5.68001 19.6471 6.38402 19.8037 7.12141C19.9324 7.72731 20.0001 8.35575 20.0001 9C20.0001 13.9706 15.9706 18 11.0001 18C10.3083 18 9.6348 17.922 8.98783 17.7742L7.16324 19.5988L5.74902 18.1846L8.35535 15.5782L9.43314 15.8244C9.93503 15.939 10.4592 16 11.0001 16C14.8661 16 18.0001 12.866 18.0001 9C18.0001 8.97511 17.9999 8.95026 17.9997 8.92544L14.5652 12.36L10.2801 12.36L7.6292 9.61409V5.42402L11.053 2.0002C11.0354 2.00007 11.0177 2 11.0001 2C7.13408 2 4.00007 5.13401 4.00007 9C4.00007 9.52928 4.05845 10.0426 4.16835 10.5346L4.408 11.6074L1.78995 14.2255L0.375732 12.8113L2.21646 10.9706C2.0748 10.3364 2.00007 9.67691 2.00007 9C2.00007 4.02944 6.02951 -1.12054e-06 11.0001 0C11.6379 0 12.2603 0.0663602 12.8607 0.192554C13.5992 0.347781 14.3043 0.59354 14.964 0.917683L9.6292 6.25245Z"
        fill="#141928"
      />
    </svg>
  );
};

Wrench.displayName = 'Wrench';

export default Wrench;
