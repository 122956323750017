export type LegalMap = {
  [key: string]: {
    annotation: number;
    text: string;
  };
};

export type Legal = {
  legalMap?: LegalMap;
  activeLegal?: string[];
};

// Redux
export enum ActionTypes {
  SET_LEGAL_MAP = 'legal/map/set',
  ADD_ACTIVE_LEGAL = 'legal/active/add',
  RESET_ACTIVE_LEGAL = 'legal/active/reset',
}

export interface LegalAction {
  type: ActionTypes.SET_LEGAL_MAP;
  legalMap: LegalMap;
}

export interface ActiveLegalAction {
  type: ActionTypes.ADD_ACTIVE_LEGAL;
  activeLegal: string[];
}

export interface ResetLegalAction {
  type: ActionTypes.RESET_ACTIVE_LEGAL;
}

export type Actions = LegalAction | ActiveLegalAction | ResetLegalAction;
