import React, { FC, HtmlHTMLAttributes, useEffect, useState } from 'react';
import PhoneNumberStyled from './PhoneNumberStyled';
import Phone from '../Icons/fill/Phone';
import { Phone as PhoneOutline } from '../Icons/outline';
import { useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  number?: string;
  showIcon?: boolean;
}

const PhoneNumber: FC<Props> = ({ number = '', showIcon, ...props }) => {
  const { singleForm } = useSelector((store: ReduxState) => store);
  const Icon = singleForm?.enabled ? <PhoneOutline /> : <Phone />;

  const [phoneNumber, setPhoneNumber] = useState(number);
  useEffect(() => setPhoneNumber(phoneNumber), [phoneNumber]);

  return (
    <PhoneNumberStyled
      singleForm={singleForm?.enabled}
      href={`tel:${number}`}
      tabIndex={-1}
      data-testid="footer-phone-number"
      {...props}
    >
      {showIcon && Icon}
      {number}
    </PhoneNumberStyled>
  );
};

PhoneNumber.defaultProps = {
  showIcon: true,
};

PhoneNumber.displayName = 'PhoneNumber';

export default PhoneNumber;
