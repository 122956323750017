import { Actions, ActionTypes } from '~/types/products';
import { ReduxState } from '~/types/redux';
import assign from 'lodash/assign';

const products = (state: ReduxState, action: Actions): ReduxState => {
  switch (action.type) {
    case ActionTypes.SET_PRODUCTS:
      return assign({}, state, {
        products: {
          ...state.products,
          available: action.available,
          offerLevelSweeteners: action.offerLevelSweeteners,
        },
      });
    case ActionTypes.SELECT_PRODUCT:
      return assign({}, state, {
        products: {
          ...state.products,
          selected: {
            ...action.selectedState,
          },
        },
      });
    case ActionTypes.CLEAR_SELECT_PRODUCT:
      return assign({}, state, {
        products: {
          ...state.products,
          selected: undefined,
        },
      });
    case ActionTypes.SET_OFFER_TYPE:
      return assign({}, state, {
        products: {
          ...state.products,
          offerType: action.offerType,
        },
      });

    case ActionTypes.SET_BROADBAND_TYPE:
      return assign({}, state, {
        products: {
          ...state.products,
          broadbandType: action.broadbandType,
        },
      });
    case ActionTypes.SET_SELECTED_SWEETENER:
      return assign({}, state, {
        products: {
          ...state.products,
          planPageSelections: {
            ...(state?.products?.planPageSelections || {}),
            selectedSweetener: action.selectedSweetener,
          },
        },
      });
    case ActionTypes.SET_ADDON_BUNDLE:
      return assign({}, state, {
        products: {
          ...state.products,
          planPageSelections: {
            ...(state?.products?.planPageSelections || {}),
            selectedAddonBundle: action.selectedAddonBundle,
          },
        },
      });
    case ActionTypes.SET_PHONE_SELECTION:
      return assign({}, state, {
        products: {
          ...state.products,
          planPageSelections: {
            ...(state?.products?.planPageSelections || {}),
            phoneSelection: action.phoneSelection,
          },
        },
      });
    case ActionTypes.SET_FIRST_AVAILABLE:
      return assign({}, state, {
        products: {
          ...state.products,
          firstAvailable: action.firstAvailable,
        },
      });
    case ActionTypes.SET_NDI_BANNER:
      return assign({}, state, {
        products: {
          ...state.products,
          ndiBanner: action.ndiBanner,
        },
      });
    case ActionTypes.SET_LANDING_PAGE_PRODUCT:
      return assign({}, state, {
        products: {
          ...state.products,
          landingPageProduct: action.landingPageProduct,
        },
      });
    case ActionTypes.SET_LANDING_PAGE_PRODUCT_AVAILABLE:
      return assign({}, state, {
        products: {
          ...state.products,
          landingPageProductAvailable: action.landingPageProductAvailable,
        },
      });
    default:
      return state;
  }
};

export default products;
