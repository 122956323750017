/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Alert = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-alert"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0H16C7.16344 0 0 7.16344 0 16V24C0 32.8366 7.16344 40 16 40H18V36H16C9.37258 36 4 30.6274 4 24V16C4 9.37258 9.37258 4 16 4H18V0ZM22 40V36H24C30.6274 36 36 30.6274 36 24V16C36 9.37258 30.6274 4 24 4H22V0H24C32.8366 0 40 7.16344 40 16V24C40 32.8366 32.8366 40 24 40H22ZM22.1528 19.1661C22.0664 20.2027 21.1999 21 20.1597 21H19.8403C18.8001 21 17.9336 20.2027 17.8472 19.1661L17.1805 11.1661C17.0833 10.0001 18.0035 9 19.1736 9H20.8264C21.9965 9 22.9167 10.0001 22.8195 11.1661L22.1528 19.1661ZM21 30C22.1046 30 23 29.1046 23 28V26C23 24.8954 22.1046 24 21 24H19C17.8954 24 17 24.8954 17 26V28C17 29.1046 17.8954 30 19 30H21Z"
        fill="#FF0037"
      />
    </svg>
  );
};

Alert.displayName = 'AngleDown';

export default Alert;
