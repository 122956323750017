/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Download = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--download"
      {...props}
    >
      <path d="M10.5 0H12.5V11H10.5V0Z" fill="#141928" />
      <path d="M11.5 17.5L16.75 12.25L15.25 10.75L11.5 14.5L7.75 10.75L6.25 12.25L11.5 17.5Z" fill="#141928" />
      <path
        d="M2.5 16.5V12H0.5V16.5C0.5 19.5376 2.96243 22 6 22H17C20.0376 22 22.5 19.5376 22.5 16.5V12H20.5V16.5C20.5 18.433 18.933 20 17 20H6C4.067 20 2.5 18.433 2.5 16.5Z"
        fill="#141928"
      />
    </svg>
  );
};

Download.displayName = 'Download';

export default Download;
