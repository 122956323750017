import styled, { css } from 'styled-components';

export const TooltipStyled = styled.span.attrs({
  className: 'tooltip',
})`
  ${({ theme, skipRewards }) => {
    const { colors } = theme;
    return css`
      .tooltip-button {
        cursor: pointer;
        font-size: 12px;
        font-weight: 800;
        margin-left: 5px;
        position: relative;
        text-decoration: underline;
        visibility: ${skipRewards && 'hidden'};

        &:after {
          content: '';
          position: relative;
          inset: 0;
          transform: scale(2.5);
        }

        .tooltip-button-text {
          position: relative;
          bottom: 4px;
        }

        svg {
          fill: ${colors.primary.gray};
          margin-right: 5px;
          position: relative;
          height: auto;
          width: 15px;
        }
      }
    `;
  }}
`;
