import React, { SVGProps } from 'react';

const Laptop = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg viewBox="0 0 33 28" xmlns="http://www.w3.org/2000/svg" className="icon icon--fill-laptop" {...props}>
    <path
      d="M26.4 4.102H6.6v13.406h19.8V4.102ZM27.225.75c.67 0 1.237.262 1.753.733.464.524.722 1.1.722 1.78v15.083c0 .733-.258 1.309-.722 1.78-.516.524-1.083.733-1.753.733H5.775c-.722 0-1.29-.21-1.753-.733-.516-.471-.722-1.047-.722-1.78V3.264c0-.681.206-1.257.722-1.78A2.352 2.352 0 0 1 5.775.75h21.45Zm5.156 21.785c.155 0 .31.105.413.21.103.104.206.261.206.419v1.885c0 .733-.258 1.309-.722 1.78-.515.524-1.083.733-1.753.733H2.475c-.722 0-1.29-.209-1.753-.733C.206 26.358 0 25.782 0 25.05v-1.885a.6.6 0 0 1 .155-.42.65.65 0 0 1 .464-.209H12.89c.206 0 .309.105.309.262.052.419.258.785.567 1.047.31.262.67.367 1.083.367h3.3c.413 0 .773-.105 1.083-.367.31-.262.464-.628.567-1.047 0-.157.103-.262.31-.262H32.38Z"
      fill="#141928"
    />
  </svg>
);

Laptop.displayName = 'Laptop';

export default Laptop;
