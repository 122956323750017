import styled, { css } from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
})`
  ${({ theme }) => {
    const { media, breakpoints } = theme;

    return css`
      margin: 0 auto;
      width: 100%;

      ${media.desktop.only} {
        max-width: ${breakpoints.desktop.content}px;
        padding: 0 ${breakpoints.desktop.gutter}px;

        .layout--single-page-exp:not([data-page='address-check']):not([data-page='plan-package']) & {
          max-width: 1240px;
        }
      }

      ${media.tablet.only} {
        max-width: ${breakpoints.tablet.content}px;
        padding: 0 ${breakpoints.tablet.gutter}px;
      }

      ${media.mobile.only} {
        max-width: ${breakpoints.mobile.content}px;
        padding: 0 ${breakpoints.mobile.gutter}px;
      }

      ${media.xsMobile.only} {
        max-width: ${breakpoints.xsMobile.content}px;
        padding: 0 ${breakpoints.xsMobile.gutter}px;
      }
    `;
  }};
`;

export const Main = styled(Container).attrs({
  as: 'main',
})`
  ${({ theme, twoColumn, complete, singlePageEnabled, review }) => {
    const { media } = theme;

    return css`
      padding-bottom: 65px;
      flex: 1 0 auto;

      ${singlePageEnabled &&
      `
        margin-top: 64px;

        ${media.tablet.down} {
          ${!review && !complete && `margin-top: 0;`}
        }
      `}

      ${media.mobile.down} {
        padding-bottom: 0px;
      }

      ${media.desktop.only} {
        ${twoColumn &&
        css`
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          > section {
            flex: 0 0 ${singlePageEnabled ? '56.1%' : complete ? '50%' : '58.33333%'};
          }

          > aside {
            margin-top: ${singlePageEnabled && review ? '56px' : 0};
            flex: 0 0 ${singlePageEnabled ? '41%' : complete ? '40%' : '36.4%'};
          }

          > .app-download {
            flex: 0 0 100%;
          }
        `}
      }
    `;
  }};
`;
