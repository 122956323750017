import React, { SVGProps } from 'react';

const LaptopOpen = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="icon icon--laptopopen"
    >
      <path
        d="M5.33322 5.99998C5.33322 3.42265 7.42256 1.33331 9.99989 1.33331H21.9999C24.5772 1.33331 26.6666 3.42265 26.6666 5.99998V15.3333H23.9999V5.99998C23.9999 4.89541 23.1045 3.99998 21.9999 3.99998H9.99989C8.89532 3.99998 7.99989 4.89541 7.99989 5.99998V15.3333L5.33322 15.3333V5.99998Z"
        fill="#141928"
      />
      <path
        d="M5.33322 18V19.8059L2.81468 23.2403C2.3319 23.8986 2.05264 24.6145 1.94922 25.3333H4.68905C4.75277 25.156 4.84399 24.9824 4.96509 24.8173L8.00887 20.6666H23.9909L27.0347 24.8173C27.1558 24.9824 27.247 25.156 27.3107 25.3333H30.0506C29.9471 24.6145 29.6679 23.8986 29.1851 23.2403L26.6666 19.8059V18L5.33322 18Z"
        fill="#141928"
      />
      <path
        d="M29.6383 28C28.913 29.5265 27.3802 30.6666 25.4219 30.6666H6.57791C4.6196 30.6666 3.08681 29.5265 2.36149 28L29.6383 28Z"
        fill="#141928"
      />
    </svg>
  );
};

LaptopOpen.displayName = 'Laptop';

export default LaptopOpen;
