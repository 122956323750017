/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const OfferBox = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--wifibox-white"
      {...props}
    >
      <path
        d="M40.25 13.125C40.25 8.29251 36.3325 4.375 31.5 4.375H10.5C5.66751 4.375 1.75 8.29251 1.75 13.125V28.875C1.75 29.4743 1.81025 30.0596 1.92503 30.625H5.25L5.25 13.125C5.25 10.2255 7.6005 7.875 10.5 7.875L31.5 7.875C34.3995 7.875 36.75 10.2255 36.75 13.125V30.625H40.075C40.1897 30.0596 40.25 29.4743 40.25 28.875V13.125Z"
        fill="white"
      />
      <path
        d="M38.5006 34.125H3.49936C5.09572 36.2503 7.63732 37.625 10.5 37.625H31.5C34.3627 37.625 36.9043 36.2503 38.5006 34.125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 30.625L30.625 28L25.375 22.75H26.25C29.1495 22.75 31.5 20.3995 31.5 17.5V15.75C31.5 12.8505 29.1495 10.5 26.25 10.5H24.5C23.1554 10.5 21.9288 11.0055 21 11.8368C20.0712 11.0055 18.8446 10.5 17.5 10.5H15.75C12.8505 10.5 10.5 12.8505 10.5 15.75V17.5C10.5 20.3995 12.8505 22.75 15.75 22.75H17.5L12.25 28L14.875 30.625L21.4375 24.0625L28 30.625ZM17.5 14C18.4665 14 19.25 14.7835 19.25 15.75V19.25H15.75C14.7835 19.25 14 18.4665 14 17.5V15.75C14 14.7835 14.7835 14 15.75 14H17.5ZM28 17.5C28 18.4665 27.2165 19.25 26.25 19.25H22.75V15.75C22.75 14.7835 23.5335 14 24.5 14H26.25C27.2165 14 28 14.7835 28 15.75V17.5Z"
        fill="white"
      />
    </svg>
  );
};

OfferBox.displayName = 'OfferBox';

export default OfferBox;
