import React, { FC } from 'react';
import slugify from '~/helpers/slugify';
import { H1, H2 } from '../Typography';
import PageHeaderStyled from './PageHeaderStyled';
import Subheader from './components/Subheader';
import PhoneNumberHeader from '../PhoneNumber/PhoneNumberHeader';
import useMediaQuery from '~/hooks/useMediaQuery';

const HeadingsMap = {
  1: H1,
  2: H2,
};

interface Props {
  title: string;
  className?: string;
  subtitle?: {
    icon?: JSX.Element;
    text: string;
  };
  headingLevel?: 1 | 2;
  children?: JSX.Element[] | JSX.Element | string;
  isEeroPage?: boolean;
  phoneNumberHeaderEnabled?: boolean;
}

const PageHeader: FC<Props> = ({
  title,
  subtitle,
  headingLevel,
  children,
  className,
  isEeroPage,
  phoneNumberHeaderEnabled,
}) => {
  const Heading = HeadingsMap[headingLevel];
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
  return (
    <PageHeaderStyled inline={children} className={className} isEeroPage={isEeroPage} data-testid={slugify(title)}>
      {isTablet && phoneNumberHeaderEnabled ? (
        <div className="page-header__phone-container">
          <span className="page-header__titles">
            <Heading>{title}</Heading>
          </span>
          <PhoneNumberHeader />
          <Subheader {...subtitle} />
        </div>
      ) : (
        <div className="page-header__titles" data-testid="page-header">
          <Heading data-testid="plan-package-header">{title}</Heading>
          <Subheader {...subtitle} />
        </div>
      )}
      {children}
    </PageHeaderStyled>
  );
};

PageHeader.defaultProps = {
  headingLevel: 2,
};

PageHeader.displayName = 'PageHeader';

export default PageHeader;
