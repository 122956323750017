import styled, { css } from 'styled-components';

export const SocialNavigationStyled = styled.nav.attrs({
  className: 'social-navigation',
})`
  ${({ theme, isAddressOrPlansPage }) => {
    const { colors, media } = theme;
    return css`
      display: flex;
      margin: ${!isAddressOrPlansPage ? '50px 0 120px' : '50px 0'};

      ${media.tablet.only} {
        margin-bottom: ${!isAddressOrPlansPage ? '60px' : '0'};
      }

      ${media.desktop.up} {
        margin: 20px 0 50px;
        justify-content: flex-end;
      }

      .navigation::after {
        content: '';
        display: table;
        clear: both;
      }

      .menu {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      .menu::after {
        content: '';
        display: table;
        clear: both;
      }

      .navigation {
        min-width: 20%;

        ${media.desktop.up} {
          display: flex;
          justify-content: flex-end;
        }
      }

      .menu {
        display: table;
        margin-bottom: 0;
        padding-left: 0;
        font-size: 0;
        letter-spacing: 0;
        line-height: 0;
      }

      .li {
        display: inline-block;
        margin-right: 12px;
        border-radius: 50%;
        background-color: ${colors.primary.gravity};
        color: ${colors.primary.white};
      }

      .li:last-child {
        margin-right: 0;
      }

      .link {
        display: block;
        padding: 12px;
        color: ${colors.primary.white};
        text-decoration: none;
        font-size: 0;
        line-height: 0;
      }

      .link:hover {
        text-decoration: none;
      }

      .link::before {
        position: relative;
        top: -1px;
        font-size: 1rem;
        line-height: 1;
      }

      .link:hover::before {
        color: ${colors.primary.white};
      }

      .icon > svg {
        fill: ${colors.primary.white};
        width: 16px;
        height: 16px;
      }
    `;
  }}
`;
