/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Router = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="32" height="30" viewBox="0 0 32 30" className="icon icon--router" {...props}>
      <path
        d="M21.3333 19.3333C20.597 19.3333 20 19.9302 20 20.6666C20 21.403 20.597 22 21.3333 22H25.3333C26.0697 22 26.6667 21.403 26.6667 20.6666C26.6667 19.9302 26.0697 19.3333 25.3333 19.3333H21.3333Z"
        fill="currentColor"
      />
      <path
        d="M5.33333 23.3333C5.33333 22.5969 5.93029 22 6.66667 22C7.40305 22 8 22.5969 8 23.3333C8 24.0697 7.40305 24.6666 6.66667 24.6666C5.93029 24.6666 5.33333 24.0697 5.33333 23.3333Z"
        fill="currentColor"
      />
      <path
        d="M12 22C11.2636 22 10.6667 22.5969 10.6667 23.3333C10.6667 24.0697 11.2636 24.6666 12 24.6666C12.7364 24.6666 13.3333 24.0697 13.3333 23.3333C13.3333 22.5969 12.7364 22 12 22Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 0.666626C6.22876 0.666626 5.33333 1.56206 5.33333 2.66663V3.99996C5.33333 4.87077 5.88987 5.6116 6.66667 5.88616V14H4.66667C2.08934 14 0 16.0893 0 18.6666V25.3333C0 27.9106 2.08934 30 4.66667 30H27.3333C29.9107 30 32 27.9106 32 25.3333V18.6666C32 16.0893 29.9107 14 27.3333 14H9.33333V5.88616C10.1101 5.6116 10.6667 4.87077 10.6667 3.99996V2.66663C10.6667 1.56206 9.77124 0.666626 8.66667 0.666626H7.33333ZM2.66667 18.6666C2.66667 17.5621 3.5621 16.6666 4.66667 16.6666H27.3333C28.4379 16.6666 29.3333 17.5621 29.3333 18.6666V25.3333C29.3333 26.4379 28.4379 27.3333 27.3333 27.3333H4.66667C3.5621 27.3333 2.66667 26.4379 2.66667 25.3333V18.6666Z"
        fill="currentColor"
      />
    </svg>
  );
};

Router.displayName = 'Router';

export default Router;
