/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const PhoneBlack = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="#000000"
      className="icon icon--phoneblack"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58494 0.377887C2.95454 0.131485 3.38881 0 3.83302 0H5.00814C5.97661 0 6.83642 0.619717 7.14268 1.53849L8.30081 5.01288C8.52038 5.67158 8.42353 6.39475 8.03839 6.97247L7.30957 8.06569C7.24347 8.16485 7.25654 8.29688 7.34081 8.38114L11.6189 12.6592C11.7031 12.7435 11.8352 12.7565 11.9343 12.6904L13.0275 11.9616C13.6052 11.5765 14.3284 11.4796 14.9871 11.6992L18.4615 12.8573C19.3803 13.1636 20 14.0234 20 14.9919V16.167C20 16.6112 19.8685 17.0455 19.6221 17.4151L18.8636 18.5528C18.2609 19.4569 17.2461 20 16.1595 20H15.1591C14.862 20 14.5679 19.9412 14.2937 19.8269L9.25671 17.7282C6.09985 16.4128 3.52133 14.006 1.99189 10.9471L0.237539 7.43842C0.0813266 7.12599 0 6.78149 0 6.43219V3.30534C0 2.55305 0.375978 1.85053 1.00192 1.43323L2.58494 0.377887ZM3.83302 2C3.78366 2 3.73541 2.01461 3.69434 2.04199L2.11133 3.09733C2.04178 3.1437 2 3.22176 2 3.30534V6.43219C2 6.471 2.00904 6.50928 2.02639 6.54399L3.78075 10.0527C5.09553 12.6823 7.31215 14.7513 10.0259 15.882L15.0629 17.9808C15.0934 17.9935 15.1261 18 15.1591 18H16.1595C16.5774 18 16.9677 17.7911 17.1995 17.4434L17.958 16.3057C17.9854 16.2646 18 16.2163 18 16.167V14.9919C18 14.8843 17.9311 14.7887 17.8291 14.7547L14.3547 13.5966C14.2815 13.5722 14.2011 13.5829 14.1369 13.6257L13.0437 14.3545C12.1513 14.9495 10.963 14.8318 10.2046 14.0734L5.9266 9.79535C5.1682 9.03696 5.05054 7.84869 5.64547 6.95629L6.37429 5.86307C6.41708 5.79888 6.42784 5.71853 6.40344 5.64534L5.24531 2.17094C5.21128 2.06886 5.11575 2 5.00814 2H3.83302Z"
      />
    </svg>
  );
};

PhoneBlack.displayName = 'PhoneBlack';

export default PhoneBlack;
