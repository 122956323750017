import styled, { css } from 'styled-components';

export const SubHeaderStyled = styled.header.attrs(() => ({
  className: 'sub-header',
}))`
  ${({ theme }) => {
    const { colors, media } = theme;

    return css`
      align-items: center;
      display: flex;
      flex-direction: row;

      ${media.mobile.down} {
        align-items: flex-start;
        justify-content: center;
      }

      .subheader {
        &__text {
          line-height: 18px;
        }

        &__icon {
          width: 25px;

          svg {
            fill: ${colors.primary.darkBlue};
            height: 75%;
            width: 75%;
          }
        }
      }
    `;
  }};
`;

const PageHeaderStyled = styled.header.attrs(() => ({
  className: 'page-header',
}))`
  ${({ theme, isEeroPage }) => {
    const { colors, media } = theme;
    return css`
      .h1,
      .h2 {
        color: ${colors.primary.darkBlue};
        font-weight: ${isEeroPage ? 500 : 700};
        margin-bottom: 24px;
      }
      margin-bottom: ${isEeroPage ? '48px' : ''};
      p {
        font-weight: ${isEeroPage ? 700 : ''};
      }
      .portal-container {
        align-items: end;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        ${media.mobile.down} {
          .edit-address {
            display: none;
          }
        }
      }
      .page-header {
        &__titles {
          flex: 1 1 auto;

          ${media.tablet.up} {
            padding-right: 1rem;
          }

          h1,
          h2 {
            font-size: 32px;

            ${media.desktop.only} {
              font-size: 42px;
            }
          }
        }
      }
      .page-header__phone-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        ${media.tablet.up} {
          margin-bottom: 40px;
        }

        .h1 {
          margin-bottom: 0px;
        }
      }
    `;
  }};
`;

export default PageHeaderStyled;
