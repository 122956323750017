/* eslint-disable */
/**
 * Exponential Tracking service
 */

const INSIGHTS_OPTIONS = {
  clientName: 'Frontier%20Communications',
  clientID: 763953,
  eventType: 'visitor',
  segmentNumber: 0,
  segmentName: 'Insight',
};

const CONVERSION_OPTIONS = {
  ...INSIGHTS_OPTIONS,
  eventType: 'conversion',
  segmentNumber: 1,
  segmentName: 'Confirmation',
};

function appendScriptFactory(options = { a9: INSIGHTS_OPTIONS }) {
  return function eventHandler() {
    window.a9 = options.a9;
    const $script = document.createElement('script');
    $script.src = 'https://a.tribalfusion.com/pixel/tags/Frontier%20Communications/763953/pixel.js';
    $script.defer = true;
    document.getElementsByTagName('body')[0].appendChild($script);
  };
}

export const cartEntry = appendScriptFactory({ a9: CONVERSION_OPTIONS });
