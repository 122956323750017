import React, { SVGProps } from 'react';

const Minus = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="13px"
      height="3px"
      viewBox="0 0 13 3"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-minus"
      {...props}
    >
      <path d="M12,2 L1,2 C0.402074337,2 0,1.55209492 0,1 C0,0.447905083 0.402074337,1.77635684e-15 1,1.77635684e-15 L12,1.77635684e-15 C12.5979257,1.77635684e-15 13,0.447905083 13,1 C13,1.55209492 12.5979257,2 12,2 Z" />
    </svg>
  );
};

Minus.displayName = 'Minus';

export default Minus;
