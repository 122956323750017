/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Monitor = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--monitor"
      {...props}
    >
      <path
        d="M6.99992 0.333328C3.31802 0.333328 0.333252 3.3181 0.333252 7V17.6667C0.333252 18.1233 0.379159 18.5692 0.466609 19H2.99992V7C2.99992 4.79086 4.79078 2.99999 6.99992 2.99999H22.9999C25.2091 2.99999 26.9999 4.79086 26.9999 7V19H29.5332C29.6207 18.5692 29.6666 18.1233 29.6666 17.6667V7C29.6666 3.3181 26.6818 0.333328 22.9999 0.333328H6.99992Z"
        fill="#141928"
      />
      <path
        d="M28.3337 21.6667H1.6661C2.88237 23.2859 4.81883 24.3333 6.99992 24.3333H13.6666V27H5.66659V29.6667H24.3333V27H16.3333V24.3333H22.9999C25.181 24.3333 27.1175 23.2859 28.3337 21.6667Z"
        fill="#141928"
      />
      <path d="M22.9999 6.33333L13.6666 15.6667L15.6666 17.6667L24.9999 8.33333L22.9999 6.33333Z" fill="#141928" />
      <path d="M4.99992 15.6667L14.3333 6.33333L16.3333 8.33333L6.99992 17.6667L4.99992 15.6667Z" fill="#141928" />
    </svg>
  );
};

Monitor.displayName = 'Monitor';

export default Monitor;
