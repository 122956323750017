/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const CompareBill = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      data-icon="compare-bill"
      className="icon icon--outline-chevron-left"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20.0002 28V24L36.0002 24V28L20.0002 28Z" fill="#FF0037" />
      <path d="M25.0002 20L36.0002 20V16H25.0002V20Z" fill="#FF0037" />
      <path d="M36.0002 12L28.0002 12V8L36.0002 8V12Z" fill="#FF0037" />
      <path d="M20.0002 36H36.0002V32H20.0002V36Z" fill="#FF0037" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0002 0C15.4224 0 16.7752 0.296895 18.0002 0.832087C19.2251 0.296894 20.5779 0 22.0002 0H34.0002C39.523 0 44.0002 4.47715 44.0002 10V34C44.0002 39.5228 39.523 44 34.0002 44H22.0002C16.4773 44 12.0002 39.5228 12.0002 34V19.8C10.7234 19.5408 9.53498 19.0384 8.48537 18.3432L2.91436 23.9142L0.0859375 21.0858L5.65694 15.5148C4.6099 13.9339 4.00015 12.0382 4.00015 10C4.00015 4.47715 8.4773 0 14.0002 0ZM14.0002 16C17.3139 16 20.0002 13.3137 20.0002 10C20.0002 6.68629 17.3139 4 14.0002 4C10.6864 4 8.00015 6.68629 8.00015 10C8.00015 13.3137 10.6864 16 14.0002 16ZM16.0002 34V19.8C20.5646 18.8734 24.0002 14.8379 24.0002 10C24.0002 7.74879 23.2563 5.67132 22.0009 4H34.0002C37.3139 4 40.0002 6.68629 40.0002 10V34C40.0002 37.3137 37.3139 40 34.0002 40H22.0002C18.6864 40 16.0002 37.3137 16.0002 34Z"
        fill="#FF0037"
      />
    </svg>
  );
};

CompareBill.displayName = 'CompareBill';

export default CompareBill;
