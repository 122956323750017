import React, { SVGProps } from 'react';

const Gift2 = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" {...props} className="icon icon--gift">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.96903 4.50143C5.83912 4.2562 5.76553 3.97653 5.76553 3.67969C5.76553 2.70887 6.55253 1.92188 7.52335 1.92188C8.14242 1.92188 8.68674 2.2419 8.99991 2.72554C9.31308 2.2419 9.8574 1.92188 10.4765 1.92188C11.4473 1.92188 12.2343 2.70887 12.2343 3.67969C12.2343 3.97653 12.1607 4.2562 12.0308 4.50143H13.2173C14.3042 4.50143 15.1853 5.38255 15.1853 6.46947V8.06359H15.2819C16.3688 8.06359 17.25 8.94472 17.25 10.0316V15.2779C17.25 16.3648 16.3688 17.2459 15.2819 17.2459H2.71805C1.63112 17.2459 0.75 16.3648 0.75 15.2779V10.0316C0.75 8.94472 1.63112 8.06359 2.71805 8.06359H2.81474V6.46947C2.81474 5.38255 3.69587 4.50143 4.78279 4.50143H5.96903ZM4.78279 5.99995H8.25079V8.06359H4.31327V6.46947C4.31327 6.21016 4.52348 5.99995 4.78279 5.99995ZM9.74931 8.06359V5.99995H13.2173C13.4766 5.99995 13.6868 6.21016 13.6868 6.46947V8.06359H9.74931ZM2.71805 9.56212H8.25079L8.25079 15.7474H2.71805C2.45874 15.7474 2.24853 15.5372 2.24853 15.2779V10.0316C2.24853 9.77233 2.45874 9.56212 2.71805 9.56212ZM9.74931 9.56212L9.74931 15.7474H15.2819C15.5412 15.7474 15.7514 15.5372 15.7514 15.2779V10.0316C15.7514 9.77233 15.5412 9.56212 15.2819 9.56212H9.74931Z"
        fill="currentColor"
      />
    </svg>
  );
};

Gift2.displayName = 'Gift2';

export default Gift2;
