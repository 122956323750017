import React, { SVGProps } from 'react';

const ArrowRight = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4379 10.3334H0.77124V7.66669H25.4379V10.3334Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8854 9L17.8282 1.94281L19.7139 0.0571899L28.6567 9L19.3805 18.2761L17.4949 16.3905L24.8854 9Z"
        fill="currentColor"
      />
    </svg>
  );
};

ArrowRight.displayName = 'ArrowRight';

export default ArrowRight;
