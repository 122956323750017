/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Ban = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--ban"
      {...props}
    >
      <path d="M3.99251088,2.88192896 L13.1181243,12.0075424 C13.9838445,10.9034721 14.5,9.51223432 14.5,8.00041346 L16,8.00041346 C16,12.4185716 12.4183673,16 8,16 C3.58163267,16 0,12.4185716 0,8.00041346 C0,3.58156951 3.58149159,0 8,0 C12.4185084,0 16,3.58156951 16,8.00041346 L14.5,8.00041346 C14.5,4.40998933 11.590074,1.5 8,1.5 C6.48800441,1.5 5.09665057,2.01616155 3.99251088,2.88192896 Z M2.92610248,3.9368409 C2.03375668,5.04978884 1.5,6.46269636 1.5,8.00041346 C1.5,11.5901253 4.41004065,14.5 8,14.5 C9.53751623,14.5 10.9503178,13.966254 12.0632109,13.0739493 L2.92610248,3.9368409 Z" />
    </svg>
  );
};

Ban.displayName = 'Ban';

export default Ban;
