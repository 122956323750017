import React, { SVGProps } from "react";

const GiftCard = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
<svg
  width="30"
  height="26"
  viewBox="0 0 30 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
  data-icon="gift-card"
  className="icon icon--gift-card"
  >
    <path d="M29.6668 7.00016C29.6668 3.31827 26.6821 0.333496 23.0002 0.333496H7.00016C3.31827 0.333496 0.333496 3.31826 0.333496 7.00016V19.0002C0.333496 19.4568 0.379403 19.9027 0.466854 20.3335H3.00016L3.00016 7.00016C3.00016 4.79102 4.79102 3.00016 7.00016 3.00016L23.0002 3.00016C25.2093 3.00016 27.0002 4.79102 27.0002 7.00016V20.3335H29.5335C29.6209 19.9027 29.6668 19.4568 29.6668 19.0002V7.00016Z"
    fill={props?.fill || "white"}/>
    <path d="M28.334 23.0002H1.66634C2.88262 24.6194 4.81907 25.6668 7.00016 25.6668H23.0002C25.1813 25.6668 27.1177 24.6194 28.334 23.0002Z" fill={props?.fill || "white"}/>
    <path d="M20.3335 20.3335L22.3335 18.3335L18.3335 14.3335H19.0002C21.2093 14.3335 23.0002 12.5426 23.0002 10.3335V9.00016C23.0002 6.79102 21.2093 5.00016 19.0002 5.00016H17.6668C16.6424 5.00016 15.7078 5.3853 15.0002 6.01869C14.2925 5.3853 13.358 5.00016 12.3335 5.00016H11.0002C8.79102 5.00016 7.00016 6.79102 7.00016 9.00016V10.3335C7.00016 12.5426 8.79102 14.3335 11.0002 14.3335H12.3335L8.3335 18.3335L10.3335 20.3335L15.3335 15.3335L20.3335 20.3335ZM12.3335 7.66683C13.0699 7.66683 13.6668 8.26378 13.6668 9.00016V11.6668H11.0002C10.2638 11.6668 9.66683 11.0699 9.66683 10.3335V9.00016C9.66683 8.26378 10.2638 7.66683 11.0002 7.66683H12.3335ZM20.3335 10.3335C20.3335 11.0699 19.7365 11.6668 19.0002 11.6668H16.3335V9.00016C16.3335 8.26378 16.9304 7.66683 17.6668 7.66683H19.0002C19.7365 7.66683 20.3335 8.26378 20.3335 9.00016V10.3335Z" fill={props?.fill || "white"}/>
</svg>

  );
};

GiftCard.displayName = 'GiftCard';

export default GiftCard;
