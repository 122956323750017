/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const EyeOpen = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--outline-eyeOpen"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99964 4.66674C6.15869 4.66674 4.6663 6.15913 4.6663 8.00008C4.6663 9.84103 6.15869 11.3334 7.99964 11.3334C9.84058 11.3334 11.333 9.84103 11.333 8.00008C11.333 6.15913 9.84058 4.66674 7.99964 4.66674ZM9.99963 8.00008C9.99963 6.89551 9.1042 6.00008 7.99964 6.00008C6.89507 6.00008 5.99964 6.89551 5.99964 8.00008C5.99964 9.10465 6.89507 10.0001 7.99964 10.0001C9.1042 10.0001 9.99963 9.10465 9.99963 8.00008Z"
        fill="#141928"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4244 12.2813C9.57731 14.277 6.42195 14.277 4.57483 12.2813L1.06494 8.48924C0.695929 8.09056 0.712705 7.47019 1.10272 7.09203L4.75113 3.55455C6.56123 1.7995 9.43804 1.7995 11.2481 3.55455L14.8965 7.09203C15.2866 7.47019 15.3033 8.09056 14.9343 8.48924L11.4244 12.2813ZM5.55334 11.3756C6.87272 12.8011 9.12655 12.8011 10.4459 11.3756L13.7346 7.82257L10.32 4.5118C9.02707 3.25819 6.9722 3.25819 5.67928 4.5118L2.26469 7.82257L5.55334 11.3756Z"
        fill="#141928"
      />
    </svg>
  );
};

EyeOpen.displayName = 'EyeOpen';

export default EyeOpen;
