import React, { SVGProps } from 'react';

const Plus = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-plus"
      {...props}
    >
      <path d="M12,5.47095652 C12.5978814,5.47095652 13,5.93103294 13,6.49813043 C13,7.06522793 12.5978814,7.52530435 12,7.52530435 L7.57860052,7.52530435 L7.57860052,12 C7.57860052,12.5670975 7.09354966,12.9953478 6.49566827,12.9953478 C5.89778688,12.9953478 5.41273602,12.5670975 5.41273602,12 L5.41273602,7.52530435 L1,7.52530435 C0.402118611,7.52530435 0,7.06522793 0,6.49813043 C0,5.93103294 0.402118611,5.47095652 1,5.47095652 L5.41273602,5.47095652 L5.41273602,1 C5.41273602,0.432902504 5.89778688,3.55271368e-15 6.49566827,3.55271368e-15 C7.09354966,3.55271368e-15 7.57860052,0.432902504 7.57860052,1 L7.57860052,5.47095652 L12,5.47095652 Z" />
    </svg>
  );
};

Plus.displayName = 'Plus';

export default Plus;
