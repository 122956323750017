/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const InfoCircle = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--outline-info-circle"
      {...props}
    >
      <path
        d="M15.6197 8.99992C14.8695 8.99992 14.2613 9.59687 14.2613 10.3332L14.2613 11.6666C14.2613 12.403 14.8695 12.9999 15.6197 12.9999L16.978 12.9999C17.7282 12.9999 18.3364 12.403 18.3364 11.6666L18.3364 10.3332C18.3364 9.59687 17.7282 8.99992 16.978 8.99992L15.6197 8.99992Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8823 17.3333C29.8823 23.9607 24.4089 29.3333 17.6572 29.3333L14.9405 29.3333C8.18875 29.3333 2.71537 23.9607 2.71537 17.3333L2.71537 14.6666C2.71537 8.03917 8.18875 2.66659 14.9405 2.66659L17.6572 2.66659C24.4089 2.66658 29.8823 8.03917 29.8823 14.6666L29.8823 17.3333ZM17.6572 26.6666L14.9405 26.6666C9.68914 26.6666 5.43207 22.4879 5.43207 17.3333L5.43207 14.6666C5.43207 9.51193 9.68913 5.33325 14.9405 5.33325L17.6572 5.33325C22.9086 5.33325 27.1656 9.51193 27.1656 14.6666L27.1656 17.3333C27.1656 22.4879 22.9086 26.6666 17.6572 26.6666Z"
        fill="currentColor"
      />
      <path d="M17.9543 22.9999L14.6115 22.9999L14.6115 14.9999L17.9543 14.9999L17.9543 22.9999Z" fill="currentColor" />
    </svg>
  );
};

InfoCircle.displayName = 'InfoCircle';

export default InfoCircle;
