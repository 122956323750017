/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Pencilgray = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="12px"
      height="13px"
      viewBox="0 0 12 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--pencil"
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="#999A9B">
        <path
          transform="translate(-1215.000000, -283.000000)"
          d="M1226.53122,285.234396 C1226.84372,285.546895 1226.99997,285.921894 1226.99997,286.359393 C1226.99997,286.796892 1226.84372,287.171891 1226.53122,287.484391 L1219.14843,294.91406 L1215.65625,295.499996 C1215.46875,295.531246 1215.30469,295.476559 1215.16406,295.335934 C1215.02344,295.19531 1214.96875,295.031248 1215,294.843748 L1215.58594,291.351569 L1223.01561,283.968774 C1223.32811,283.656275 1223.7031,283.500025 1224.1406,283.500025 C1224.5781,283.500025 1224.9531,283.656275 1225.2656,283.968774 L1226.53122,285.234396 Z M1219.68749,290.812508 C1219.59374,290.703133 1219.54686,290.578133 1219.54686,290.437508 C1219.54686,290.296884 1219.59374,290.171884 1219.68749,290.062509 L1222.47654,287.273454 L1221.8203,286.617205 L1217.83593,290.578133 L1218.79687,290.578133 L1218.79687,291.70313 L1219.92186,291.70313 L1219.92186,292.664066 L1223.88279,288.6797 L1223.22654,288.023452 L1220.43749,290.812508 C1220.32811,290.906257 1220.20311,290.953132 1220.06249,290.953132 C1219.92186,290.953132 1219.79686,290.906257 1219.68749,290.812508 Z M1216.92187,294.234374 L1218.65624,293.953125 L1219.07812,293.531251 L1219.07812,292.546878 L1217.95312,292.546878 L1217.95312,291.421881 L1216.96875,291.421881 L1216.54687,291.843755 L1216.26562,293.578126 L1216.92187,294.234374 Z M1225.80466,286.75783 C1225.91404,286.648455 1225.96872,286.515643 1225.96872,286.359393 C1225.96872,286.203143 1225.90622,286.070332 1225.78122,285.960957 L1224.53904,284.695335 C1224.42966,284.58596 1224.29685,284.531273 1224.1406,284.531273 C1223.98435,284.531273 1223.85154,284.58596 1223.74217,284.695335 L1222.61717,285.820332 L1224.67966,287.882827 L1225.80466,286.75783 Z"
        />
      </g>
    </svg>
  );
};

Pencilgray.displayName = 'Pencilgray';

export default Pencilgray;
