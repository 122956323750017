/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Star = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="28px"
      height="27px"
      viewBox="0 0 28 27"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--star"
      {...props}
    >
      <path
        d="M16.6363 2.74441C15.8298 0.304834 12.3789 0.304848 11.5725 2.74441L9.52113 8.94973H2.93248C0.338085 8.94973 -0.72855 12.2788 1.38277 13.7865L6.67327 17.5645L4.64317 23.7056C4.15037 25.1964 4.99238 26.542 6.20017 27.0285L8.73643 19.8195L9.35934 17.9352C9.63438 17.1032 9.3358 16.1891 8.6227 15.6798L2.93249 11.6164L10.0029 11.6164C10.8656 11.6164 11.631 11.0632 11.9018 10.2441L14.1044 3.5814L16.3069 10.2441C16.5777 11.0632 17.3432 11.6164 18.2058 11.6164H25.2762L19.586 15.6798C18.8729 16.1891 18.5744 17.1032 18.8494 17.9352L21.0336 24.5426L15.2667 20.4244C14.5713 19.9278 13.6374 19.9278 12.9421 20.4244L10.8165 21.9422L9.27685 26.3185L14.1044 22.8712L19.4839 26.7128C21.5828 28.2116 24.3751 26.1544 23.5656 23.7056L21.5355 17.5645L26.826 13.7865C28.9373 12.2788 27.8706 8.94973 25.2762 8.94973H18.6876L16.6363 2.74441Z"
        fill={props.fill || "currentColor"}
      />
    </svg>
  );
};

Star.displayName = 'Star';

export default Star;
