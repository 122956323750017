import styled, { css } from 'styled-components';

const ErrorBoundaryStyled = styled.div`
  ${() => {
    return css`
      details {
        white-space: 'pre-wrap',
        line-height: 1.2,
      }
    `;
  }};
`;

ErrorBoundaryStyled.displayName = 'ErrorBoundaryStyled';

export default ErrorBoundaryStyled;
