/* eslint-disable */
/**
 * Valassis Tracking service
 */

const CART_ENTRY = {
  pixId: '_pix_id_27aaf70b-0b16-1506-eb67-704a9f77e06e',
  src: {
    script:
      'https://s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D27aaf70b-0b16-1506-eb67-704a9f77e06e%26type%3D31%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/address&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D27AAF70B-0B16-1506-EB67-704A9F77E06E&cb=',
    img: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D27aaf70b-0b16-1506-eb67-704a9f77e06e%26type%3D31%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/address&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D27AAF70B-0B16-1506-EB67-704A9F77E06E',
  },
};

const NOT_SERVICEABLE = {
  pixId: '_pix_id_c6f7332f-6c3e-a96c-f00b-5d9ed33a68c4',
  src: {
    script:
      'https://s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Dc6f7332f-6c3e-a96c-f00b-5d9ed33a68c4%26type%3D83%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/unserviceable&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3DC6F7332F-6C3E-A96C-F00B-5D9ED33A68C4&cb=',
    img: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Dc6f7332f-6c3e-a96c-f00b-5d9ed33a68c4%26type%3D83%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/unserviceable&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3DC6F7332F-6C3E-A96C-F00B-5D9ED33A68C4',
  },
};

const SEVICEABILITY_CONFIRMED = {
  pixId: '_pix_id_5a51810b-7e6e-e25c-f82d-37b0182b5863',
  src: {
    script:
      'https://s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D5a51810b-7e6e-e25c-f82d-37b0182b5863%26type%3D32%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/plan-package&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D5A51810B-7E6E-E25C-F82D-37B0182B5863&cb=',
    img: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D5a51810b-7e6e-e25c-f82d-37b0182b5863%26type%3D32%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/plan-package&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D5A51810B-7E6E-E25C-F82D-37B0182B5863',
  },
};

const PACKAGE_SELECTED = {
  pixId: '_pix_id_d60e2d69-c5ed-77df-b6d0-805c4f3c4013',
  src: {
    script:
      'https://s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Dd60e2d69-c5ed-77df-b6d0-805c4f3c4013%26type%3D53%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/offer&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3DD60E2D69-C5ED-77DF-B6D0-805C4F3C4013&cb=',
    img: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Dd60e2d69-c5ed-77df-b6d0-805c4f3c4013%26type%3D53%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/offer&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3DD60E2D69-C5ED-77DF-B6D0-805C4F3C4013',
  },
};

const PERSONAL_DETAILS_ENTERED = {
  pixId: '_pix_id_df7e5843-11ad-8c23-63ff-f436706e0577',
  src: {
    script: `https://s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D5a51810b-7e6e-e25c-f82d-37b0182b5863%26type%3D32%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/plan-package&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D5A51810B-7E6E-E25C-F82D-37B0182B5863' +
      '&cb=`,
    img: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D5a51810b-7e6e-e25c-f82d-37b0182b5863%26type%3D32%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/plan-package&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D5A51810B-7E6E-E25C-F82D-37B0182B5863',
  },
};

const CREDIT_CHECK = {
  pixId: '_pix_id_ccd161c1-3e8e-3c37-86e8-c1585375d545',
  src: {
    script: `https://s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3Dccd161c1-3e8e-3c37-86e8-c1585375d545%26type%3D7%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/verification&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3DCCD161C1-3E8E-3C37-86E8-C1585375D545&cb=`,
    img: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3Dccd161c1-3e8e-3c37-86e8-c1585375d545%26type%3D7%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/verification&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3DCCD161C1-3E8E-3C37-86E8-C1585375D545',
  },
};

const ORDER_CONFIRMATION = {
  pixId: '_pix_id_12a4da89-8f4e-f04d-d2b4-52ab127b28d6',
  src: {
    script:
      'https://s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D12a4da89-8f4e-f04d-d2b4-52ab127b28d6%26type%3D29%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/confirmation&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D12A4DA89-8F4E-F04D-D2B4-52AB127B28D6&cb=',
    img: 'https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D12a4da89-8f4e-f04d-d2b4-52ab127b28d6%26type%3D29%26m%3D1&ex-fch=416613&ex-src=https://frontier.com/order-online/confirmation&ex-hargs=v%3D1.0%3Bc%3D2257400076184%3Bp%3D12A4DA89-8F4E-F04D-D2B4-52AB127B28D6',
  },
};

function setTrackingFactory({ pixId = '', src }) {
  return function setTracking() {
    if (document.getElementById(pixId) === null) {
      const _pix = document.createElement('iframe');

      _pix.style.display = 'none';
      _pix.src = src + Math.random() * 1000000000000000000;
      _pix.id = pixId;
      _pix.setAttribute('aria-hidden', true);
      document.getElementsByTagName('body')[0].appendChild(_pix);
    }
  };
}

function createPixel(src) {
  const $img = document.createElement('img');
  $img.src = src;
  $img.height = 1;
  $img.width = 1;
  $img.alt = '';
  return $img;
}

function appendScriptFactory({ pixId, src: { script, img } }) {
  return function eventHandler() {
    setTrackingFactory({ pixId, src: script })();

    // append identical pixel to body without disabled JS
    document.querySelector('footer').appendChild(createPixel(img));
  };
}

export const cartEntry = appendScriptFactory(CART_ENTRY);

export const notServiceable = appendScriptFactory(NOT_SERVICEABLE);

export const seviceabilityConfirmed = appendScriptFactory(SEVICEABILITY_CONFIRMED);

export const packageSelected = appendScriptFactory(PACKAGE_SELECTED);

export const personalDetailsEntered = appendScriptFactory(PERSONAL_DETAILS_ENTERED);

export const creditCheckRequested = appendScriptFactory(CREDIT_CHECK);

export const orderConfirmation = appendScriptFactory(ORDER_CONFIRMATION);
