/* eslint-disable */
/**
 * Google Tracking service
 */

export const gTagConfig = {
  default: 'DC-8577835',
  vantage: 'AW-793118781',
  FiOS: 'AW-793133807',
};

export const triliaTag = 'AW-802360209';

function generateSendTo(targetProperty) {
  return Object.values(gTagConfig).map((conversionId) => conversionId + targetProperty);
}

const marketMap = {
  1: 'copper',
  2: 'copper',
  3: 'fios',
  4: 'fios',
  5: 'vantage',
  6: 'vantage',
  7: 'vantage',
  8: 'vantage',
  9: 'copper',
};

const tagConfiguration = () => {
  // Configure gtag
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag;

  // Google Tag for Trilia
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=DC-8577835';
  document.getElementsByTagName('body')[0].appendChild(script);
  window.gtag('js', new Date());
};

const sendConfig = () => {
  if (typeof window.gtag === 'function') {
    Object.values(gTagConfig).forEach((id) => gtag('config', id));
    gtag('config', triliaTag);
  }
};

export const pageView = (e) => {
  tagConfiguration();
  sendConfig();

  // Make sure we have detail
  if (typeof e.detail !== 'undefined') {
    // Pull page off
    const page = e.detail && e.detail.page;
    const sessionId = e.detail && e.detail.id;

    // Make sure we have a page
    if (typeof page !== 'undefined') {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'page_view', {
          send_to: triliaTag,
        });
      }
      // Check the page and track accordingly
      if (page === 'address') {
        // Cart entry
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'conversion', {
            allow_custom_scripts: true,
            session_id: sessionId,
            send_to: generateSendTo('/redve0/ecomm0+per_session'),
          });

          window.gtag('event', 'conversion', {
            send_to: 'AW-802360209/g4a-CP3s6ZkBEJGXzP4C',
          });
        }
      } else if (page === 'unserviceable' || page === 'unserviceable-soft' || page === 'unserviceable-allconnect') {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'conversion', {
            allow_custom_scripts: true,
            session_id: sessionId,
            send_to: generateSendTo('/redve0/ecomm000+per_session'),
          });
        }
      } else if (page === 'plan-package') {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'conversion', {
            allow_custom_scripts: true,
            session_id: sessionId,
            send_to: generateSendTo('/redve0/ecomm004+per_session'),
          });

          if (e.detail.market) {
            const marketFlag = marketMap[e.detail.market];
            const triliId = marketFlag === 'copper' ? '005' : marketFlag === 'fios' ? '004' : '006';

            window.gtag('event', 'conversion', {
              allow_custom_scripts: true,
              session_id: sessionId,
              send_to: generateSendTo(`/redve0/trili${triliId}+per_session`),
            });
          }
        }
      } else if (page === 'confirmation') {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'conversion', {
            allow_custom_scripts: true,
            session_id: 1,
            send_to: generateSendTo('/redve0/ecomm001+per_session'),
          });
        }
      }
    }
  }
};

// Tracks whenever a user adds a plan to cart
export const packageSelected = (e, sessionId) => {
  sendConfig();

  if (typeof e.detail !== 'undefined' && typeof e.detail.products !== 'undefined') {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        session_id: sessionId && sessionId.tenantId,
        send_to: generateSendTo('/redve0/ecomm002+per_session'),
      });
    }
  }
};

export const creditCheckResponded = (e, sessionId) => {
  sendConfig();
  if (typeof e.detail !== 'undefined') {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        session_id: sessionId && sessionId.tenantId,
        send_to: generateSendTo('/redve0/ecomm00+per_session'),
      });
    }
  }
};

export const creditCheckSuccess = (e) => {
  sendConfig();
  if (typeof e.detail !== 'undefined') {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        session_id: e && e.tenantId,
        send_to: generateSendTo('/redve0/trili007+per_session'),
      });
    }
  }
};

export const creditCheckFailed = (e) => {
  sendConfig();
  if (typeof e.detail !== 'undefined') {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        session_id: e && e.tenantId,
        send_to: generateSendTo('/redve0/trili008+per_session'),
      });
    }
  }
};

export const personalDetailsEntered = (e, sessionId) => {
  sendConfig();
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      session_id: sessionId && sessionId.tenantId,
      send_to: generateSendTo('/redve0/ecomm003+per_session'),
    });
  }
};

export const trackFiloHelp = (e) => {
  sendConfig();
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: generateSendTo('/redve0/trili009+standard'),
    });
  }
};

export const appointmentSelected = (e) => {
  sendConfig();
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: generateSendTo('/redve0/trili00-+standard'),
    });
  }
};

export const editPlan = (e) => {
  sendConfig();
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      session_id: window._Cohesion && window._Cohesion.sessionId,
      send_to: generateSendTo('/redve0/ecomm006+per_session'),
    });
  }
};
