import React, { SVGProps } from "react";

const TwitterX = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 14" {...props}>
      <path d="M12.2175 0.269287H14.4665L9.55313 5.88495L15.3333 13.5266H10.8075L7.26268 8.89198L3.20662 13.5266H0.956278L6.21161 7.52002L0.666656 0.269287H5.3074L8.51159 4.50551L12.2175 0.269287ZM11.4282 12.1805H12.6744L4.63025 1.54471H3.29296L11.4282 12.1805Z"/>
    </svg>
  );
};

TwitterX.displayName = 'Twitter-X';

export default TwitterX;
