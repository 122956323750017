import { createStore, compose, applyMiddleware, Store } from 'redux';
import LogRocket from 'logrocket';
import thunk from 'redux-thunk';
import clientSide from '../../helpers/clientSide';
import getInitialState from './getInitialState';
import reducers from './reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Add redux dev tools
const composeEnhancers = (() => {
  if (process.env.environment !== 'production' && clientSide) {
    return (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose;
  }
  return compose;
})();

const enhancer = composeEnhancers(
  applyMiddleware(
    thunk,
    LogRocket.reduxMiddleware({
      stateSanitizer: function (state) {
        return {
          ...state,
          // state to be masked
          address: '***',
          contact: '***',
        };
      },
    })
  )
);

const storeCreator = (production: boolean): Store => {
  const initialState = getInitialState(production);
  const store = createStore(reducers, initialState, enhancer);
  return store;
};

export default storeCreator;
