import React, { SVGProps } from 'react';

const Cart = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width="18px"
      height="16px"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon--fill-cart"
      {...props}
    >
      <path d="M15.0116991,10.9269264 C15.6872203,10.9053156 16.1164156,10.8347359 16.312739,10.0981697 L17.9633791,3.30407928 C18.0514495,2.85505862 17.9820497,2.56493285 17.7401952,2.30952574 C17.4983408,2.05411862 17.1366976,1.9788373 16.594264,1.9788373 L4.4615662,1.97087016 C4.16132621,0.878376696 3.67493743,0 2.33286469,0 L0.750599966,0 C0.336268785,0 0,0.334619694 0,0.746918959 C0,1.15921822 0.336268785,1.49383792 0.750599966,1.49383792 L2.00129967,1.49413669 C2.5377484,1.49413669 3.00209962,1.81072542 3.10217962,2.56216894 L4.24139021,9.71094236 C3.55283984,10.0933649 3.18954946,10.8183742 3.18954946,11.5433835 C3.18954946,12.4327151 3.74699503,13.363874 4.78482458,13.6148388 C4.69375179,13.8229802 4.64070939,14.0530312 4.64070939,14.2960289 C4.64070939,15.2361509 5.40832295,16 6.35407891,16 C7.29983487,16 8.06644763,15.2361509 8.06644763,14.2960289 C8.06644763,14.0799203 8.02241244,13.8757625 7.94935404,13.686543 L12.6010722,13.686543 C12.527013,13.8757625 12.4829778,14.0799203 12.4829778,14.2960289 C12.4829778,15.2361509 13.2495906,16 14.1953466,16 C15.1411025,16 15.9077153,15.2361509 15.9077153,14.2960289 C15.9077153,14.0799203 15.8636801,13.8757625 15.7906217,13.686543 L16.2810137,13.686543 C16.6953448,13.686543 17.0316136,13.3519233 17.0316136,12.9396241 C17.0316136,12.5263289 16.6953448,12.1927051 16.2810137,12.1927051 L5.34627336,12.1927051 C4.72177419,12.1927051 4.69074939,11.6519358 4.69074939,11.5433835 C4.69074939,11.3581476 4.75480058,10.9269264 5.34627336,10.9269264 L15.0116991,10.9269264 Z" />
    </svg>
  );
};

Cart.displayName = 'Cart';

export default Cart;
