/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

const Gift = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-icon="gift"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 32"
      className="icon icon--fill-gift"
      {...props}
    >
      <g clipPath="url(#clip0_454:1695)">
        <path
          d="M1.25159 17.3684V31.0955C1.25226 31.2877 1.32937 31.4719 1.46607 31.6078C1.60278 31.7436 1.78796 31.8201 1.98118 31.8206H10.8727C10.9635 31.8207 11.0534 31.803 11.1373 31.7685C11.2211 31.734 11.2974 31.6834 11.3616 31.6196C11.4258 31.5558 11.4768 31.48 11.5115 31.3966C11.5463 31.3132 11.5642 31.2237 11.5642 31.1334V17.3684C11.5642 17.1859 11.4913 17.0109 11.3616 16.8819C11.232 16.7529 11.0561 16.6804 10.8727 16.6804H1.94305C1.75966 16.6804 1.58379 16.7529 1.45411 16.8819C1.32444 17.0109 1.25159 17.1859 1.25159 17.3684Z"
          fill="currentColor"
        />
        <path
          d="M15.4366 17.3684V31.1334C15.4366 31.2237 15.4545 31.3132 15.4893 31.3966C15.5241 31.48 15.575 31.5558 15.6392 31.6196C15.7034 31.6834 15.7797 31.734 15.8636 31.7685C15.9474 31.803 16.0373 31.8207 16.1281 31.8206H25.0179C25.2112 31.8201 25.3964 31.7436 25.5331 31.6078C25.6698 31.4719 25.7469 31.2877 25.7475 31.0955V17.3684C25.7475 17.1859 25.6747 17.0109 25.545 16.8819C25.4153 16.7529 25.2395 16.6804 25.0561 16.6804H16.1264C15.9433 16.6809 15.7679 16.7535 15.6386 16.8825C15.5093 17.0115 15.4366 17.1862 15.4366 17.3684Z"
          fill="currentColor"
        />
        <path
          d="M10.8727 8.72931H0.728745C0.535676 8.72998 0.350706 8.80658 0.214185 8.94241C0.0776636 9.07824 0.000669997 9.26227 0 9.45436V14.4227C0.000446613 14.6149 0.0773421 14.7992 0.213887 14.9352C0.350433 15.0712 0.53553 15.1479 0.728745 15.1486H10.8727C11.0561 15.1486 11.232 15.0761 11.3616 14.9471C11.4913 14.818 11.5642 14.6431 11.5642 14.4606V9.41642C11.5642 9.32612 11.5463 9.2367 11.5115 9.15328C11.4768 9.06986 11.4258 8.99408 11.3616 8.93026C11.2974 8.86645 11.2211 8.81585 11.1373 8.78137C11.0534 8.74689 10.9635 8.7292 10.8727 8.72931Z"
          fill="currentColor"
        />
        <path
          d="M26.2704 8.72931H16.1265C16.0357 8.7292 15.9458 8.74689 15.8619 8.78137C15.778 8.81585 15.7018 8.86645 15.6376 8.93026C15.5733 8.99408 15.5224 9.06986 15.4876 9.15328C15.4529 9.2367 15.435 9.32612 15.435 9.41642V14.4606C15.435 14.6431 15.5078 14.818 15.6375 14.9471C15.7672 15.0761 15.9431 15.1486 16.1265 15.1486H26.2704C26.4636 15.1479 26.6487 15.0712 26.7853 14.9352C26.9218 14.7992 26.9987 14.6149 26.9992 14.4227V9.45436C26.9985 9.26227 26.9215 9.07824 26.785 8.94241C26.6484 8.80658 26.4635 8.72998 26.2704 8.72931Z"
          fill="currentColor"
        />
        <path
          d="M23.1996 3.81076C22.6293 3.41619 21.7065 3.05788 20.832 2.32102C19.9575 1.58417 19.4974 0.778176 19.0288 0.297617C18.6339 -0.107907 17.8738 -0.0918885 17.4908 0.297617C16.4095 1.39363 13.6776 5.0189 13.6776 5.0189L13.6733 5.0248C13.6534 5.05132 13.6275 5.07285 13.5977 5.08768C13.5679 5.10251 13.535 5.11023 13.5017 5.11023C13.4684 5.11023 13.4356 5.10251 13.4058 5.08768C13.376 5.07285 13.3501 5.05132 13.3301 5.0248C13.3301 5.0248 10.5948 1.40543 9.51694 0.303519C9.13393 -0.0859863 8.37383 -0.102005 7.97895 0.303519C7.51035 0.784078 7.04683 1.59091 6.17573 2.32693C5.30462 3.06294 4.37844 3.42294 3.80815 3.81666C3.69955 3.88664 3.61028 3.98254 3.54847 4.09564C3.48666 4.20873 3.45428 4.33542 3.45428 4.46415C3.45428 4.59288 3.48666 4.71956 3.54847 4.83266C3.61028 4.94575 3.69955 5.04166 3.80815 5.11164C4.47335 5.57533 5.91558 6.40156 7.17055 7.09963C7.52514 7.29754 7.92489 7.40147 8.33146 7.40146H18.6763C19.0828 7.40147 19.4826 7.29754 19.8372 7.09963C21.0921 6.40156 22.5344 5.57533 23.1996 5.11164C23.3094 5.04193 23.3999 4.94579 23.4625 4.8321C23.5252 4.71842 23.558 4.59085 23.558 4.4612C23.558 4.33154 23.5252 4.20398 23.4625 4.09029C23.3999 3.97661 23.3094 3.88047 23.1996 3.81076Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

Gift.displayName = 'Gift';

export default Gift;
