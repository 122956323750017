import Cookies from 'js-cookie';
import { CallMonarchWithPreamp } from '@red-digital/bricks/dist/types/monarch';
import { CohesionContextPlacements } from '@red-digital/bricks';
import { Monarch } from '~/types/monarch';
import clientSide from './clientSide';
import { MONARCH_RULESETS } from '~/constants/config/common';

export const callMonarchWithPreamp: CallMonarchWithPreamp = (preampDecisions: CohesionContextPlacements) => {
  const olVersionMlp = window?.sessionStorage?.getItem('olVersion');
  const apiVersionCookie = Cookies.get('apiVersion');
  const olVersion = apiVersionCookie ? apiVersionCookie : olVersionMlp;
  const dma = Cookies.get('dmaName');
  const resume = Cookies.get('resumeEnabled');
  const moversSwitchers = Cookies.get('moversSwitchers');
  const movers = moversSwitchers === 'movers';
  const tipDataFromZipCode = Cookies.get('tipDataFromZipCode');

  if (typeof preampDecisions === 'object') {
    // check editable asset property
    let cdapiMonarchRuleset;

    // Look for CDAPI asset
    Object.entries(preampDecisions).forEach(([, asset]) => {
      const { metadata = {} } = asset;
      if (metadata.isCdapiAgent === 'true' && typeof metadata.monarchRulesetId === 'string') {
        cdapiMonarchRuleset = metadata.monarchRulesetId;
      }
    });

    // If CDAPI is turned on, use specified Monarch ruleset
    if (cdapiMonarchRuleset) {
      const payload = {
        ol_version: olVersion,
        is_mover: movers,
        designated_market_area: dma,
        tipDataFromZipCode: tipDataFromZipCode && JSON.parse(tipDataFromZipCode),
        is_resume_quote: Boolean(resume),
      };

      const config = { rulesetId: cdapiMonarchRuleset, payload };

      return config;
    }
  }

  // Else, use BAU Monarch ruleset
  return {
    rulesetId: MONARCH_RULESETS.GLOBAL,
    payload: {
      ol_version: olVersion,
    },
  };
};

export const getMonarchRule = (data: Monarch): void => {
  if (clientSide) {
    const { event = 'rule', ruleId, payload = {}, params = {}, callback = () => null, tracking } = data;
    const { tenantId, writeKey } = tracking;

    window?.cohesion('monarch:ready', function () {
      window?.monarch(event, ruleId, { tenantId, ...payload }, { writeKey, ...params }, callback);
    });
  }
  return;
};
