import React, { FC } from 'react';
import { SubHeaderStyled } from '../PageHeaderStyled';

interface Props {
  icon?: JSX.Element;
  text: string;
}

const Subheader: FC<Props> = ({ icon, text }) => {
  if (icon && text) {
    return (
      <SubHeaderStyled>
        <span className="subheader__icon">{icon}</span>
        <p className="subheader__text" data-testid="subheader__text">
          {text}
        </p>
      </SubHeaderStyled>
    );
  }

  if (text)
    return (
      <SubHeaderStyled>
        <p className="subheader__text" data-testid="subheader__text">
          {text}
        </p>
      </SubHeaderStyled>
    );

  return null;
};

Subheader.displayName = 'Subheader';

export default Subheader;
