import React, { SVGProps } from 'react';

const LinkedIn = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 28 32" {...props}>
      <path d="M6.269 30h-5.806v-18.694h5.806v18.694zM3.362 8.756c-1.856 0-3.362-1.537-3.362-3.394s1.506-3.362 3.362-3.362c1.856 0 3.362 1.506 3.362 3.362s-1.506 3.394-3.362 3.394zM28 30h-5.794v-9.1c0-2.169-0.044-4.95-3.019-4.95-3.019 0-3.481 2.356-3.481 4.794v9.256h-5.8v-18.694h5.569v2.55h0.081c0.775-1.469 2.669-3.019 5.494-3.019 5.875 0 6.956 3.869 6.956 8.894v10.269z" />
    </svg>
  );
};

LinkedIn.displayName = 'LinkedIn';

export default LinkedIn;
