import React, { SVGProps } from 'react';

const LightningBolt = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg" className="icon icon--fill-lightning-bolt" {...props}>
    <title>High Speed Internet icon</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#01315F" cx={45} cy={45} r={45} />
      <path
        d="M41.953 64.875c.711 0 1.244-.305 1.6-.914l13.406-23.156c.355-.61.355-1.219 0-1.828-.356-.61-.889-.914-1.6-.914H46.6l2.59-9.903c.153-.558.051-1.079-.304-1.561-.356-.483-.838-.724-1.448-.724H37.078a1.82 1.82 0 00-1.219.457 1.705 1.705 0 00-.609 1.143l-2.438 18.28c-.05.56.102 1.042.458 1.448.355.406.812.61 1.37.61h9.065L40.201 62.59c-.152.61-.05 1.142.305 1.6.355.456.838.685 1.447.685z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

LightningBolt.displayName = 'LightningBolt';

export default LightningBolt;
